import { Button } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customBtn: {
    background: "#fff",
    padding: "12px 20px !important",
    fontSize: "1rem !important",
    borderRadius: "4px !important",
    transition: " .5s !important",
    color: "#000 !important",
    border: "0 !important",
    fontWeight: "600!important",
    lineHeight: "1.25 !important",
    textTransform: "none !important",
    letterSpacing: "3px",
    borderColor: "#fff",
    boxShadow:
      "0 1px 15px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    marginLeft: ".5rem !important",
  },
});

const ResetButton = ({ text, onClick, loading, loader, style }) => {
  const classes = useStyles();
  return (
    <>
      <Button onClick={onClick} style={style} className={classes.customBtn}>
        {text}
        {loading && loader}
      </Button>
    </>
  );
};

export default ResetButton;
