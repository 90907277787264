
import React, { useEffect, useState } from "react";
import { updateStaff } from "../../actions/staff";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";

const EditVendor = ({ updateStaff, getShops, shops: { allShops } }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  
    const [data, setData] = useState({
     
  });
  const [errors, setErrors] = useState({
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    cityErr: "",
    countryErr: "",
    staff_imageErr: "",
    statusErr: "",
  });

  const {
    first_nameErr,
    last_nameErr,
    emailErr,
    cityErr,
    countryErr,
    staff_imageErr,
  } = errors;
  // const [errors, setErrors] = useState({
  //     // shop_idErr: "",
  //     emailErr: "",
  //     passwordErr: "",
  // });
  
  // const {
  //     // shop_id,
  //     email,
  //     password,
  // } = data;
  // console.log(data)
  // const {
  //     // shop_idErr,
  //     emailErr,
  //     passwordErr,
  // } = errors;
 
 
  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/staff/${params.id}`);
      setData({
        ...data,
        first_name: result?.data?.data?.user?.first_name,
        last_name: result?.data?.data?.user?.last_name,
        email: result?.data?.data?.user?.email,
        city: result?.data?.data?.user?.city,
        country: result?.data?.data?.user?.country,
     
      });
      setLoading(false);
      console.log(data)
    };
    fetchData();
  }, [params.id]);
console.log(data?.first_name)
console.log(data?.email)
  const {
    first_name,
    last_name,
    email,
    city,
    country,
   
  } = data;
  const validate = () => {
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (
      !first_name ||
      !last_name  ||
      !email ||
      !city ||
      !country 
    
      
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        vendor_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await updateStaff(params?.id, data);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-staff");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Vendor <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                        <div className="row clearfix">
                        <div className="col-sm-12">
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  style={{
                                    border:
                                      first_nameErr && "1px solid #d32f2f",
                                  }}
                                  type="text"
                                  name="first_name"
                                  value={data?.first_name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      first_nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="First name"
                                />
                              </div>
                              {first_nameErr && (
                                <p className="text-danger">{first_nameErr}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="last_name"
                                  value={data?.last_name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      last_nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Last name"
                                  required="required"
                                />
                              </div>
                              {last_nameErr && (
                                <p className="text-danger">{last_nameErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="email"
                                  value={data?.email}
                                  onChange={(e) => handleChangeEmail(e)}
                                  className="form-control"
                                  placeholder="Email"
                                  required="required"
                                />
                              </div>
                              {emailErr && (
                                <p className="text-danger">{emailErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="country"
                                  value={data?.country}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      countryErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Staff country"
                                  required="required"
                                />
                              </div>
                              {countryErr && (
                                <p className="text-danger">{countryErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="city"
                                  value={data?.city}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      cityErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Staff city"
                                  required="required"
                                />
                              </div>
                              {cityErr && (
                                <p className="text-danger">{cityErr}</p>
                              )}
                            </div>

                            {/* <div className="form-group">
                              <div className="form-line">
                                <label>Staff Image</label>
                                <input
                                  type="file"
                                  name="staff_image"
                                  value={data?.staff_image}
                                  onChange={(e) => onChangeFile(e)}
                                  className="form-control"
                                  placeholder="Business image"
                                  required="required"
                                />
                              </div>
                              {staff_imageErr && (
                                <p className="text-danger">{staff_imageErr}</p>
                              )}
                            </div> */}

                            <button
                              onClick={onSubmit}
                              className="btn btn-primary mt-2"
                              style={
                                !first_name ||
                                !last_name ||
                                !email ||
                                !city ||
                                !country ||
                                staff_imageErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                            >
                              <i className="icon-arrow_forward mr-2"></i>Submit
                            </button>
                          </div> </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditVendor.propTypes = {
  getShops: PropTypes.func.isRequired,
  updateStaff: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pop: state.pop,
  shops: state.shops,
  staff:state.staff,
});

export default connect(mapStateToProps, {
  updateStaff,
  getShops,
})(EditVendor);
