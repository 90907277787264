

import { LIVE_URl, GET_POP_DETAILS,GET_POP_SETTING  } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

export const getPop = (loadingFunc) => async (dispatch) => {
 
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/pop`);
    if (res) {

      loadingFunc(false);
      dispatch({
        type: GET_POP_SETTING,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};
export const popRegistration = (val, setErrorState, ) => {
  return async (dispatch) => {
    
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "Application/json");
      myHeaders.append(
        "Authorization",
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2M2QyYjllYjQ3Zjc2MWYyMDgwZmM3YjIiLCJqdGkiOiJiZjRjNTJmY2ZlOTljNDA3MzEzN2NjYzEwODFlZTNmMDljOTZiMDU2MGM2NzVhNTdlMTU3Mzg2YTkyMTY0YzU2NTUyY2NhZDEwY2UwZTU0MSIsImlhdCI6MTY4MTc2MTY3Mi43MzAwNzg5MzU2MjMxNjg5NDUzMTI1LCJuYmYiOjE2ODE3NjE2NzIuNzMwMDgzOTQyNDEzMzMwMDc4MTI1LCJleHAiOjE3MTMzODQwNzIuNjQ4Mzc1MDM0MzMyMjc1MzkwNjI1LCJzdWIiOiI2M2Q1NGZhN2RiZTM4Y2Y0ZmYwZjE0NTIiLCJzY29wZXMiOltdfQ.HWm27cbJi_qZAXQNmZtOWBBZHFGpcwPZ-1nF-3uP0WVinVUBUPLwHsHLz9bmXxfR6WA1OnAqrVCB6eONjuCAq94b05T3K5WR3Zc-MzFLnJPy06Wxozpr-ypCRIX-fhnCcVWMuF3iKzls2LViq-D_3hCSCciGJ2EJC19lyJac9C9B9lMM79AadXC7xGoDJSqjPKME-AFBbjuRbVK_xXlpZjDSPNtktZujgchDe4AlbELzc9mVPg00LA-5_pUSq9NjMvOqQVHfH46GORdQAqt6a4daZ7KkLvqT0Yk4b5oE8Lyy01QkO0yxSHIw7PMJi5ekyDSGIUoqfbmtqnzqZx5LX9QZOd8aNt6gJ3vzKfTpLKgnwh8JJEyPjD8ptzT319o1g295hoDuFFcoegA4hBfJMkvHNMydSwcsroWrCXZYiLFFmFPYBeiju7pGexAMOSOBNcVD5idjhNRQDafigK2POXonQpmnzShEqP7fvTs8H2TksBLKjtteB8CbjOtvh3_m-YYOzTnfrqfjR6LT8moc2tbiHYcaZMQcFHweKXGpXX08Y4orZBr9_NhhghWb4-3YJCN-xhBOYwbbaTiNxv7_MbGX_AfnMZG8snabxI6Zouwl986utP5pm7B74ZA7fhJlmQ3K4Z9duLpIKd7bx-JaB6Oql9_LiZSSakfqf6O1_aA"
        // `Bearer ${token}`
      );

      var formdata = new FormData();
      formdata.append("shop_id", val.shop_id);
      formdata.append("email", val.email);
      formdata.append("password", val.password);
    

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      dispatch({
        type: GET_POP_DETAILS,
        payload: true,
      });

      let pop = await fetch(
        `${LIVE_URl}/pop`,
        requestOptions
      ).then((response) => {
        return response.json().then((data) => ({
          status: response.status,
          data,
        }));
      });
      if (pop.status === 200 && pop.data.success === true) {
        dispatch({
          type: GET_POP_DETAILS,
          payload: pop.data.data,
        });
          toast.success("Pop Added Successfully");
        
        
      } else {
        if (pop.data.success === false) {
          console.log(pop);
          setErrorState(pop.data.message);
          toast.error("Pop Adding error");

          
        }
        
     
        console.log(pop);
      }
      dispatch({
        type: GET_POP_DETAILS,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_POP_DETAILS,
        payload: false,
      });
    }
  };
};

export const deletePop = (popId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/pop/${popId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getPop(deleteLoaderFunc));
      toast.success("Pop Delete Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);

    toast.error(error.response.data.message);
    return false;
  }
};

export const popUpdate =
  (popId, popDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = popDetails;
    try {
      const res = await axios.put(`${LIVE_URl}/pop/${popId}`, body, config);
      toast.success(res?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };
// getpop details
