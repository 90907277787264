import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  modelNoBtn: {
    background: "#e4e8f0 !important",
    padding: "10px 20px !important",
    fontSize: "1rem !important",
    borderRadius: "4px !important",
    transition: " .5s !important",
    color: "#000 !important",
    border: "0 !important",
    fontWeight: "600 !important",
    lineHeight: "1.25 !important",
    textDecoration: "none !important",
    margin: "2px !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#212529 !important",
    },
  },
  modelYesBtn: {
    background: "#dc3545 !important",
    padding: "10px 20px !important",
    fontSize: "1rem !important",
    borderRadius: "4px !important",
    transition: " .5s !important",
    color: "#fff   !important",
    border: "0 !important",
    fontWeight: "600 !important",
    lineHeight: "1.25 !important",
    textDecoration: "none !important",
    margin: "2px !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#bb2d3b !important",
    },
  },
});

const DeleteModal = ({
  show,
  onHide,
  title,
  bodyText,
  onClickNo,
  onClickYes,
  deleteLoader,
  loader,
  style,
}) => {
  const classes = useStyles();
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button
            className={classes.modelNoBtn}
            onClick={onClickNo}
            style={deleteLoader ? { pointerEvents: "none" } : null}
          >
            No
          </Button>
          <Button
            className={classes.modelYesBtn}
            onClick={onClickYes}
            disabled={deleteLoader ? true : ""}
            style={style}
          >
            Yes
            {deleteLoader && loader}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
