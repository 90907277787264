import React, { useEffect, useState } from "react";
import { deleteVendor } from "../../actions/vendor";
import { getVendor } from "../../actions/vendor";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";

const AllVendor = ({
  vendor: { allVendors },
  toggleNavbar,
  getVendor,
  deleteVendor,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteVendorModal, setDeleteVendorModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [vendorData, setVendorData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getVendor(loadingFunc);
  }, [getVendor]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Vendor MODAL

  const openDeleteVendorModal = (data) => {
    setVendorData(data);
    setDeleteVendorModal(true);
  };

  const closeDeleteVendorModal = () => setDeleteVendorModal(false);

  const deleteVendorFunc = async (e) => {
    e.preventDefault();
    let res = await deleteVendor(vendorData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteVendorModal();
      setVendorData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteVendorModal}
        onHide={closeDeleteVendorModal}
        title="Delete Vendor"
        bodyText="Are you sure, you want to delete the vendor?"
        onClickNo={closeDeleteVendorModal}
        onClickYes={(e) => deleteVendorFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Vendor Name <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                    {
                      hasPermission('add-vendor') &&
                      <Link
                        to="/dashboard/add-vendor"
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Vendor
                      </Link>
                    }
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allVendors?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Vendor Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Phone No</th>
                            <th className="text-left">City</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allVendors?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">
                                {data?.vendor_email}
                              </td>
                              <td className="text-left">
                                {data?.vendor_phone}
                              </td>
                              <td className="text-left">{data?.city}</td>
                              <td className="text-right">
                                {/* <a href="bank_add_account_bussiness.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
                                | */}
                                {
                                  hasPermission('edit-vendor') &&
                                  <Link
                                    to={`/dashboard/edit-vendor/${data?._id}`}
                                  >
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </Link>
                                }
                                {
                                  hasPermission('delete-vendor') &&
                                  <i
                                    className="s-24 icon-trash-can"
                                    onClick={() => openDeleteVendorModal(data)}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllVendor.propTypes = {
  getVendor: PropTypes.func.isRequired,
  deleteVendor: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vendor: state.vendor,
});

export default connect(mapStateToProps, {
  getVendor,
  deleteVendor,
})(AllVendor);
