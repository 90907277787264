import { Box } from "@mui/material";
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import NewSidebar from "./NewSidebar";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";
import * as GrIcons from "react-icons/gr";

import u8 from "../../assets/img/dummy/u8.png";
import logo from "../../assets/img/basic/logo.png";
import AsideBar from "./AsideBar";
import { SidebarData } from "./SidebarData";
import hasPermission from "../../utils/permisssions";

function DashboardContent({ changeToggle, toggleNavbar }) {
  return (
    <Box>
      {/* <Navbar changeToggle={changeToggle} toggleNavbar={toggleNavbar} /> */}
      <NewSidebar changeToggle={changeToggle} toggleNavbar={toggleNavbar} />
      <Outlet />
    </Box>
  );
}

const Dashboard = ({ changeToggle, toggleNavbar }) => {
  const [state, setState] = useState(false);
  const [key, setKey] = useState("");
  let staticPath = "/dashboard";

  // const dropDown = (key) => {
  //   setKey(key);
  // };
  const [isOpen1, setIsOpen1] = useState();
  const [isOpen2, setIsOpen2] = useState();
  const [isOpen3, setIsOpen3] = useState();
  const [isOpen4, setIsOpen4] = useState();
  const [isOpen5, setIsOpen5] = useState();
  const [isOpen6, setIsOpen6] = useState();
  const [isOpen7, setIsOpen7] = useState();
  const [isOpen8, setIsOpen8] = useState();
  const [isOpen9, setIsOpen9] = useState();
  const [isOpen10, setIsOpen10] = useState();
  const [isOpen11, setIsOpen11] = useState();
  const [isOpen12, setIsOpen12] = useState();
  const [isOpen13, setIsOpen13] = useState();
  const [isOpen14, setIsOpen14] = useState();
  const [isOpen15, setIsOpen15] = useState();
  const [isOpen16, setIsOpen16] = useState();
  const [isOpen17, setIsOpen17] = useState();
  const [isOpen18, setIsOpen18] = useState();
 

  const dropDown = (key) => {
    if (key === "1") {
      setIsOpen1(!isOpen1);
      setIsOpen2(false);
    } else if (key === "2") {
      setIsOpen2(!isOpen2);
      setIsOpen1(false);
    }
    else if (key === "3") {
      setIsOpen3(!isOpen3);
      setIsOpen1(false);
    }
    else if (key === "4") {
      setIsOpen4(!isOpen4);
      setIsOpen1(false);
    }
    else if (key === "5") {
      setIsOpen5(!isOpen5);
      setIsOpen1(false);
    }
    else if (key === "6") {
      setIsOpen6(!isOpen6);
      setIsOpen1(false);
    }
    else if (key === "7") {
      setIsOpen7(!isOpen7);
      setIsOpen1(false);
    }
    else if (key === "8") {
      setIsOpen8(!isOpen8);
      setIsOpen1(false);
    } else if (key === "9") {
      setIsOpen9(!isOpen9);
      setIsOpen1(false);
    } else if (key === "10") {
      setIsOpen10(!isOpen10);
      setIsOpen1(false);
    } else if (key === "11") {
      setIsOpen11(!isOpen11);
      setIsOpen1(false);
    } else if (key === "12") {
      setIsOpen12(!isOpen12);
      setIsOpen1(false);
    }
    else if (key === "13") {
      setIsOpen13(!isOpen13);
      setIsOpen1(false);
    }
    else if (key === "14") {
      setIsOpen14(!isOpen14);
      setIsOpen1(false);
    }
    else if (key === "15") {
      setIsOpen15(!isOpen15);
      setIsOpen1(false);
    }
    else if (key === "16") {
      setIsOpen16(!isOpen16);
      setIsOpen1(false);
    }
    else if (key === "17") {
      setIsOpen17(!isOpen17);
      setIsOpen1(false);
    }
    else if (key === "18") {
      setIsOpen18(!isOpen18);
      setIsOpen1(false);
    }
  };
  const showSetting = () => {
    setState(() => !state);
  };
  return (
    <>
      {/* <DashboardContent
        changeToggle={changeToggle}
        toggleNavbar={toggleNavbar}
      /> */}

      <div
        className={
          toggleNavbar
            ? "light loaded "
            : "light loaded sidebar-collapse sidebar-open"
        }
      >
        <div id="loader" className="loader loader-fade">
          <div className="plane-container">
            <div className="preloader-wrapper small active">
              <div className="spinner-layer spinner-blue">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>

              <div className="spinner-layer spinner-red">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>

              <div className="spinner-layer spinner-yellow">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>

              <div className="spinner-layer spinner-green">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="app">
          <aside
            className="main-sidebar fixed offcanvasSide shadow"
            data-toggle="offcanvas"
          >
            <div
              className="slimScrollDiv"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "auto",
                height: "625px",
              }}
            >
              <section
                className="sidebar"
                style={{ height: "625px", overflow: "hidden", width: "auto" }}
              >
                <div
                  className="w-90 mt-3  m-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={logo} alt="" />
                  <AiIcons.AiOutlineClose
                    onClick={changeToggle}
                    style={{ fontSize: "30px", cursor: "pointer" }}
                  />
                </div>
                <div className="relative">
                  <a
                    onClick={showSetting}
                    data-toggle="collapse"
                    href="#userSettingsCollapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="userSettingsCollapse"
                    className="btn-fab btn-fab-sm absolute fab-right-bottom fab-top btn-primary shadow1 "
                  >
                    <i className="icon icon-cogs"></i>
                  </a>
                  <div className="user-panel p-3 light mb-2">
                    <div>
                      <div className="float-left image">
                        <img className="user_avatar" src={u8} alt="User Image" />
                      </div>
                      <div className="float-left info">
                        {sessionStorage.getItem("user_data") ? (
                          <h6 className="font-weight-light mt-2 mb-1">
                            {
                              JSON.parse(sessionStorage.getItem("user_data"))
                                ?.user?.name
                            }
                          </h6>
                        ) : (
                          "Username"
                        )}

                        <a href="#">
                          <i className="icon-circle text-primary blink"></i> Online
                        </a>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div
                      className={
                        state
                          ? "collapse multi-collapse show"
                          : "collapse multi-collapse"
                      }
                      id="userSettingsCollapse"
                    >
                      <div className="list-group mt-3 shadow">
                        <a
                          href="#"
                          className="list-group-item list-group-item-action "
                        >
                          <i className="mr-2 icon-umbrella text-blue"></i>Profile
                        </a>
                        <a
                          href="#"
                          className="list-group-item list-group-item-action"
                        >
                          <i className="mr-2 icon-cogs text-yellow"></i>Settings
                        </a>
                        <a
                          href="#"
                          className="list-group-item list-group-item-action"
                        >
                          <i className="mr-2 icon-security text-purple"></i>Change
                          Password
                        </a>
                        <a
                          href="/"
                          className="list-group-item list-group-item-action"
                        >
                          <i className="mr-2 icon-security text-purple"></i>Log out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="sidebar-menu">
                  <li className="header">
                    <strong>MAIN NAVIGATION</strong>
                  </li>
                  <li
                    className={key === "3" ? "treeview active" : "treeview"}
                    onClick={() => dropDown("3")}
                  >
                    <a href="#">
                      <i className="icon icon-sailing-boat-water purple-text s-18"></i>{" "}
                      <span>Dashboard</span>
                    </a>
                  </li>

                  <li
                    className={isOpen1 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("1")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <MdIcons.MdOutlineBusinessCenter />
                      </i>
                      Business
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen1 ? "treeview-menu menu-open" : "treeview-menu"
                        // key === "1"
                        //   ? "treeview-menu menu-open"
                        //   : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-businesses`}>
                          <i className="icon icon-circle-o"></i>All Business
                        </Link>
                      </li>
                      {
                        hasPermission('add-business') &&
                        <li>
                          <Link to={`${staticPath}/add-business`}>
                            <i className="icon icon-add"></i>Add Business
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen2 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("2")}
                  >
                    <a href="#">
                      <i
                        className="icon icon-shopping_cart
                light-green-text s-18"
                      ></i>
                      Shops<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen2 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-shops`}>
                          <i className="icon icon-circle-o"></i>All Shops
                        </Link>
                      </li>
                      {
                        hasPermission('add-shop') &&

                        <li>
                          <Link to={`${staticPath}/add-shop`}>
                            <i className="icon icon-add"></i>Add Shop
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen3 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("3")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <AiIcons.AiOutlineBank />
                      </i>
                      Banks
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen3 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-banks`}>
                          <i className="icon icon-circle-o"></i>All Banks
                        </Link>
                      </li>
                      {
                        hasPermission('add-bank') &&
                        <li>
                          <Link to={`${staticPath}/add-bank`}>
                            <i className="icon icon-add"></i>Add Bank
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen4 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("4")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <AiIcons.AiOutlinePullRequest />
                      </i>
                      Bank Request
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen4 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-bank-requests`}>
                          <i className="icon icon-circle-o"></i>All Bank Request
                        </Link>
                      </li>
                      {
                        hasPermission('add-bank-request') &&
                        <li>
                          <Link to={`${staticPath}/add-bank-request`}>
                            <i className="icon icon-add"></i>Add Bank Request
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen5 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("5")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <RiIcons.RiAccountBoxLine />
                      </i>
                      Account
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen5 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-accounts`}>
                          <i className="icon icon-circle-o"></i>All Account
                        </Link>
                      </li>
                      {
                        hasPermission('add-account') &&
                        <li>
                          <Link to={`${staticPath}/add-account`}>
                            <i className="icon icon-add"></i>Add Account
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen6 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("6")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <BsIcons.BsShopWindow />
                      </i>
                      Vendor
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen6 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-vendors`}>
                          <i className="icon icon-circle-o"></i>All Vendor
                        </Link>
                      </li>
                      {
                        hasPermission('add-vendor') &&
                        <li>
                          <Link to={`${staticPath}/add-vendor`}>
                            <i className="icon icon-add"></i>Add Vendor
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen7 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("7")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <AiIcons.AiOutlineUser />
                      </i>
                      Customer
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen7 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-customers`}>
                          <i className="icon icon-circle-o"></i>All Customer
                        </Link>
                      </li>
                      {
                        hasPermission('add-customer') &&
                        <li>
                          <Link to={`${staticPath}/add-customer`}>
                            <i className="icon icon-add"></i>Add Customer
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen8 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("8")}
                  >
                    <a href="#">
                      <i className="icon icon-documents light-green-text s-18"></i>
                      Documents
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen8 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/file-convert-bank`}>
                          <i className="icon icon-add"></i>Banks
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/file-convert-sale`}>
                          <i className="icon icon-add"></i>Sale
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/file-convert-cost`}>
                          <i className="icon icon-add"></i>Cost
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={isOpen9 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("9")}
                  >
                    <a href="#">
                      <i className="icon  light-green-text s-18">
                        <TbIcons.TbBusinessplan />
                      </i>
                      Plans
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen9 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-plans`}>
                          <i className="icon icon-circle-o"></i>All Plans
                        </Link>
                      </li>
                      {
                        hasPermission('add-plan') &&
                        <li>
                          <Link to={`${staticPath}/add-plan`}>
                            <i className="icon icon-add"></i>Add Plan
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen10 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("10")}
                  >
                    <a href="#">
                      <i className="icon icon-th light-green-text s-18"></i>Proceed
                      Files<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen10 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <a href="#">
                          <i className="icon icon-add"></i>Banks
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icon icon-add"></i>Sale
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icon icon-add"></i>Cost
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={isOpen11 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("11")}
                  >
                    <a href="#">
                      <i className="icon icon-support light-green-text s-18"></i>
                      Tickets
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen11 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <a href="#">
                          <i className="icon icon-circle-o"></i>All Tickets
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={isOpen12 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("12")}
                  >
                    <a href="#">
                      <i className="icon icon-folder-information light-green-text s-18"></i>
                      Reports
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen12 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                      <Link to={`${staticPath}/invoice-reports`}>
                          <i className="icon con-circle-o"></i>Invoice Report
                        </Link>
                      </li>
                      <li>
                      <Link to={`${staticPath}/statement-reports`}>
                          <i className="icon con-circle-o"></i>Statement Report
                        </Link>
                      </li>
                      <li>
                      <Link to={`${staticPath}/buyer-reports`}>
                          <i className="icon con-circle-o"></i>Buyer Report
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="header light mt-3">
                    <strong>Admin Section</strong>
                  </li>
                  <li
                    className={isOpen13 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("13")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Staff<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen13 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/all-staff`}>
                          <i className="icon icon-circle-o"></i>All Staff
                        </Link>
                      </li>
                      {
                        hasPermission('add-staff') &&
                        <li>
                          <Link to={`${staticPath}/add-staff`}>
                            <i className="icon icon-add"></i>Add Staff
                          </Link>
                        </li>
                      }
                    </ul>
                  </li>

                  <li
                    className={isOpen14 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("14")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Roll Managment
                      <i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen14 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >
                      <li>
                        <Link to={`${staticPath}/roles-and-permission`}>
                          <i className="icon icofont-ui-user-group"></i>Roles &
                          Permissions Assignment
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/all-roles`}>
                          <i className="icon icofont-settings"></i>Roles
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/all-permissions`}>
                          <i className="icon icofont-settings"></i>Permissions
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon icon-bug_report light-green-text s-18"></i>
                      Spam Filter
                    </a>
                  </li>
                  <li
                    className={isOpen15 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("15")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Email Piping<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen15 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >

                      <li>
                        <Link to={`${staticPath}/all-pop`}>
                          <i className="icon icon-circle-o"></i>All POPs
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/pop-settings`}>
                          <i className="icon icon-circle-o"></i>Add POP
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/email_pipping`}>
                          <i className="icon icon-circle-o"></i>Email Piped
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={isOpen16 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("16")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Invoice<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen16 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >

                      <li>
                        <Link to={`${staticPath}/all-invoice`}>
                          <i className="icon icon-circle-o"></i>All Invoices
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/add-invoice`}>
                          <i className="icon icon-circle-o"></i>Add Invoice
                        </Link>
                      </li>
                     
                    </ul>
                  </li>
                  <li
                    className={isOpen17 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("17")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Statement<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen17 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >

                      <li>
                        <Link to={`${staticPath}/all-statement`}>
                          <i className="icon icon-circle-o"></i>All Statement
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/add-statement`}>
                          <i className="icon icon-circle-o"></i>Add Statement
                        </Link>
                      </li>
                     
                    </ul>
                  </li>
                  <li
                    className={isOpen18 ? "treeview active" : "treeview"}
                    onClick={() => dropDown("18")}
                  >
                    <a href="#">
                      <i className="icon icon-account_box light-green-text s-18"></i>
                      Buyers<i className="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul
                      className={
                        isOpen18 ? "treeview-menu menu-open" : "treeview-menu"
                      }
                    >

                      <li>
                        <Link to={`${staticPath}/all-buyer`}>
                          <i className="icon icon-circle-o"></i>All Buyers
                        </Link>
                      </li>
                      <li>
                        <Link to={`${staticPath}/add-buyer`}>
                          <i className="icon icon-circle-o"></i>Add Buyers
                        </Link>
                      </li>
                     
                    </ul>
                  </li>
                  {/* <li>
                    <Link to={`${staticPath}/email_pipping`}>
                      <i className="icon icon-send-o light-green-text s-18"></i>
                      Email Piping
                    </Link>
                  </li> */}
                  <li>
                    <Link to={`${staticPath}/system-setting`}>
                      <i className="icon icofont-settings-alt light-green-text s-18"></i>
                      System Setting
                    </Link>
                  </li>
                </ul>
              </section>
              <div
                className="slimScrollBar"
                style={{
                  background: "rgba(0, 0, 0, 0.3)",
                  width: "5px",
                  position: "absolute",
                  top: "0px",
                  opacity: "0.4",
                  display: "none",
                  borderRadius: " 7px",
                  zIndex: "99",
                  right: "1px",
                  height: "535.837px",
                }}
              ></div>
              <div
                className="slimScrollRail"
                style={{
                  width: "5px",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  display: "none",
                  borderRadius: "7px",
                  background: "rgb(51, 51, 51)",
                  opacity: "0.2",
                  zIndex: "90",
                  right: "1px",
                }}
              ></div>
            </div>
          </aside>
          <div className="has-sidebar-left">
            <div className="pos-f-t">
              <div className="collapse" id="navbarToggleExternalContent">
                <div className="bg-dark pt-2 pb-2 pl-4 pr-2">
                  <div className="search-bar">
                    <input
                      className="transparent s-24 text-white b-0 font-weight-lighter w-128 height-50"
                      type="text"
                      placeholder="start typing..."
                    />
                  </div>
                  <a
                    href="#"
                    data-toggle="collapse"
                    data-target="#navbarToggleExternalContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    className="paper-nav-toggle paper-nav-white active "
                  >
                    <i></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="sticky">
              <div className="navbar navbar-expand navbar-dark d-flex justify-content-between bd-navbar blue accent-3">
                <div className="relative">
                  <div
                    className="d-flex"
                    style={{
                      padding: " .5rem 0.7rem",
                    }}
                  >
                    <div>
                      <a
                        onClick={changeToggle}
                        href="#"
                        data-toggle="push-menu"
                        className="paper-nav-toggle pp-nav-toggle"
                      >
                        <i></i>
                      </a>
                    </div>
                    <div className="d-none d-md-block">
                      <h1 className="nav-title text-white">Dashboard</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page has-sidebar-left">
            {/* <header className="my-3">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <h1 className="s-24">
                      <i className="icon-pages"></i>
                      Bussiness Name <span className="s-14">Registration No</span>
                    </h1>
                  </div>
                </div>
              </div>
            </header>
            <div className="container-fluid my-3">
              <div className="box">
                <section className="paper-card">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="box">
                        <div className="box-header">
                          <a
                            href="bank_add_account_bussiness.html"
                            className="btn btn-sm btn-danger float-right mb-4"
                          >
                            Add New Bank Account
                          </a>
                        </div>
                        <div className="box-body no-padding">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th style={{ width: "10px" }}>#</th>
                                <th className="text-left">Bank Name</th>
                                <th className="text-left">Account No</th>
                                <th className="text-left">Shop</th>
                                <th style={{ width: "160px" }}>Action</th>
                              </tr>
                              <tr>
                                <td>1.</td>
                                <td className="text-left">Bank of UK </td>
                                <td className="text-left">UK617SUB0081910191919</td>
                                <td className="text-left"> Shop No 4</td>
                                <td className="text-right">
                                  <a href="bank_add_account_bussiness.html">
                                    {" "}
                                    <i className="s-24 icon-eye"> </i>{" "}
                                  </a>
                                  |
                                  <a href="bank_add_account_bussiness.html">
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </a>
                                  |<i className="s-24 icon-trash-can"></i>
                                </td>
                              </tr>

                              <tr>
                                <td>2.</td>
                                <td className="text-left">Bank of UK </td>
                                <td className="text-left">
                                  UK617SUB00819101516170
                                </td>
                                <td className="text-left"> Shop No 2</td>
                                <td className="text-right">
                                  <a href="bank_add_account_bussiness.html">
                                    {" "}
                                    <i className="s-24 icon-eye"> </i>{" "}
                                  </a>
                                  |
                                  <a href="bank_add_account_bussiness.html">
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </a>
                                  |<i className="s-24 icon-trash-can"></i>
                                </td>
                              </tr>

                              <tr>
                                <td>3.</td>
                                <td className="text-left">Bank of UK </td>
                                <td className="text-left">UK617SUB0081910191912</td>
                                <td className="text-left"> Shop No 1</td>
                                <td className="text-right">
                                  <a href="bank_add_account_bussiness.html">
                                    {" "}
                                    <i className="s-24 icon-eye"> </i>{" "}
                                  </a>
                                  |
                                  <a href="bank_add_account_bussiness.html">
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </a>
                                  |<i className="s-24 icon-trash-can"></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="box-tools">
                            <ul className="pagination pagination-sm no-margin float-right">
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  «
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  1
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  3
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  »
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div> */}
            <Outlet />
          </div>
          <aside className="control-sidebar fixed white ">
            <div
              className="slimScrollDiv"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "auto",
                height: "625px",
              }}
            >
              <div
                className="slimScroll"
                style={{
                  overflow: "hidden",
                  width: "auto",
                  height: "625px",
                }}
              >
                <div className="sidebar-header">
                  <h4>Activity List</h4>
                  <a
                    href="#"
                    data-toggle="control-sidebar"
                    className="paper-nav-toggle  active"
                  >
                    <i></i>
                  </a>
                </div>
                <div className="p-3">
                  <div>
                    <div className="my-3">
                      <small>25% Complete</small>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "25%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="my-3">
                      <small>45% Complete</small>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "45%" }}
                          aria-valuenow="45"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="my-3">
                      <small>60% Complete</small>`
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="my-3">
                      <small>75% Complete</small>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "75%" }}
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="my-3">
                      <small>100% Complete</small>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 bg-primary text-white">
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="font-weight-normal s-14">Sodium</h5>
                      <span className="font-weight-lighter text-primary">
                        Spark Bar
                      </span>
                      <div>
                        {" "}
                        Oxygen
                        <span className="text-primary">
                          <i className="icon icon-arrow_downward"></i> 67%
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="chartjs-size-monitor"
                        style={{
                          position: "absolute",
                          inset: "0px",
                          overflow: "hidden",
                          pointerEvents: "none",
                          visibility: "hidden",
                          zIndex: "-1",
                        }}
                      >
                        <div
                          className="chartjs-size-monitor-expand"
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            overflow: "hidden",
                            pointerEvents: "none",
                            visibility: "hidden",
                            zIndex: "-1",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "1000000px",
                              height: "1000000px",
                              left: "0",
                              top: "0",
                            }}
                          ></div>
                        </div>
                        <div
                          className="chartjs-size-monitor-shrink"
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            overflow: "hidden",
                            pointerEvents: "none",
                            visibility: "hidden",
                            zIndex: "-1",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "200%",
                              height: "200%",
                              left: "0",
                              top: "0",
                            }}
                          ></div>
                        </div>
                      </div>
                      <canvas
                        width="298"
                        height="208"
                        data-chart="spark"
                        data-chart-type="bar"
                        data-dataset="[[28,68,41,43,96,45,100,28,68,41,43,96,45,100,28,68,41,43,96,45,100,28,68,41,43,96,45,100]]"
                        data-labels="['a','b','c','d','e','f','g','h','i','j','k','l','m','n','a','b','c','d','e','f','g','h','i','j','k','l','m','n']"
                        className="chartjs-render-monitor js-chart-drawn"
                        style={{
                          display: "block",
                          width: "298px",
                          height: "208px",
                        }}
                      ></canvas>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="recent-orders"
                    className="table table-hover mb-0 ps-container ps-theme-default"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <a href="#">INV-281281</a>
                        </td>
                        <td>
                          <span className="badge badge-success">Paid</span>
                        </td>
                        <td>$ 1228.28</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">INV-01112</a>
                        </td>
                        <td>
                          <span className="badge badge-warning">Overdue</span>
                        </td>
                        <td>$ 5685.28</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">INV-281012</a>
                        </td>
                        <td>
                          <span className="badge badge-success">Paid</span>
                        </td>
                        <td>$ 152.28</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">INV-01112</a>
                        </td>
                        <td>
                          <span className="badge badge-warning">Overdue</span>
                        </td>
                        <td>$ 5685.28</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">INV-281012</a>
                        </td>
                        <td>
                          <span className="badge badge-success">Paid</span>
                        </td>
                        <td>$ 152.28</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="sidebar-header">
                  <h4>Activity</h4>
                  <a
                    href="#"
                    data-toggle="control-sidebar"
                    className="paper-nav-toggle  active"
                  >
                    <i></i>
                  </a>
                </div>
                <div className="p-4">
                  <div className="activity-item activity-primary">
                    <div className="activity-content">
                      <small className="text-muted">
                        <i className="icon icon-user position-left"></i> 5 mins ago
                      </small>
                      <p>
                        Lorem ipsum dolor sit amet conse ctetur which ascing
                        elit users.
                      </p>
                    </div>
                  </div>
                  <div className="activity-item activity-danger">
                    <div className="activity-content">
                      <small className="text-muted">
                        <i className="icon icon-user position-left"></i> 8 mins ago
                      </small>
                      <p>
                        Lorem ipsum dolor sit ametcon the sectetur that ascing
                        elit users.
                      </p>
                    </div>
                  </div>
                  <div className="activity-item activity-success">
                    <div className="activity-content">
                      <small className="text-muted">
                        <i className="icon icon-user position-left"></i> 10 mins ago
                      </small>
                      <p>
                        Lorem ipsum dolor sit amet cons the ecte tur and adip
                        ascing elit users.
                      </p>
                    </div>
                  </div>
                  <div className="activity-item activity-warning">
                    <div className="activity-content">
                      <small className="text-muted">
                        <i className="icon icon-user position-left"></i> 12 mins ago
                      </small>
                      <p>
                        Lorem ipsum dolor sit amet consec tetur adip ascing elit
                        users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="slimScrollBar"
                style={{
                  background: "rgba(0, 0, 0, 0.95)",
                  width: "5px",
                  position: "absolute",
                  top: "0px",
                  opacity: "0.4",
                  display: "block",
                  borderRadius: "7px",
                  zIndex: "99",
                  right: "1px",
                  height: "350.022px",
                }}
              ></div>
              <div
                className="slimScrollRail"
                style={{
                  width: "5px",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  display: "none",
                  borderRadius: "7px",
                  background: "rgb(51, 51, 51)",
                  opacity: " 0.2",
                  zIndex: "90",
                  right: "1px",
                }}
              ></div>
            </div>
          </aside>
          <div className="control-sidebar-bg shadow white fixed"></div>
        </div>

        <div
          className="tooltip-inner"
          id="line-chart-tooltip"
          style={{ position: "absolute", display: "none", opacity: "0.8" }}
        ></div>
      </div>
    </>
  );
};

export default Dashboard;
