import { LIVE_URl, GET_SHOPS, GET_SHOP_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL SHOPS

export const getShops = (loadingFunc) => async (dispatch,data) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/shop`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_SHOPS,
        payload: res?.data?.data,
      });
      console.log(data)
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// SHOP REGISTRATION

export const shopsRegistration = (shopsDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const body = shopsDetails;
  try {
    const res = await axios.post(`${LIVE_URl}/shop`, body, config);
    if (res) {
      dispatch(getShops());
      toast.success("Shop Registration Successfully");
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

// SHOP UPDATE

export const shopUpdate =
  (shipId, shopsDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = shopsDetails;
    try {
      const res = await axios.put(`${LIVE_URl}/shop/${shipId}`, body, config);
      toast.success(res?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// GET SHOP DETAILS
export const getShopDetails = (id, loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/shop/${id}`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_SHOP_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// DELETE SHOP

export const deleteShop = (shopId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/shop/${shopId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getShops(deleteLoaderFunc));
      toast.success("Shop Delete Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);

    toast.error(error.response.data.message);
    return false;
  }
};
