import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shopsRegistration } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";
import { useNavigate } from "react-router-dom";

const AddShop = ({
  shopsRegistration,
  getBusinesses,
  business: { allBusiness },
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    shop_name: "",
    contact_number: "",
    shop_email: "",
    address: "",
    vat: "",
    about: "",
    shop_image: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    city: "Lahore",
    country: "Pakistan",
    business_id: "",
  });
  const [errors, setErrors] = useState({
    shop_nameErr: "",
    shop_emailErr: "",
    contact_numberErr: "",
    addressErr: "",
    vatErr: "",
    aboutErr: "",
    shop_imageErr: "",
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    passwordErr: "",
    business_idErr: "",
  });

  const {
    shop_name,
    contact_number,
    shop_email,
    address,
    vat,
    about,
    shop_image,
    first_name,
    last_name,
    email,
    password,
    business_id,
  } = data;

  const {
    shop_nameErr,
    shop_emailErr,
    contact_numberErr,
    addressErr,
    vatErr,
    aboutErr,
    shop_imageErr,
    first_nameErr,
    last_nameErr,
    emailErr,
    passwordErr,
    business_idErr,
  } = errors;

  useEffect(() => {
    getBusinesses(loadingFunc);
  }, [getBusinesses]);

  const validate = () => {
    if (!shop_name) {
      setErrors((prev) => ({
        ...prev,
        shop_nameErr: "Please provide shop name",
      }));
    }
    if (!contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!shop_email) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: "Please provide shop email",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide shop address",
      }));
    }
    if (!vat) {
      setErrors((prev) => ({
        ...prev,
        vatErr: "Please provide shop vat",
      }));
    }
    if (!about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!shop_image) {
      setErrors((prev) => ({
        ...prev,
        shop_imageErr: "Please provide shop image",
      }));
    }
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!business_id) {
      setErrors((prev) => ({
        ...prev,
        business_idErr: "Please provide business",
      }));
    }

    if (
      !shop_name ||
      !contact_number ||
      !shop_email ||
      !address ||
      !vat ||
      !about ||
      !shop_image ||
      !first_name ||
      !last_name ||
      !email ||
      !password ||
      !business_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeShopEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, shop_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, shop_email: e.target.value }));
      setErrors((prev) => ({ ...prev, shop_emailErr: "" }));
    }
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("first");
      try {
        let response = await shopsRegistration(data);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-shops");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Shops Registration 
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="shop_name"
                                value={shop_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Shop name"
                              />
                            </div>
                            {shop_nameErr && (
                              <p className="text-danger">{shop_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                value={shop_email}
                                onChange={(e) => handleChangeShopEmail(e)}
                                className="form-control"
                                placeholder="Shop email"
                                required="required"
                              />
                            </div>
                            {shop_emailErr && (
                              <p className="text-danger">{shop_emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="contact_number"
                                value={contact_number}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    contact_numberErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Contact number"
                                required="required"
                              />
                            </div>
                            {contact_numberErr && (
                              <p className="text-danger">{contact_numberErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="vat"
                                value={vat}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    vatErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Shop vat"
                                required="required"
                              />
                            </div>
                            {vatErr && <p className="text-danger">{vatErr}</p>}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="address"
                                value={address}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    addressErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Shop address"
                                required="required"
                              />
                              {addressErr && (
                                <p className="text-danger">{addressErr}</p>
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <textarea
                                type="text"
                                name="about"
                                value={about}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    aboutErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="About"
                                required="required"
                              />
                            </div>
                            {aboutErr && (
                              <p className="text-danger">{aboutErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Shop Logo</label>
                              <input
                                type="file"
                                name="shop_image"
                                style={{
                                  border: shop_imageErr && "1px solid #d32f2f",
                                }}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_imageErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Shop image"
                                required="required"
                              />
                            </div>
                            {shop_imageErr && (
                              <p className="text-danger">{shop_imageErr}</p>
                            )}
                          </div>

                          <h1 className="s-24">Manager Information</h1>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="first_name"
                                value={first_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    first_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="First name"
                              />
                            </div>
                            {first_nameErr && (
                              <p className="text-danger">{first_nameErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="last_name"
                                value={last_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    last_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Last name"
                                required="required"
                              />
                            </div>
                            {last_nameErr && (
                              <p className="text-danger">{last_nameErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => handleChangeEmail(e)}
                                className="form-control"
                                placeholder="Email"
                                required="required"
                                autoComplete="off"
                              />
                            </div>
                            {emailErr && (
                              <p className="text-danger">{emailErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="password"
                                name="password"
                                autoComplete="off"

                                style={{
                                  border: passwordErr && "1px solid #d32f2f",
                                }}
                                value={password}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                  }));
                                  if (Number(e.target.value.length) < 8) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr:
                                        "Password must be atleast 8 characters",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr: "",
                                    }));
                                  }
                                }}
                                className="form-control"
                                placeholder="Password"
                                required="required"
                              />
                            </div>
                            {passwordErr && (
                              <p className="text-danger">{passwordErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <select
                                name="business_id"
                                className="custom-select form-control"
                                value={business_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an business
                                </option>
                                {allBusiness.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {business_idErr && (
                              <p className="text-danger">{business_idErr}</p>
                            )}
                          </div>

                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-2"
                            style={
                              loading ||
                              shop_nameErr ||
                              shop_emailErr ||
                              contact_numberErr ||
                              addressErr ||
                              vatErr ||
                              aboutErr ||
                              shop_imageErr ||
                              first_nameErr ||
                              last_nameErr ||
                              emailErr ||
                              passwordErr ||
                              business_idErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* <div className="col-md-5">
              <h3>Documentation</h3>
              <hr />
              <p>
                Subscription Plan: Starter
                <br />
                Shops: 06
                <br />
                Staff: 06 x 3
              </p>

              <p>
                <strong> Account Information</strong>
                <br />
                Bank Name - Title - Account No <br />
                Bank Name - Title - Account No
                <br />
                Bank Name - Title - Account No
              </p>

              <a
                href="bank_add_account_bussiness.html"
                target="_blank"
                className="btn btn-xs btn-danger"
              >
                Add New Account Number
              </a>

              <a
                href="#"
                target="_blank"
                className="btn btn-xs btn-primary m-1"
              >
                Update Package
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

AddShop.propTypes = {
  shopsRegistration: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  business: state.business,
});

export default connect(mapStateToProps, {
  shopsRegistration,
  getBusinesses,
})(AddShop);
