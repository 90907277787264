import { GET_SHOPS, GET_SHOP_DETAILS } from "../actions/types";

const initialState = {
  allShops: [],
  shopDetails: null,
};

export default function shops(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SHOPS:
      return {
        ...state,
        allShops: payload,
      };
    case GET_SHOP_DETAILS:
      return {
        ...state,
        shopDetails: payload,
      };
    default:
      return state;
  }
}
