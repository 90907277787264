import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUserInfo,
  getRoles,
  updatePermission,
  getPermission,
  assignPermissionAndRoleToUSer,
} from "../../actions/role";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";

const options = {
  permissions: [
    {
      name: "modify",
      description:
        "Allows the user to modify the properties of a file or directory",
    },
    {
      name: "rename",
      description: "Allows the user to rename a file or directory",
    },
    {
      name: "copy",
      description:
        "Allows the user to copy a file or directory to a different location",
    },
    {
      name: "move",
      description:
        "Allows the user to move a file or directory to a different location",
    },
    {
      name: "share",
      description:
        "Allows the user to share a file or directory with other users",
    },
    {
      name: "print",
      description: "Allows the user to print a file or document",
    },
    {
      name: "download",
      description: "Allows the user to download a file from the server",
    },
    {
      name: "upload",
      description: "Allows the user to upload a file to the server",
    },
    {
      name: "execute_script",
      description: "Allows the user to execute a script or program",
    },
    {
      name: "access_logs",
      description: "Allows the user to access server logs",
    },
    {
      name: "view_users",
      description: "Allows the user to view a list of all users on the system",
    },
    {
      name: "edit_users",
      description: "Allows the user to edit user profiles and account settings",
    },
    {
      name: "change_password",
      description: "Allows the user to change their own password",
    },
    {
      name: "reset_password",
      description: "Allows the user to reset passwords for other users",
    },
    {
      name: "manage_permissions",
      description:
        "Allows the user to modify permissions for files and directories",
    },
    {
      name: "view_logs",
      description: "Allows the user to view system logs and error messages",
    },
    {
      name: "create_group",
      description: "Allows the user to create a new group for users",
    },
    {
      name: "delete_group",
      description: "Allows the user to delete a group and remove users from it",
    },
    {
      name: "add_user_to_group",
      description: "Allows the user to add other users to a group",
    },
    {
      name: "remove_user_from_group",
      description: "Allows the user to remove other users from a group",
    },
  ],
};

const RolesAndPermissions = ({
  role: { allRoles, allPermissions, userDetails },
  getUserInfo,
  getRoles,
  updatePermission,
  getPermission,
  assignPermissionAndRoleToUSer,
}) => {
  const navigate = useNavigate();
  const params = useParams();


  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    userDetails?.permissions_via_roles || []
  );
  const [selectedRole, setSelectedRole] = useState(userDetails?.roles || []);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const dataLoadingFunc = (key) => {
    setDataLoading(key);
  };

  useEffect(() => {
    getRoles(dataLoadingFunc);
    getPermission(dataLoadingFunc);
  }, [getRoles, getPermission]);

  useEffect(() => {
    getUserInfo(params?.id);
  }, [params?.id]);

  const handleOptionChange = (option) => {
    const index = selectedOptions.indexOf(option);
    if (index > -1) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleRoleChange = (event) => {
    const roleName = event.target.value;
    const selected = [...selectedRole];
    const index = selected.indexOf(roleName);
    if (index === -1) {
      selected.push(roleName);
    } else {
      selected.splice(index, 1);
    }
    setSelectedRole(selected);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await assignPermissionAndRoleToUSer(
        params.id,
        selectedOptions,
        selectedRole
      );
      setLoading(false);
      if (response) {
        navigate("/dashboard/roles-and-permission");
      }
    } catch (err) {
      setLoading(false);
    }
  };
  RolesAndPermissions.propTypes = {
    updatePermission: PropTypes.object.isRequired,

  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit User
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card p-4">
            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <div className="my-2">
                  <h4 className="text-left">Roles</h4>
                  <div className="d-flex flex-wrap">
                    {allRoles?.map((data, i) => (
                      <div key={data?._id}>
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked
                              value={data?.name}
                              checked={selectedRole.includes(data?.name)}
                              onChange={handleRoleChange}
                            />
                          }
                          label={data?.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="my-2">
                  <h4 className="text-left">Permissions</h4>
                  <div className="checkbox-container">
                    {allPermissions?.map((option, i) => (
                      <div className="checkbox-item" key={option?._id}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option?.name)}
                          onChange={() => handleOptionChange(option?.name)}
                        />
                        <label htmlFor={option?.name}>{option?.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="d-flex my-2">
              <button
                onClick={onSubmit}
                className="btn btn-primary"
                style={loading ? { pointerEvents: "none" } : null}
              >
                Submit
                {loading ? (
                  <Icofont
                    icon="spinner"
                    style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                    spin="true"
                  />
                ) : (
                  <i
                    className="icon-arrow_forward mr-2"
                    style={{ marginLeft: "5px" }}
                  ></i>
                )}
              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

RolesAndPermissions.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getPermission: PropTypes.func.isRequired,
  assignPermissionAndRoleToUSer: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  updatePermission:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  getUserInfo,
  getRoles,
  getPermission,
  updatePermission,
  assignPermissionAndRoleToUSer,
})(RolesAndPermissions);
