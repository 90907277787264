import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { getDocuments, deleteDocument } from "../../actions/document";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEye, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddAccount from "../../components/forms/AddAccount";
import EditAccount from "../../components/forms/EditAccount";
import AddPlan from "../../components/forms/AddPlan";
import EditPlan from "../../components/forms/EditPlan";
import AddDocument from "../../components/forms/AddDocument";

const Document = ({
  document: { allDocuments },
  toggleNavbar,
  getDocuments,
  deleteDocument,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [documentData, setDocumentData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };
  console.log(allDocuments)

  const toggleAddDocumentForm = () => {
    setToggleForm(true);
  };

  const closeAddDocumentForm = () => {
    setToggleForm(false);
  };

  const toggleEditDocumentForm = (data) => {
    setDocumentData(data);
    setToggleEditForm(true);
  };

  const closeEditDocumentForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getDocuments(loadingFunc);
  }, [getDocuments]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Document MODAL

  const openDeleteDocumentModal = (data) => {
    setDocumentData(data);
    setDeleteDocumentModal(true);
  };

  const closeDeleteDocumentModal = () => setDeleteDocumentModal(false);

  const deleteDocumentFunc = async (e) => {
    e.preventDefault();
    let res = await deleteDocument(documentData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteDocumentModal();
      setDocumentData("");
    }
  };

  return (
    <>
      {/* ADD Document MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddDocumentForm}
        bodyContent={
          <AddDocument closeAddDocumentForm={closeAddDocumentForm} />
        }
        title="Add Document"
      />

      {/* EDIT Document MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditDocumentForm}
        bodyContent={
          <EditPlan
            closeEditDocumentForm={closeEditDocumentForm}
            documentData={documentData}
          />
        }
        title="Edit Document"
      />

      <DeleteModal
        show={showDeleteDocumentModal}
        onHide={closeDeleteDocumentModal}
        title="Delete Document"
        bodyText="Are you sure, you want to delete the Document?"
        onClickNo={closeDeleteDocumentModal}
        onClickYes={(e) => deleteDocumentFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Document Registration
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <a
                        onClick={(e) => toggleAddDocumentForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Document
                      </a>
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allDocuments?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Type</th>
                              <th className="text-left">File Link</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allDocuments?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">
                                  {data?.documentable_type}
                                </td>
                                <td className="text-left">
                                  <a href={data?.file_link} target="_blank">
                                    Link
                                  </a>
                                </td>
                                <td>
                                  <FaEdit
                                    // onClick={() => toggleEditDocumentForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  |
                                  <AiFillDelete
                                    // onClick={() =>
                                    //   openDeleteDocumentModal(data)
                                    // }
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

Document.propTypes = {
  getDocuments: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  document: state.document,
});

export default connect(mapStateToProps, {
  getDocuments,
  deleteDocument,
})(Document);
