import React, { useEffect, useState } from "react";
import { deleteBank, getBanks } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Loader from "../../components/global/Loader";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AllBanks = ({
  bank: { allBanks },
  toggleNavbar,
  getBanks,
  deleteBank,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteBankModal, setDeleteBankModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [bankData, setBankData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getBanks(loadingFunc);
  }, [getBanks]);
console.log(allBanks)
  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE BANK MODAL

  const openDeleteBankModal = (data) => {
    console.log(data?.name)
    setBankData(data);
    setDeleteBankModal(true);
  };

  const closeDeleteBankModal = () => setDeleteBankModal(false);
const deleteBankFunc = async (e) => {
    e.preventDefault();
    let res = await deleteBank(bankData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteBankModal();
      setBankData("");

    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteBankModal}
        onHide={closeDeleteBankModal}
        title="Delete Bank"
        bodyText="Are you sure, you want to delete the bank?"
        onClickNo={closeDeleteBankModal}
        onClickYes={(e) => deleteBankFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Bank Name <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {
                      hasPermission('add-bank') &&
                    <Link
                      to="/dashboard/add-bank"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Bank Account
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allBanks?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Bank Name</th>
                            <th className="text-left">Country</th>
                            <th className="text-left">City</th>
                            <th className="text-left" style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allBanks?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">{data?.country}</td>
                              <td className="text-left">{data?.city}</td>
                              <td className="text-left">
                              {
                                  hasPermission('edit-bank') &&
                                <Link to={`/dashboard/edit-bank/${data?._id}`}>
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
}
                                {/* |
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeleteBankModal(data)}
                                  style={{ cursor: "pointer" }}
                                ></i> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
AllBanks.propTypes = {
  getBanks: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getBanks,
  deleteBank,
})(AllBanks);
