import React, { useEffect, useState } from "react";
import { staffRegistration } from "../../actions/staff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getShops } from "../../actions/shops";
import { getRoles } from "../../actions/role";
import { useNavigate } from "react-router-dom";

const AddStaff = ({
  staffRegistration,
  getShops,
  getRoles,
  shops: { allShops },
  role: { allRoles },
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
    getRoles(loadingFunc);
  }, [getShops, getRoles]);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: "",
    city: "",
    country: "",
    staff_image: "",
    shop_id: "",
    role_id: "",
  });
  const [errors, setErrors] = useState({
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    passwordErr: "",
    password_confirmErr: "",
    cityErr: "",
    countryErr: "",
    staff_imageErr: "",
    shop_idErr: "",
    role_idErr: "",
  });

  const {
    first_name,
    last_name,
    email,
    password,
    password_confirm,
    city,
    country,
    staff_image,
    shop_id,
    role_id,
  } = data;

  const {
    first_nameErr,
    last_nameErr,
    emailErr,
    passwordErr,
    password_confirmErr,
    cityErr,
    countryErr,
    staff_imageErr,
    shop_idErr,
    role_idErr,
  } = errors;

  const validate = () => {
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!password_confirm) {
      setErrors((prev) => ({
        ...prev,
        password_confirmErr: "Please provide confirm password",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!staff_image) {
      setErrors((prev) => ({
        ...prev,
        staff_imageErr: "Please provide staff image",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!role_id) {
      setErrors((prev) => ({
        ...prev,
        role_idErr: "Please select role",
      }));
    }

    if (
      !first_name ||
      !last_name ||
      !email ||
      !password ||
      !password_confirm ||
      !city ||
      !country ||
      !staff_image ||
      !shop_id ||
      !role_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      staff_imageErr: "",
    }));
    setData({ ...data, staff_image: file });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        let response = await staffRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-staff");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
               Add Staff <span className="s-14">Registration Number</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                style={{
                                  border: first_nameErr && "1px solid #d32f2f",
                                }}
                                type="text"
                                name="first_name"
                                value={first_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    first_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="First name"
                              />
                            </div>
                            {first_nameErr && (
                              <p className="text-danger">{first_nameErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="last_name"
                                value={last_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    last_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Last name"
                                required="required"
                              />
                            </div>
                            {last_nameErr && (
                              <p className="text-danger">{last_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => handleChangeEmail(e)}
                                className="form-control"
                                placeholder="Email"
                                required="required"
                              />
                            </div>
                            {emailErr && (
                              <p className="text-danger">{emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="country"
                                value={country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    countryErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Staff country"
                                required="required"
                              />
                            </div>
                            {countryErr && (
                              <p className="text-danger">{countryErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="city"
                                value={city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    cityErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Staff city"
                                required="required"
                              />
                            </div>
                            {cityErr && (
                              <p className="text-danger">{cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                  }));
                                  if (Number(e.target.value.length) < 8) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr:
                                        "Password must be atleast 8 characters",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr: "",
                                    }));
                                  }
                                }}
                                className="form-control"
                                placeholder="Password"
                                required="required"
                              />
                            </div>
                            {passwordErr && (
                              <p className="text-danger">{passwordErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="password"
                                name="password_confirm"
                                value={password_confirm}
                                onChange={(e) => {
                                  if (password && e.target.value !== password) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      password_confirmErr:
                                        "Password and Confirm Password does not match.",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      password_confirmErr: "",
                                    }));
                                  }
                                  setData({
                                    ...data,
                                    password_confirm: e.target.value,
                                  });
                                }}
                                className="form-control"
                                placeholder="Confirm password"
                                required="required"
                              />
                            </div>
                            {password_confirmErr && (
                              <p className="text-danger">
                                {password_confirmErr}
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Staff Image</label>
                              <input
                                type="file"
                                name="staff_image"
                                onChange={(e) => onChangeFile(e)}
                                className="form-control"
                                placeholder="Business image"
                                required="required"
                              />
                            </div>
                            {staff_imageErr && (
                              <p className="text-danger">{staff_imageErr}</p>
                            )}
                          </div>

                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-2"
                            style={
                              loading ||
                              first_nameErr ||
                              last_nameErr ||
                              emailErr ||
                              passwordErr ||
                              password_confirmErr ||
                              cityErr ||
                              countryErr ||
                              staff_imageErr ||
                              shop_idErr ||
                              role_idErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Documentation</h3>
              <hr />
              <p>
                Subscription Plan: Starter
                <br />
                Shops: 06
                <br />
                Staff: 06 x 3
              </p>
              <div className="form-group">
                <div className="form-line">
                  <select
                    name="shop_id"
                    className="custom-select form-control"
                    value={shop_id}
                    required="required"
                    onChange={(e) => {
                      onChangeFields(e);
                      setErrors((prev) => ({
                        ...prev,
                        shop_idErr: "",
                      }));
                    }}
                  >
                    <option value="" disabled={true}>
                      Select an shop
                    </option>
                    {allShops.map((data, i) => (
                      <option key={i} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                {shop_idErr && <p className="text-danger">{shop_idErr}</p>}
              </div>

              <div className="form-group mt-4">
                <div className="form-line">
                  <select
                    name="role_id"
                    className="custom-select form-control"
                    value={role_id}
                    required="required"
                    onChange={(e) => {
                      onChangeFields(e);
                      setErrors((prev) => ({
                        ...prev,
                        role_idErr: "",
                      }));
                    }}
                  >
                    <option value="" disabled={true}>
                      Select an role
                    </option>
                    {allRoles.map((data, i) => (
                      <option key={i} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                {role_idErr && <p className="text-danger">{role_idErr}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddStaff.propTypes = {
  getShops: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  staffRegistration: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staff,
  shops: state.shops,
  role: state.role,
});

export default connect(mapStateToProps, {
  staffRegistration,
  getShops,
  getRoles,
})(AddStaff);
