import React, { useEffect, useState } from "react";
import { getVendor } from "../../actions/vendor";
import { getDocuments } from "../../actions/document";
import { documentRegistration } from "../../actions/document";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import CustomButton from "../../components/global/Button";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    textAlign: "left",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "left",
    margin: "10px 0",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const CostDocument = ({
  toggleNavbar,
  vendor: { allVendors },
  getVendor,
  documentRegistration,
  document: { allDocuments },
  getDocuments,
  shops: { allShops },
  getShops,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    document_name: "",
    document_type: "",
    document_type_id: "",
    customer_id: "",
    vendor_id: "",
    bank_id: "",
    shop_id: "",
    file_link: "",
  });
  const [errors, setErrors] = useState({
    document_nameErr: "",
    document_typeErr: "",
    document_type_idErr: "",
    customer_idErr: "",
    vendor_idErr: "",
    bank_idErr: "",
    shop_idErr: "",
    file_linkErr: "",
  });

  const {
    document_name,
    document_type,
    document_type_id,
    customer_id,
    vendor_id,
    bank_id,
    shop_id,
    file_link,
  } = data;

  const {
    document_nameErr,
    document_typeErr,
    document_type_idErr,
    customer_idErr,
    vendor_idErr,
    bank_idErr,
    shop_idErr,
    file_linkErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getVendor(loadingFunc);
    getDocuments(loadingFunc);
    getShops(loadingFunc);
  }, [getVendor, getDocuments, getShops]);

  const validate = () => {
    if (!document_name) {
      setErrors((prev) => ({
        ...prev,
        document_nameErr: "Please provide document name",
      }));
    }
    if (!vendor_id) {
      setErrors((prev) => ({
        ...prev,
        vendor_idErr: "Please provide vendor",
      }));
    }
    if (!file_link) {
      setErrors((prev) => ({
        ...prev,
        file_linkErr: "Please select file link",
      }));
    }

    if (!document_name || !vendor_id || !file_link) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      file_linkErr: "",
    }));
    setData({ ...data, file_link: file });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let dataForm = new FormData();
        dataForm.append("document_name", document_name);
        dataForm.append("document_type", JSON.stringify("App\\Models\\Vendor"));
        dataForm.append("document_type_id", vendor_id);
        dataForm.append("customer_id", "0");
        dataForm.append("vendor_id", vendor_id);
        dataForm.append("bank_id", "0");
        dataForm.append("shop_id", shop_id);
        dataForm.append("file_link", file_link);
        let response = await documentRegistration(dataForm, loadingFunc);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <div className="page ">
          <header className="my-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h1 className="s-24">
                    <i
                      className="icofont-brand-china-unicom"
                      style={{ marginRight: "5px" }}
                    ></i>
                    File Conversion
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <div className="container-fluid my-3">
            <div className="box">
              <section className="paper-card">
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <div className="box-header">
                        <Row className={classes.row}>
                          <Col sm={12} md={6}>
                            <label>Document Name</label>
                            <input
                              style={{
                                border: document_nameErr && "1px solid #d32f2f",
                              }}
                              type="text"
                              name="document_name"
                              value={document_name}
                              onChange={(e) => {
                                onChangeFields(e);
                                setErrors((prev) => ({
                                  ...prev,
                                  document_nameErr: "",
                                }));
                              }}
                              className="form-control"
                              placeholder="Document name"
                            />
                            {document_nameErr && (
                              <Typography className={classes.errorMsg}>
                                {document_nameErr}
                              </Typography>
                            )}
                          </Col>
                          <Col sm={12} md={6}>
                            <label>Vendor</label>
                            <div className="select">
                              <select
                                name="vendor_id"
                                style={{
                                  border: vendor_idErr && "1px solid #d32f2f",
                                }}
                                className="custom-select form-control"
                                value={vendor_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    vendor_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an vendor
                                </option>
                                {allVendors?.map((data, i) => (
                                  <option value={data?._id} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                              {vendor_idErr && (
                                <Typography className={classes.errorMsg}>
                                  {vendor_idErr}
                                </Typography>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className={classes.row}>
                          <Col sm={12} md={6}>
                            <label>Shop</label>
                            <div className="select">
                              <select
                                name="shop_id"
                                style={{
                                  border: shop_idErr && "1px solid #d32f2f",
                                }}
                                className="custom-select form-control"
                                value={shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops?.map((data, i) => (
                                  <option value={data?._idj7y} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                              {shop_idErr && (
                                <Typography className={classes.errorMsg}>
                                  {shop_idErr}
                                </Typography>
                              )}
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <label>Document</label>
                            <input
                              style={{
                                border: file_linkErr && "1px solid #d32f2f",
                              }}
                              type="file"
                              name="file_link"
                              onChange={(e) => onChangeFile(e)}
                              className="form-control"
                            />
                            {file_linkErr && (
                              <Typography className={classes.errorMsg}>
                                {file_linkErr}
                              </Typography>
                            )}
                          </Col>
                          <div className={classes.bottomFooter}>
                            <CustomButton
                              text={"Submit"}
                              onClick={onSubmit}
                              loading={loading}
                              loader={
                                <Icofont
                                  icon="spinner"
                                  style={{
                                    fontSize: "1.2rem",
                                    marginLeft: "5px",
                                  }}
                                  spin="true"
                                />
                              }
                              style={
                                loading ||
                                document_nameErr ||
                                document_typeErr ||
                                document_type_idErr ||
                                customer_idErr ||
                                vendor_idErr ||
                                bank_idErr ||
                                shop_idErr ||
                                file_linkErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                            />
                          </div>
                        </Row>
                      </div>
                      {/* <div className="box-body no-padding">
                        {loading ? (
                          <Loader />
                        ) : allDocuments?.length > 0 ? (
                          <table className="table">
                            <tbody>
                              <tr>
                                <th style={{ width: "10px" }}>#</th>
                                <th>Name</th>
                                <th className="text-left">Type</th>
                                <th className="text-left">File Link</th>
                                {/* <th style={{ width: "200px" }}>Action</th> *
                              </tr>
                              {allDocuments
                                ?.filter(function (document) {
                                  return (
                                    document.documentable_type ===
                                    JSON.stringify("App\\Models\\Vendor")
                                  );
                                })
                                .map((data, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data?.name}</td>
                                    <td className="text-left">
                                      {data?.documentable_type}
                                    </td>
                                    <td className="text-left">
                                      <a href={data?.file_link} target="_blank">
                                        Link
                                      </a>
                                    </td>
                                    {/* <td>
                                      <FaEdit
                                        // onClick={() => toggleEditDocumentForm(data)}
                                        className="s-24 m-1"
                                        style={{
                                          color: "#000",
                                          cursor: "pointer",
                                        }}
                                      />
                                      |
                                      <AiFillDelete
                                        // onClick={() =>
                                        //   openDeleteDocumentModal(data)
                                        // }
                                        className="s-24 m-1"
                                        style={{
                                          color: "#000",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </td> *
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <h2>No Data</h2>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CostDocument.propTypes = {
  documentRegistration: PropTypes.func.isRequired,
  getVendor: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vendor: state.vendor,
  document: state.document,
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getVendor,
  getDocuments,
  documentRegistration,
  getShops,
})(CostDocument);
