import { makeStyles } from "@mui/styles";
import { Grid, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Icofont from "react-icofont";
import { useNavigate } from "react-router-dom";
import InputField from "../global/InputField";
import CustomButton from "../global/Button";
import { forgetPassword } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  customBtn: {
    background:
      "linear-gradient(90deg,#212529 0,rgba(52,58,64,.7215686274509804))!important",
    padding: "12px 20px !important",
    fontSize: "1rem !important",
    borderRadius: "4px !important",
    transition: " .5s !important",
    color: "#fff !important",
    border: "0 !important",
    fontWeight: "600!important",
    lineHeight: "1.25 !important",
    textTransform: "none !important",
    letterSpacing: "3px",
  },
  gridContainer: {
    backgroundColor: " #fff !important",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },

  form: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff !important",

    "@media (max-width: 600px)": {
      height: "65vh",
    },
  },
  boxForm: {
    width: "60%",
    boxShadow: "0 1px 15px 1px rgb(52 40 104 / 8%)",
    padding: "3rem",
    "@media (max-width: 768px)": {
      width: "100%",
      boxShadow: "none",
    },
  },

  formHeading: {
    fontWeight: "500 !important",
    fontSize: "1.8rem !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginBottom: "10px !important",
    color: " #2c304d !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  formSubtitle: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    color: "var(--text-color) !important",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  inputDiv: {
    marginBottom: "40px",
  },
  inputDivBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },

  sideBox: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0098da!important",
    "@media (max-width: 600px)": {
      height: "fit-content",
      padding: "60px",
    },
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const ForgetPassword = ({ forgetPassword }) => {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    emailErr: "",
  });

  const { email } = values;
  const { emailErr } = errors;

  const validate = () => {
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }

    if (!email) {
      return false;
    }
    return true;
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setValues((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setValues((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const data = await forgetPassword(email, navigateTo);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={4}>
          <Box className={classes.sideBox}>
            {/* <Box component="img" src={Logo} style={{ width: "140px" }} /> */}
          </Box>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            className={classes.form}
          >
            <Box className={classes.boxForm}>
              <Typography variant="h5" className={classes.formHeading}>
                Forget Password
              </Typography>
              <div className={classes.inputDiv}>
                <InputField
                  label={"Email"}
                  type={"text"}
                  value={values.email}
                  onChange={(e) => handleChangeEmail(e)}
                />
                {emailErr ? (
                  <Typography className={classes.errorMsg}>
                    {emailErr}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.inputDivBtn}>
                <CustomButton
                  text={"Submit"}
                  onClick={(e) => onSubmit(e)}
                  type={"submit"}
                  loading={loading}
                  disable={emailErr ? true : ""}
                  loader={
                    <Icofont
                      icon="spinner"
                      style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                      spin="true"
                    />
                  }
                />
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ForgetPassword.propTypes = {
  forgetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  forgetPassword,
})(ForgetPassword);
