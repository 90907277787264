import React, { useEffect, useState } from "react";
import { invoiceUpdate, getInvoiceDetails } from "../../actions/invoice";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";

const EditInvoice = ({ invoiceUpdate }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    date:"",
    description: "",
   debit:"",
   credit:"",
   balance:""
  });
  const [errors, setErrors] = useState({
    dateErr: "",
    descriptionErr: "",
    debitErr: "",
    creditErr: "",
    balanceErr: "",
  });

  const {
    dateErr,
    descriptionErr,
    debitErr,
    creditErr,
    balanceErr,
  } = errors;
  const {
    date,
    description,
    debit,
    credit,
    balance,
  } = data;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/invoice/${params.id}`);
      console.log(result);
      setData({
        ...data,
        date: result?.data?.data?.date,
        description: result?.data?.data?.description,
        debit: result?.data?.data?.debit,
        credit: result?.data?.data?.credit,
        balance: result?.data?.data?.balance,
     
      });
      setLoading(false);
    };
    fetchData();
  }, [params.id]);
console.log(data?.debit)
  const disabledStyle = () => {
    if (
      loading ||
      dateErr ||
      descriptionErr ||
      debitErr ||
      creditErr ||
      balanceErr
   
    ) {
      return { pointerEvents: "none" };
    }
    return null;
  };
  const validate = () => {
    const requiredFields = [
      "date",
      "description",
      "debit",
      "credit",
      "balance",
    
    ];

    let isValid = true;

    requiredFields.forEach((field) => {
      if (![field]) {
        setErrors((prev) => ({
          ...prev,
          [`${field}Err`]: `Please provide ${field}`,
        }));
        isValid = false;
      }
    });

    return isValid;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const response = await invoiceUpdate(params?.id, data);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-invoice");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Invoice <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className="form-line">
                              <label>Date</label>
                                <input
                                  type="date"
                                  name="date"
                                  value={data?.date}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      dateErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Date"
                                />
                              </div>
                              {dateErr && (
                                <p className="text-danger">{dateErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                              <label>Description</label>
                                <input
                                  type="text"
                                  name="description"
                                  value={data?.description}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      descriptionErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Description"
                                />
                              </div>
                              {descriptionErr && (
                                <p className="text-danger">{descriptionErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                              <label>Debit</label>
                                <input
                                  type="text"
                                  name="debit"
                                  value={data?.debit}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      debitErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Debit"
                                />
                              </div>
                              {debitErr && (
                                <p className="text-danger">{debitErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <label>Credit</label>
                                <input
                                  type="text"
                                  name="credit"
                                  value={data?.credit}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      creditErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Credit"
                                />
                              </div>
                              {creditErr && (
                                <p className="text-danger">{creditErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                              <label>Balance</label>
                                <input
                                  type="text"
                                  name="balance"
                                  value={data?.balance}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      balanceErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Balance"
                                />
                              </div>
                              {balanceErr && (
                                <p className="text-danger">{balanceErr}</p>
                              )}
                            </div>


                            <button
                              onClick={onSubmit}
                              className="btn btn-primary mt-4"
                              style={disabledStyle()}
                            >
                              <i className="icon-arrow_forward mr-2"></i>Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditInvoice.propTypes = {
  invoiceUpdate: PropTypes.func.isRequired,
  getInvoiceDetails: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

export default connect(mapStateToProps, {
  invoiceUpdate,
  getInvoiceDetails,
})(EditInvoice);
