import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shopUpdate, getShops } from "../../actions/shops";
import { shopsRegistration } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";
import { useNavigate, useParams } from "react-router-dom";
import { getVendor } from "../../actions/vendor";
import { getBanks } from "../../actions/bank";
import { getCustomer } from "../../actions/customer";

import { LIVE_URl } from "../../actions/types";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { getEmails } from "../../actions/emails";
import CustomButton from "../../components/global/Button";
import { documentRegistration } from "../../actions/document";
import Icofont from "react-icofont";
import axios from "axios";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    textAlign: "left",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "left",
    margin: "10px 0",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});
const EmailView = ({

  documentRegistration,
  getShops,
  getVendor,
  getBanks,
  getCustomer,
  getEmails,
  getBusinesses,
  vendor: { allVendors },
  bank: { allBanks },
  customer: { allCustomers },
  shops: { allShops },
  emails: { allEmails },
  business: { allBusiness },
}) => {

  const navigateTo = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [data, setData] = useState({
    document_name: "",
    document_type: "",
    document_type_id: "",
    customer_id: "",
    vendor_id: "",
    bank_id: "",
    shop_id: "",
    bank_id: "",
    dynamicFileLink: "",
    vendor: ""
  });
  const [loading, setLoading] = useState(false);
  const [showDeleteShopModal, setDeleteShopModal] = useState(false);
  const [emailData, setEmailData] = useState("");

  const [errors, setErrors] = useState({
    subjectErr: "",
    senderErr: "",
    date_receivedErr: "",
    contentErr: "",
    shop_idErr: "",
    document_nameErr: "",
    document_typeErr: "",
    document_type_idErr: "",
    customer_idErr: "",
    vendor_idErr: "",
    bank_idErr: "",

  });

  const {
    subjectErr,
    senderErr,
    date_receivedErr,
    contentErr,
    shop_idErr,
    document_nameErr,
    document_typeErr,
    document_type_idErr,
    customer_idErr,
    vendor_idErr,
    bank_idErr,

  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };
  const {
    vendor,
    document_name,
    document_type,
    document_type_id,
    vendor_id,
    customer_id,
    shop_id,
    bank_id
  } = data;
  const { email_attachments } = data;
  // const{decoded_document}=data
  const [responseData, setResponseData] = useState([]);
  const [decodedDocument, setDecodedDocument] = useState(JSON.parse(localStorage.getItem("decoded_document")));
  const [response, setResponse] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [fileLink, setFileLink] = useState(""); // Initialize with an empty string

  // useEffect(() => {
  //   localStorage.setItem("decoded_document", JSON.stringify(response?.data?.data?.decoded_document|| []));
  // }, [response?.data?.data?.decoded_document]);

  useEffect(() => {
    getEmails(loadingFunc);
  }, [getEmails]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/email-pipe/${params.id}`);
      setData(result?.data?.data);
      setLoading(false);
    };
    fetchData();
  }, [params.id]);


  const validate = (data) => {
    const errors = {};
    if (!data?.subject) errors.subjectErr = "Please provide  subject";
    if (!data?.sender) errors.senderErr = "Please provide sender email";
    if (!data?.date_received) errors.date_receivedErr = "Please provide date";
    if (!data?.content) errors.contentErr = "Please provide content";
    if (!document_name) {
      setErrors((prev) => ({
        ...prev,
        document_nameErr: "Please provide document name",
      }));
    }
    if (!document_type_id) {
      setErrors((prev) => ({
        ...prev,
        document_type_idErr: "Please provide Type",
      }));
    }

    if (!document_name || !document_type_id) {
      return false;
    }
    return true;
    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
    setSelectedDocumentType(e.target.value);
  };

  // const file_link = "https://pharmacy.webspider.pk/public/lexon_invoice.pdf";
  // const file_link = "http:\/\/pharmacy.webspider.pk\/public\/attachment\/username\/August\/2023-08-01\/1\/64c95834c13c6.pdf";
  const onSubmit = async (e, attachment) => {
    console.log(attachment, "hi")
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      try {
        console.log("click here")
        let dataForm = new FormData();
        dataForm.append("document_name", document_name);
        let dynamicFileLink = "";

        // ...

        dataForm.append("file_link", attachment.file_path);
        dataForm.append("document_type", document_type);
        dataForm.append("document_type_id", document_type_id);
        dataForm.append("customer_id", customer_id);
        dataForm.append("vendor_id", vendor_id);
        dataForm.append("bank_id", bank_id);
        dataForm.append("shop_id", shop_id);
        // dataForm.append("file_link", file_link);
        // if (dynamicFileLink !== undefined) {
        //   dataForm.append("file_link", dynamicFileLink)
        // } 
        console.log(dataForm, "click")
        axios.post("https://pharmacy.webspider.pk/api/v1/document", dataForm)
          .then((response) => {

            console.log("responsevresponse", response);
            setResponseData(response.data); // Set the response data to the state
            // console.log(response?.data?.data?.decoded_document)
            localStorage.setItem("decoded_document", JSON.stringify(response?.data?.data || []));
            // navigateTo(`/dashboard/converted/${response?.data?.data?._id}`);
            if (document_type === "Vendor") {
              navigateTo(`/dashboard/converted/${response?.data?.data?._id}`);
            } else if (document_type === "Bank") {
              navigateTo(`/dashboard/statementconverted/${response?.data?.data?._id}`);
            } else if (document_type === "Buyer") {
              navigateTo(`/dashboard/buyerconverted/${response?.data?.data?._id}`);
            }
            setLoading(false);
            // console.log(response);

          }).catch(error => {
            console.error('Error:', error);
            toast.error(error);

          })


      } catch (err) {
        setLoading(false);



      }
    }
  };

  useEffect(() => {
    getBusinesses(loadingFunc);
    getVendor(loadingFunc);
    getBanks(loadingFunc);
    getCustomer(loadingFunc);


    getShops(loadingFunc);
  }, [getBusinesses, getShops, getVendor, getBanks, getCustomer]);




  const openDeleteShopModal = (data) => {
    setEmailData(data);
    setDeleteShopModal(true);
  };
  const closeDeleteShopModal = () => setDeleteShopModal(false);
  const handleChangeShopEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        Date_Err: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, sender: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, sender: e.target.value }));
      setErrors((prev) => ({ ...prev, Date_Err: "" }));
    }
  };



  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Email View <span className="s-14">Registration Number</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">


                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="sender"
                                value={data?.sender}
                                onChange={(e) => handleChangeShopEmail(e)}
                                className="form-control"
                                placeholder="Sender"
                                required="required"
                              />
                            </div>
                            {senderErr && (
                              <p className="text-danger">{senderErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="subject"
                                value={data?.subject}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    subjectErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Subject"
                              />
                            </div>
                            {subjectErr && (
                              <p className="text-danger">{subjectErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="date"
                                value={data?.date_received}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    date_receivedErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Date"
                                required="required"
                              />
                            </div>
                            {date_receivedErr && (
                              <p className="text-danger">{date_receivedErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <div className="select">
                                <select
                                  name="shop_id"

                                  className="custom-select form-control"
                                  value={shop_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      shop_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" >
                                    Select Shop
                                  </option>
                                  {allShops?.map((data, i) => (
                                    <option value={data?._id} key={i}>
                                      {data?.name}
                                    </option>
                                  ))}
                                </select>

                              </div>

                            </div>
                            {shop_idErr && (
                              <Typography className={classes.errorMsg}>
                                {shop_idErr}
                              </Typography>
                            )}
                          </div>








                          {/*  */}
                          <div className="form-group">
                            <div className="form-line">
                              <textarea
                                type="text"
                                name="content"
                                value={data?.content}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    contentErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Content"
                                required="required"
                              />
                            </div>
                            {contentErr && (
                              <p className="text-danger">{contentErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              {document_type}
                              <input

                                type="text"
                                name="document_name"
                                value={document_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    document_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Document name"
                              />

                            </div>
                            {document_nameErr && (
                              <Typography className={classes.errorMsg}>
                                {document_nameErr}
                              </Typography>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label> Select an Document  type</label>
                              <div className="select">

                                <select
                                  name="document_type"
                                  className="custom-select form-control"
                                  value={document_type} // Use selectedDocumentType state
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);

                                  }}                                >
                                  <option value="">Select</option>

                                  <option value="Vendor">Invoice</option>
                                  <option value="Bank">Statement</option>
                                  <option value="Buyer">Buyer Invoice</option>
                                </select>

                              </div>
                            </div>

                          </div>



                          {document_type == "Vendor" && (<div className="form-group">
                            <div className="form-line">
                              <div className="select">
                                <select
                                  name="document_type_id"

                                  className="custom-select form-control"
                                  value={document_type_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      vendor_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an vendor
                                  </option>
                                  {allVendors?.map((data, i) => (
                                    <option value={data?._id} key={i}>
                                      {data?.name}
                                    </option>
                                  ))}
                                </select>

                              </div>

                            </div>
                            {vendor_idErr && (
                              <Typography className={classes.errorMsg}>
                                {vendor_idErr}
                              </Typography>
                            )}
                          </div>)}
                          {document_type == "Bank" && (<div className="form-group">
                            <div className="form-line">
                              <div className="select">
                                <select
                                  name="document_type_id"

                                  className="custom-select form-control"
                                  value={document_type_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      vendor_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an Bank
                                  </option>
                                  {allBanks?.map((data, i) => (
                                    <option value={data?._id} key={i}>
                                      {data?.name}
                                    </option>
                                  ))}
                                </select>

                              </div>

                            </div>
                            {vendor_idErr && (
                              <Typography className={classes.errorMsg}>
                                {vendor_idErr}
                              </Typography>
                            )}
                          </div>)}
                          {document_type == "Buyer" && (<div className="form-group">
                            <div className="form-line">
                              <div className="select">
                                <select
                                  name="document_type_id"

                                  className="custom-select form-control"
                                  value={document_type_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      vendor_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an Customer
                                  </option>
                                  {allCustomers?.map((data, i) => (
                                    <option value={data?._id} key={i}>
                                      {data?.name}
                                    </option>
                                  ))}
                                </select>

                              </div>

                            </div>
                            {vendor_idErr && (
                              <Typography className={classes.errorMsg}>
                                {vendor_idErr}
                              </Typography>
                            )}
                          </div>)}
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <section >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="box">
                      <div className="box-header">
                        <h5 className="text-left">Attachments</h5>
                      </div>
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>Attachment</th>
                            <th className="text-right">Status</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {email_attachments?.map((attachment, i) => (
                            <tr key={attachment._id}>
                              <Link
                                to={attachment.file_path}
                              ><td>Document{i + 1} </td></Link>

                              <td className="text-right">
                                <i className="icon icon-remove text-danger"> </i>
                              </td>


                              <td className="text-right "
                              >
                                <button className="badge badge-success" onClick={(e) => onSubmit(e, attachment)}>
                                  processing
                                </button>
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

EmailView.propTypes = {
  documentRegistration: PropTypes.func.isRequired,
  shopsRegistration: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
  getShops: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
  emails: PropTypes.object.isRequired,
  shopUpdate: PropTypes.func.isRequired,
  getVendor: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,

  getEmails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  emails: state.emails,
  shops: state.shops,
  business: state.business,
  vendor: state.vendor,
  customer: state.customer,
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getShops,
  getVendor,
  getBanks,
  getCustomer,
  shopUpdate,
  getEmails,
  shopsRegistration,
  getBusinesses,
  documentRegistration,
})(EmailView);