import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";
import * as GrIcons from "react-icons/gr";

let staticPath = "/dashboard";

export const SidebarData = [
  {
    title: "Dashboard",
    path: `${staticPath}/stats`,
    icon: (
      <AiIcons.AiOutlineDashboard style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Businesses",
    path: `${staticPath}/businesses`,
    icon: (
      <MdIcons.MdOutlineBusinessCenter
        style={{ color: "#000", fontSize: "20px" }}
      />
    ),
  },
  {
    title: "Shops",
    path: `${staticPath}/shops`,
    icon: (
      <AiIcons.AiOutlineShoppingCart
        style={{ color: "#000", fontSize: "20px" }}
      />
    ),
  },
  {
    title: "Banks",
    path: `${staticPath}/banks`,
    icon: <AiIcons.AiOutlineBank style={{ color: "#000", fontSize: "20px" }} />,
  },
  {
    title: "Bank Requests",
    path: `${staticPath}/bank-request`,
    icon: (
      <AiIcons.AiOutlinePullRequest
        style={{ color: "#000", fontSize: "20px" }}
      />
    ),
  },
  {
    title: "Accounts",
    path: `${staticPath}/accounts`,
    icon: (
      <RiIcons.RiAccountBoxLine style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Vendor",
    path: `${staticPath}/vendor`,
    icon: <BsIcons.BsShopWindow style={{ color: "#000", fontSize: "20px" }} />,
  },
  {
    title: "Customer",
    path: `${staticPath}/customer`,
    icon: <AiIcons.AiOutlineUser style={{ color: "#000", fontSize: "20px" }} />,
  },
  {
    title: "Documents",
    // path: `${staticPath}/documents`,
    icon: <i className="icofont-file-document"></i>,
    iconClosed: (
      <RiIcons.RiArrowDownSFill style={{ color: "#000", fontSize: "20px" }} />
    ),
    iconOpened: (
      <RiIcons.RiArrowUpSFill style={{ color: "#000", fontSize: "20px" }} />
    ),

    subNav: [
      {
        title: "Bank",
        path: `${staticPath}/bankDocuments`,
        icon: (
          <AiIcons.AiOutlineBank style={{ color: "#000", fontSize: "20px" }} />
        ),
      },
      {
        title: "Sale",
        path: `${staticPath}/saleDocuments`,
        icon: (
          <HiIcons.HiOutlineShoppingBag
            style={{ color: "#000", fontSize: "20px" }}
          />
        ),
      },
      {
        title: "Cost",
        path: `${staticPath}/costDocuments`,
        icon: (
          <AiIcons.AiOutlineDollarCircle
            style={{ color: "#000", fontSize: "20px" }}
          />
        ),
      },
    ],
  },
  {
    title: "Plans",
    path: `${staticPath}/plans`,
    icon: (
      <TbIcons.TbBusinessplan style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Proceed Files",
    path: "/proceed_files",
    icon: (
      <AiIcons.AiOutlineFileSearch
        style={{ color: "#000", fontSize: "20px" }}
      />
    ),
  },
  {
    title: "Tickets",
    path: "/tickets",
    icon: (
      <HiIcons.HiOutlineTicket style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Reports",
    path: "/reports",
    icon: (
      <HiIcons.HiOutlineDocumentReport
        style={{ color: "#000", fontSize: "20px" }}
      />
    ),
  },
  {
    title: "Staff",
    path: "/staff",
    icon: (
      <HiIcons.HiOutlineUsers style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Roll Management",
    path: "/roll_management",
    icon: (
      <GrIcons.GrUserSettings style={{ color: "#000", fontSize: "20px" }} />
    ),
  },
  {
    title: "Spam Filter",
    path: "/spam_filter",
    icon: <AiIcons.AiOutlineBug style={{ color: "#000", fontSize: "20px" }} />,
  },
  {
    title: "Email Pipping",
    path: "/email_pipping",
    icon: <AiIcons.AiOutlineMail style={{ color: "#000", fontSize: "20px" }} />,
  },
];
