import React, { useEffect, useState } from "react";
import { getAccounts, deleteAccount } from "../../actions/account";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Loader from "../../components/global/Loader";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AllAccounts = ({
  account: { allAccounts },
  getAccounts,
  deleteAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [accountData, setAccountData] = useState("");
  const [amount, setAmount] = useState("");
  const [valid, setValid] = useState(true);
  const handleInputChange = (e) => {
    const value = e.target.value;
    const currencyRegex = /^\d+(\.\d{1,2})?$/; // Regular expression for currency format

    if (currencyRegex.test(value) || value === "") {
      setAmount(value);
      setValid(true);
    } else {
      setValid(false);
    }
  };
  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getAccounts(loadingFunc);
  }, [getAccounts]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Account MODAL

  const openDeleteAccountModal = (data) => {
    setAccountData(data);
    setDeleteAccountModal(true);
  };

  const closeDeleteAccountModal = () => setDeleteAccountModal(false);

  const deleteAccountFunc = async (e) => {
    e.preventDefault();
    let res = await deleteAccount(accountData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteAccountModal();
      setAccountData("");
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeleteAccountModal}
        onHide={closeDeleteAccountModal}
        title="Delete Accounts"
        bodyText="Are you sure, you want to delete the account?"
        onClickNo={closeDeleteAccountModal}
        onClickYes={(e) => deleteAccountFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Accounts <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {
                      hasPermission('add-account') &&
                    <Link
                      to="/dashboard/add-account"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Bank Account
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allAccounts?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Currency</th>
                            <th className="text-left">Number</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allAccounts?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">{data?.currency}</td>
                              <td className="text-left">{data?.number}</td>
                              <td className="text-right">
                                {/* <a href="bank_add_account_bussiness.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
                                | */}
                                {
                                  hasPermission('edit-account') &&
                                  <Link
                                    to={`/dashboard/edit-account/${data?._id}`}
                                  >
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </Link>
                                }

                                {
                                  hasPermission('delete-account') &&
                                  <i
                                    className="s-24 icon-trash-can"
                                    onClick={() => openDeleteAccountModal(data)}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
AllAccounts.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  getAccounts,
  deleteAccount,
})(AllAccounts);
