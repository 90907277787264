import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteCustomer } from "../../actions/customer";
import { getCustomer } from "../../actions/customer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddVendor from "../../components/forms/AddVendor";
import EditVendor from "../../components/forms/EditVendor";
import AddCustomer from "../../components/forms/AddCustomer";
import EditCustomer from "../../components/forms/EditCustomer";
import hasPermission from "../../utils/permisssions";
const Customer = ({
  customer: { allCustomers },
  toggleNavbar,
  getCustomer,
  deleteCustomer,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteCustomerModal, setDeleteCustomerModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [customerData, setCustomerData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddCustomerForm = () => {
    setToggleForm(true);
  };

  const closeAddCustomerForm = () => {
    setToggleForm(false);
  };

  const toggleEditCustomerForm = (data) => {
    setCustomerData(data);
    setToggleEditForm(true);
  };

  const closeEditCustomerForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getCustomer(loadingFunc);
  }, [getCustomer]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Customer MODAL

  const openDeleteCustomerModal = (data) => {
    setCustomerData(data);
    setDeleteCustomerModal(true);
  };

  const closeDeleteCustomerModal = () => setDeleteCustomerModal(false);

  const deleteCustomerFunc = async (e) => {
    e.preventDefault();
    let res = await deleteCustomer(customerData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteCustomerModal();
      setCustomerData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteCustomerModal}
        onHide={closeDeleteCustomerModal}
        title="Delete Customer"
        bodyText="Are you sure, you want to delete the customer?"
        onClickNo={closeDeleteCustomerModal}
        onClickYes={(e) => deleteCustomerFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Customer Listing
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                       {
                            hasPermission('add-customer') &&
                      <a
                        onClick={(e) => toggleAddCustomerForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add Customer
                      </a>
}
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allCustomers?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Email</th>
                              <th className="text-left">Phone No</th>
                              <th className="text-left">City</th>
                              <th
                                style={{ width: "160px", textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                            {allCustomers?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">
                                  {data?.customer_email}
                                </td>
                                <td className="text-left">
                                  {data?.customer_phone}
                                </td>
                                <td className="text-left">{data?.city}</td>
                                <td className="text-center">
                                  {/* <FaEye
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  | */}
                               {
                            hasPermission('edit-customer') &&   
                                  <FaEdit
                                    onClick={() => toggleEditCustomerForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                               }
                                  |
                                  {
                            hasPermission('delete-customer') &&
                                  <AiFillDelete
                                    onClick={() =>
                                      openDeleteCustomerModal(data)
                                    }
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* ADD Customer MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddCustomerForm}
        bodyContent={
          <AddCustomer closeAddCustomerForm={closeAddCustomerForm} />
        }
        title="Add Customer"
      />

      {/* EDIT Customer MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditCustomerForm}
        bodyContent={
          <EditCustomer
            closeEditCustomerForm={closeEditCustomerForm}
            customerData={customerData}
          />
        }
        title="Edit Customer"
      />
    </>
  );
};

Customer.propTypes = {
  getCustomer: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps, {
  getCustomer,
  deleteCustomer,
})(Customer);
