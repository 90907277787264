import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDocumentDetails } from "../../actions/document";
import EditableTable from "../../components/global/EditableTable";

const data = [
  {
    employeeId: "01",
    name: "John Doe",
    email: "johndoe@email.com",
    position: "Frontend Developer",
  },
  {
    employeeId: "02",
    name: "Sara",
    email: "sara@email.com",
    position: "HR Executive",
  },
  {
    employeeId: "03",
    name: "Mike",
    email: "mike@email.com",
    position: "Backend Developer",
  },
];

const BankDocumentDetail = ({ toggleNavbar, getDocumentDetails }) => {
  const params = useParams();
  const [employeeData, setEmployeeData] = useState(data);

  useEffect(() => {
    getDocumentDetails(params?.id);
  }, [params?.id]);

  const onChangeInput = (e, employeeId) => {
    const { name, value } = e.target;

    const editData = employeeData.map((item) =>
      item.employeeId === employeeId && name ? { ...item, [name]: value } : item
    );

    setEmployeeData(editData);
  };
  return (
    <>
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <div className="page ">
          <header className="my-3">
            <div className="container-fluid">
              {/* <div className="row">
                <div className="col-md-5">
                  <object
                    data="http://pharmacy.webspider.pk/public/uploads/document/1678214420543158320.pdf"
                    type="application/pdf"
                    width="500"
                    height="600"
                  >
                    <p>
                      Alternative text - include a link{" "}
                      <a href="http://pharmacy.webspider.pk/public/uploads/document/1678214420543158320.pdf">
                        to the PDF!
                      </a>
                    </p>
                  </object>
                </div>
                <div className="col-md-7">
                  <EditableTable data={data} />
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6 mh-100">
                  <div className="card">
                    <div className="card-body b-b">
                      <object
                        data="http://pharmacy.webspider.pk/public/uploads/document/1678214420543158320.pdf"
                        type="application/pdf"
                        width="100%"
                        height="650px"
                      >
                        alt :{" "}
                        <a href="http://pharmacy.webspider.pk/public/uploads/document/1678214420543158320.pdf">
                          test.pdf
                        </a>
                      </object>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body b-b">
                      <form className="form-material">
                        <div className="body">
                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <div className="box-body no-padding table-editable">
                                <label for="">Editable Table Here</label>
                                <table className="table table-striped">
                                  <tbody>
                                    <tr>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Position</th>
                                    </tr>
                                    {employeeData.map(
                                      ({
                                        employeeId,
                                        name,
                                        email,
                                        position,
                                      }) => (
                                        <tr key={employeeId}>
                                          <td>
                                            <input
                                              name="name"
                                              value={name}
                                              type="text"
                                              onChange={(e) =>
                                                onChangeInput(e, employeeId)
                                              }
                                              placeholder="Type Name"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="email"
                                              value={email}
                                              type="text"
                                              onChange={(e) =>
                                                onChangeInput(e, employeeId)
                                              }
                                              placeholder="Type Email"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="position"
                                              type="text"
                                              value={position}
                                              onChange={(e) =>
                                                onChangeInput(e, employeeId)
                                              }
                                              placeholder="Type Position"
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <a
                                // href="proceed-files.html"
                                className="btn btn-primary mt-2"
                              >
                                <i className="icon-arrow_forward mr-2"></i> Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>{" "}
      </div>
    </>
  );
};

BankDocumentDetail.propTypes = {
  getDocumentDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  document: state.document,
});

export default connect(mapStateToProps, {
  getDocumentDetails,
})(BankDocumentDetail);
