// import { GET_POP_SETTING } from "../actions/types";

// const initialState = {
 
//   popDetails:null,
// };

// export default (state = initialState, { type, payload }) => {
//   // const { type, payload } = action;

//   switch (type) {

//     case GET_POP_SETTING:
//       return {
//         ...state,
//         popDetails: payload,
//       };
//     default:
//       return state;
//   }
// }

import {GET_POP_SETTING, GET_POP_DETAILS } from "../actions/types";

const initialState = {
  allPops: null,
  popDetails: null,
};

export default function pop(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POP_SETTING:
      return {
        ...state,
        allPops: payload,
      };
    case GET_POP_DETAILS:
      return {
        ...state,
        popDetails: payload,
      };
    default:
      return state;
  }
}
