import React, { useEffect, useState } from "react";
import { deleteBank } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Loader from "../../components/global/Loader";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import { updateBankRequest, getBankRequests } from "../../actions/bank";
import hasPermission from "../../utils/permisssions";
const AllBankRequests = ({ bank: { allBankRequests }, updateBankRequest, getBankRequests}) => {
  const [loading, setLoading] = useState(false);
    const [bankRequestData, setBankRequestData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getBankRequests(loadingFunc);
  }, [getBankRequests]);
console.log(allBankRequests)
const handleUpdateRequest = async (bankRequestId, newData) => {
  setLoading(true);
  try {
    await updateBankRequest(bankRequestId, newData);
    // Update the allBankRequests state by fetching the updated data
    await getBankRequests(loadingFunc);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    // Handle error
  }
};
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Bank  Name <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {
                      hasPermission('add-bank-request') &&
                    <Link
                      to="/dashboard/add-bank-request"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add Bank Request
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allBankRequests?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Bank Name</th>
                            <th className="text-left">Status</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allBankRequests?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.bank_name}</td>
                              <td className="text-left">{data?.status}</td>
                              <td className="text-right">
                                {/* <a href="bank_add_account_bussiness.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
                                | */}
                                   {
                                  hasPermission('edit-bank-request') &&
                                <Link
                                  to={`/dashboard/edit-bank-request/${data?._id}`}
                                >
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllBankRequests.propTypes = {
  getBankRequests: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getBankRequests,
  deleteBank,
})(AllBankRequests);
