import React, { useEffect, useState } from "react";
import { getCustomer } from "../../actions/customer";
import { getDocuments } from "../../actions/document";
import { documentRegistration } from "../../actions/document";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    textAlign: "left",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "left",
    margin: "10px 0",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const FileConvertSale = ({
  customer: { allCustomers },
  getCustomer,
  documentRegistration,
  document: { allDocuments },
  getDocuments,
  shops: { allShops },
  getShops,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    document_name: "",
    document_type: "",
    document_type_id: "",
    customer_id: "",
    vendor_id: "",
    bank_id: "",
    shop_id: "",
    file_link: "",
  });
  const [errors, setErrors] = useState({
    document_nameErr: "",
    document_typeErr: "",
    document_type_idErr: "",
    customer_idErr: "",
    vendor_idErr: "",
    bank_idErr: "",
    shop_idErr: "",
    file_linkErr: "",
  });

  const {
    document_name,
    document_type,
    document_type_id,
    customer_id,
    vendor_id,
    bank_id,
    shop_id,
    file_link,
  } = data;

  const {
    document_nameErr,
    document_typeErr,
    document_type_idErr,
    customer_idErr,
    vendor_idErr,
    bank_idErr,
    shop_idErr,
    file_linkErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getCustomer(loadingFunc);
    getDocuments(loadingFunc);
    getShops(loadingFunc);
  }, [getCustomer, getDocuments, getShops]);

  const validate = () => {
    if (!document_name) {
      setErrors((prev) => ({
        ...prev,
        document_nameErr: "Please provide document name",
      }));
    }
    if (!customer_id) {
      setErrors((prev) => ({
        ...prev,
        customer_idErr: "Please provide customer",
      }));
    }
    if (!file_link) {
      setErrors((prev) => ({
        ...prev,
        file_linkErr: "Please select file link",
      }));
    }

    if (!document_name || !customer_id || !file_link) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const FileLink = ({ url, filename }) => {
    const isPDF = filename.toLowerCase().endsWith(".pdf");
  
    return (
      <a href={isPDF ? url : null} target="_blank" rel="noopener noreferrer">
        {filename}
      </a>
    );
  };
  

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      file_linkErr: "",
    }));
    setData({ ...data, file_link: file });
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setError("Please select a PDF file.");
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let dataForm = new FormData();
        dataForm.append("document_name", document_name);
        dataForm.append(
          "document_type",
          JSON.stringify("App\\Models\\Customer")
        );
        dataForm.append("document_type_id", customer_id);
        dataForm.append("customer_id", customer_id);
        dataForm.append("vendor_id", "0");
        dataForm.append("bank_id", "0");
        dataForm.append("shop_id", shop_id);
        dataForm.append("file_link", file_link);
        let response = await documentRegistration(dataForm, loadingFunc);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                File Conversion<span className="s-14">Bank </span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                id="inputState"
                                className="form-control"
                                placeholder="Country"
                              >
                                <option>Select Account Number</option>
                                <option>GB24BKEN10000031510604</option>
                                <option>GB27BOFI90212729823529</option>
                                <option>GB17BOFS80055100813796</option>
                              </select>
                            </div>
                            <small>
                              {" "}
                              <a href="#"> add new Account</a>{" "}
                            </small>
                          </div>
                          <div className="col-sm">
                          <div >
                            <div className="form-line focused">
                              <input
                                type="file"
                                name="file_link"
                                accept=".pdf"
                                onChange={handleFileChange} 
                                className="form-control"
                              />
                            </div>
                            {file_linkErr && (
                              <p className="text-danger">{file_linkErr}</p>
                            )}
                          </div>
                            <small> upload only .pdf file </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-6 mh-100">
              <div className="card">
                <div className="card-body b-b">
                  <object
                    data="http://pharmacy.webspider.pk/public/uploads/document/1678211272150429916.pdf"
                    type="application/pdf"
                    width="100%"
                    height="650px"
                  >
                    alt :{" "}
                    <a href="http://pharmacy.webspider.pk/public/uploads/document/1678211272150429916.pdf">
                      test.pdf
                    </a>
                  </object>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="box-body no-padding table-editable">
                            <label for="">Editable Table Here</label>
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th>Date</th>
                                  <th>Description</th>
                                  <th>Debit</th>
                                  <td>Credit</td>
                                  <td>Balance</td>
                                </tr>
                                <tr>
                                  <td>15/10/2020</td>
                                  <td>BP GBCLONLTD construction</td>
                                  <td></td>
                                  <td>9000.00</td>
                                  <td>9000.57</td>
                                </tr>

                                <tr>
                                  <td>15/10/2020</td>
                                  <td>BP C Florea For help</td>
                                  <td>1225</td>
                                  <td></td>
                                  <td>7775.57</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <a
                            href="procede-files.html"
                            className="btn btn-primary mt-2"
                          >
                            <i className="icon-arrow_forward mr-2"></i> Submit{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
FileConvertSale.propTypes = {
  documentRegistration: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  document: state.document,
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getCustomer,
  getDocuments,
  documentRegistration,
  getShops,
})(FileConvertSale);
