import { LIVE_URl, GET_STATEMENT, GET_STATEMENT_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL Plan

export const getStatement = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/statement`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_STATEMENT,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// Plan REGISTRATION

export const statementRegistration =
  (statementDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = statementDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/statement`, body, config);

      if (res) {
        toast.success("Statement Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const statementUpdate = (statementId, statementDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = statementDetails;
  try {
    const res = await axios.put(`${LIVE_URl}/statement/${statementId}`, body, config);

    if (res) {
      toast.success("Statement Updated Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const getStatementDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/statement/${id}`);
    if (res) {
      dispatch({
        type: GET_STATEMENT_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// DELETE Plan

export const deleteStatement = (statementId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/statement/${statementId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getStatement(deleteLoaderFunc));
      toast.success("Statement Deleted Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};
