import { LOGIN_SUCCESS, AUTH_ERROR, LOG_OUT, LIVE_URl } from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { toast } from "react-toastify";
import hasPermission from "../utils/permisssions";

// Login User

export const userAuthentication =
  (authData, navigateTo) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const res = await axios.post(`${LIVE_URl}/login`, authData, config);

      if (res?.status === 200) {
        toast.success(res?.data?.message);
      }
      if (res?.data?.data?.token) {
        sessionStorage.setItem("id", res?.data?.data?.user?.id);
        setAuthToken(res?.data?.data?.token);
        sessionStorage.setItem("token", res?.data?.data?.token);
        sessionStorage.setItem("user_data", JSON.stringify(res?.data?.data));
        sessionStorage.setItem("user_type", (res?.data?.data?.user_type));

        
        sessionStorage.setItem("permissions", res?.data?.data?.user?.permissions);
        sessionStorage.setItem("permissions_via_roles", res?.data?.data?.user?.permissions_via_roles);
        console.log("Check Permission",hasPermission('add-user'))

        dispatch({
          type: LOGIN_SUCCESS,
          payload: res?.data?.data,
        });
        navigateTo("/dashboard");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// FORGET PASSWORD

export const forgetPassword = (email, navigate) => async (dispatch) => {
  try {
    let data = new FormData();
    data.append("email", email);
    const res = await axios.post(`${LIVE_URl}/password/forget`, data);
    console.log("res", res);
    if (res?.status === 200) {
      toast.success(res?.data?.detail);
      navigate("/");
    }
    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const logout = (navigateTo) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
  };
  try {
    const res = await axios.get(`${LIVE_URl}/logout`, config);
    if (res) {
      sessionStorage.clear();
      navigateTo("/");
    }
    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
