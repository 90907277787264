import React, { useState } from "react";
import { businessRegistration } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AddBusiness = ({ businessRegistration }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    business_name: "",
    lisence_number: "",
    contact_number: "",
    business_email: "",
    business_country: "",
    business_city: "",
    post_code: "",
    valid_till: "",
    alert_before: "",
    about: "",
    business_image: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: "",
    city: "",
    country: "",
    owner_image: "",
  });
  const [errors, setErrors] = useState({
    business_nameErr: "",
    lisence_numberErr: "",
    contact_numberErr: "",
    business_emailErr: "",
    business_countryErr: "",
    business_cityErr: "",
    post_codeErr: "",
    valid_tillErr: "",
    alert_beforeErr: "",
    aboutErr: "",
    business_imageErr: "",
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    passwordErr: "",
    password_confirmErr: "",
    cityErr: "",
    countryErr: "",
    owner_imageErr: "",
  });

  const {
    business_name,
    lisence_number,
    contact_number,
    business_email,
    business_country,
    business_city,
    post_code,
    valid_till,
    alert_before,
    about,
    business_image,
    first_name,
    last_name,
    email,
    password,
    password_confirm,
    city,
    country,
    owner_image,
  } = data;

  const {
    business_nameErr,
    lisence_numberErr,
    contact_numberErr,
    business_emailErr,
    business_countryErr,
    business_cityErr,
    post_codeErr,
    valid_tillErr,
    alert_beforeErr,
    aboutErr,
    business_imageErr,
    first_nameErr,
    last_nameErr,
    emailErr,
    passwordErr,
    password_confirmErr,
    cityErr,
    countryErr,
    owner_imageErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const validate = () => {
    if (business_name === "") {
      setErrors((prev) => ({
        ...prev,
        business_nameErr: "Please provide business name",
      }));
    }
    if (!lisence_number) {
      setErrors((prev) => ({
        ...prev,
        lisence_numberErr: "Please provide license number",
      }));
    }
    if (!contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!business_email) {
      setErrors((prev) => ({
        ...prev,
        business_emailErr: "Please provide business email",
      }));
    }
    if (!business_country) {
      setErrors((prev) => ({
        ...prev,
        business_countryErr: "Please provide business country",
      }));
    }
    if (!business_city) {
      setErrors((prev) => ({
        ...prev,
        business_cityErr: "Please provide business city",
      }));
    }
    if (!post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!valid_till) {
      setErrors((prev) => ({
        ...prev,
        valid_tillErr: "Please provide valid till date",
      }));
    }
    if (!alert_before) {
      setErrors((prev) => ({
        ...prev,
        alert_beforeErr: "Please provide alert before date",
      }));
    }
    if (!about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!business_image) {
      setErrors((prev) => ({
        ...prev,
        business_imageErr: "Please provide business image",
      }));
    }
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(email)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: email ? "Invalid email address" : "Email can't be empty",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!password_confirm) {
      setErrors((prev) => ({
        ...prev,
        password_confirmErr: "Please provide confirm password",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!owner_image) {
      setErrors((prev) => ({
        ...prev,
        owner_imageErr: "Please provide owner image",
      }));
    }

    if (
      !business_name ||
      !lisence_number ||
      !contact_number ||
      !business_email ||
      !business_country ||
      !business_city ||
      !post_code ||
      !valid_till ||
      !alert_before ||
      !about ||
      !business_image ||
      !first_name ||
      !last_name ||
      !email ||
      !password ||
      !password_confirm ||
      !city ||
      !country ||
      !owner_image
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setData({ ...data, [e.target.name]: file });
  };

  const handleChangeEmailUser = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const handleChangeEmailBusiness = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        business_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, business_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, business_email: e.target.value }));
      setErrors((prev) => ({ ...prev, business_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        let response = await businessRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-businesses");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24 font-weight-bold">
                <i className="icon-pages"></i>
             Create Business
                {/* <span className="s-14">Registration Number</span> */}
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-6">
                          <h3 className="font-weight-bold">Business Details</h3>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="business_name"
                                className="form-control"
                                placeholder="Business Name"
                                value={business_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_nameErr: "",
                                  }));
                                }}
                              />
                            </div>
                            {business_nameErr && (
                              <p className="text-danger m-0">
                                {business_nameErr}
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="lisence_number"
                                className="form-control"
                                placeholder="License Number"
                                value={lisence_number}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    lisence_numberErr: "",
                                  }));
                                }}
                              />
                            </div>
                            {lisence_numberErr && (
                              <p className="text-danger">{lisence_numberErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="contact_number"
                                value={contact_number}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    contact_numberErr: "",
                                  }));
                                }}
                                placeholder="Contact Number"
                              />
                            </div>
                            {contact_numberErr && (
                              <p className="text-danger">{contact_numberErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                name="business_email"
                                value={business_email}
                                onChange={(e) => handleChangeEmailBusiness(e)}
                                placeholder="Email Address"
                              />
                            </div>
                            {business_emailErr && (
                              <p className="text-danger">{business_emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="business_country"
                                value={business_country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_countryErr: "",
                                  }));
                                }}
                                placeholder="Country"
                              />
                            </div>
                            {business_countryErr && (
                              <p className="text-danger">
                                {business_countryErr}
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="business_city"
                                value={business_city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_cityErr: "",
                                  }));
                                }}
                                placeholder="City"
                              />
                            </div>
                            {business_cityErr && (
                              <p className="text-danger">{business_cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="post_code"
                                value={post_code}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    post_codeErr: "",
                                  }));
                                }}
                                placeholder="Post Code"
                              />
                            </div>
                            {post_codeErr && (
                              <p className="text-danger">{post_codeErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Validate Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="valid_till"
                                value={valid_till}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    valid_tillErr: "",
                                  }));
                                }}
                                placeholder="Validate Date"
                              />
                            </div>
                            {valid_tillErr && (
                              <p className="text-danger">{valid_tillErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Alert Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="alert_before"
                                value={alert_before}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    alert_beforeErr: "",
                                  }));
                                }}
                                placeholder="Alert Date"
                              />
                            </div>
                            {alert_beforeErr && (
                              <p className="text-danger">{alert_beforeErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <textarea
                                type="text"
                                className="form-control"
                                name="about"
                                value={about}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    aboutErr: "",
                                  }));
                                }}
                                placeholder="About"
                              />
                            </div>
                            {aboutErr && (
                              <p className="text-danger">{aboutErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Business Image</label>
                              <input
                                type="file"
                                className="form-control"
                                name="business_image"
                                onChange={(e) => {
                                  onChangeFile(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_imageErr: "",
                                  }));
                                }}
                                placeholder="Business Image"
                              />
                            </div>
                            {business_imageErr && (
                              <p className="text-danger">{business_imageErr}</p>
                            )}
                          </div>
                          {
                            hasPermission('add-business') &&
                          <button
                            style={
                              loading ||
                              business_nameErr ||
                              lisence_numberErr ||
                              contact_numberErr ||
                              business_emailErr ||
                              business_countryErr ||
                              business_cityErr ||
                              post_codeErr ||
                              valid_tillErr ||
                              alert_beforeErr ||
                              aboutErr ||
                              business_imageErr ||
                              first_nameErr ||
                              last_nameErr ||
                              emailErr ||
                              passwordErr ||
                              password_confirmErr ||
                              cityErr ||
                              countryErr ||
                              owner_imageErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                            onClick={onSubmit}
                            className="btn btn-primary mt-2"
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                          }
                        </div>
                        <div className="col-sm-6">
                        <h3 className="font-weight-bold">Business Owner Details</h3>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="first_name"
                                className="form-control"
                                placeholder="First Name"
                                value={first_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    first_nameErr: "",
                                  }));
                                }}
                              />
                            </div>
                            {first_nameErr && (
                              <p className="text-danger m-0">{first_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="last_name"
                                className="form-control"
                                placeholder="Last Name"
                                value={last_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    last_nameErr: "",
                                  }));
                                }}
                              />
                            </div>
                            {last_nameErr && (
                              <p className="text-danger">{last_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                value={country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    countryErr: "",
                                  }));
                                }}
                                placeholder="Country"
                              />
                            </div>
                            {countryErr && (
                              <p className="text-danger">{countryErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    cityErr: "",
                                  }));
                                }}
                                placeholder="City"
                              />
                            </div>
                            {cityErr && (
                              <p className="text-danger">{cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => handleChangeEmailUser(e)}
                                placeholder="Email"
                              />
                            </div>
                            {emailErr && (
                              <p className="text-danger">{emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                autoComplete="off"
                                value={password}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                  }));
                                  if (Number(e.target.value.length) < 8) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr:
                                        "Password must be atleast 8 characters",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr: "",
                                    }));
                                  }
                                }}
                                placeholder="Password"
                              />
                            </div>
                            {passwordErr && (
                              <p className="text-danger">{passwordErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="password"
                                name="password_confirm"
                                value={password_confirm}
                                onChange={(e) => {
                                  if (password && e.target.value !== password) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      password_confirmErr:
                                        "Password and Confirm Password does not match.",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      password_confirmErr: "",
                                    }));
                                  }
                                  setData({
                                    ...data,
                                    password_confirm: e.target.value,
                                  });
                                }}
                                className="form-control"
                                placeholder="Confirm password"
                              />
                            </div>
                            {password_confirmErr && (
                              <p className="text-danger">
                                {password_confirmErr}
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Owner Image</label>
                              <input
                                type="file"
                                className="form-control"
                                name="owner_image"
                                onChange={(e) => {
                                  onChangeFile(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    owner_imageErr: "",
                                  }));
                                }}
                                placeholder="Owner Image"
                              />
                              {owner_imageErr && (
                                <p className="text-danger">{owner_imageErr}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddBusiness.propTypes = {
  businessRegistration: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  businessRegistration,
})(AddBusiness);
