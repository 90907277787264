import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RegistrationInvoice } from "../../actions/invoice";
import axios from "axios";
import EditableTable from "../../components/global/EditableTable";
import { useNavigate } from "react-router-dom";
import { invoiceRegistration } from "../../actions/invoice";
import { LIVE_URl } from "../../actions/types";


const BuyerConverted = ({ invoiceRegistration, response, RegistrationInvoice }) => {
  const navigate = useNavigate();
  // const decoded_document = responseData ? responseData.decoded_document : [];
  const [decodedDocument, setDecodedDocument] = useState([]);
  const [date, setDate] = useState([]);
  const [debit, setDebit] = useState([]);
  const [credit, setCredit] = useState([]);
  const [description, setDescription] = useState([]);
  const [balance, setBalance] = useState([]);
  const [document_id, setDocument_id] = useState([]);
  const [shop_id, setShop_id] = useState([]);


  useEffect(() => {
    // Retrieve the data from local storage
    const decodedDocument = localStorage.getItem("decoded_document");
    if (decodedDocument) {
      const parsedDecodedDocument = JSON.parse(decodedDocument); // Parse the JSON
      if (Array.isArray(parsedDecodedDocument)) { // Check if it's an array
        setDecodedDocument(parsedDecodedDocument);
        parsedDecodedDocument.forEach((item) => {
          setDate((prev) => ([ ...prev, item.date ]));
          setDebit((prev) => ([ ...prev, item.debit ]));
          setCredit((prev) => ([ ...prev, item.credit ]));
          setDescription((prev) => ([ ...prev, item.description ]));
          setBalance((prev) => ([ ...prev, item.balance ]));
          setDocument_id((prev) => ([ ...prev, item.document_id ]));
          setShop_id((prev) => ([ ...prev, item.shop_id ]));
        });
      } else {
        console.error("Decoded document is not an array:", parsedDecodedDocument);
      }
    }
  }, []);
 
  // console.log(decoded_document,'data added')
  // const [data, setData] = useState(initialData);

  const handleInputChange = (e, index, field) => {
    const updatedData = [...decodedDocument];
    updatedData[index][field] = e.target.value;
    setDecodedDocument(updatedData);
  };


  const [loading, setLoading] = useState(false);
  const loadingFunc = (key) => {
    setLoading(key);
  };
  useEffect(() => {
    invoiceRegistration(loadingFunc);

  }, [invoiceRegistration]);
  console.log(invoiceRegistration)
  const onSubmit = async (e) => {
    e.preventDefault();
    // if (validate()) {
    setLoading(true);
    try {
      var myHeaders = new Headers();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var formdata = new FormData();
      formdata.append("date[]", date);
      formdata.append("description[]", description);
      formdata.append("document_id", document_id);
      formdata.append("shop_id", shop_id);
      formdata.append("vendor_id", "");
      formdata.append("debit[]", debit);
      formdata.append("credit[]", credit);
      formdata.append("balance[]", balance);
      axios.post(`${LIVE_URl}/buyer-store-multiple`, formdata, myHeaders,config)
        .then(function ({ response }) {
          console.log(response)
          setLoading(false);

          navigate("/dashboard/all-invoice");

        });

    } catch (err) {
      setLoading(false);
    }
    // }
  };

  return (
    <>

      <div >
        <div className="page " >
          <header className="my-3">
            <div className="container-fluid">

              <div className="row">
                <div className="col-md-6 mh-100">
                  <div className="card">
                    <div className="card-body b-b">
                      <object
                        data="https://pharmacy.webspider.pk/public/lexon_invoice.pdf"
                        type="application/pdf"
                        width="100%"
                        height="650px"
                      >
                        alt :{" "}
                        <a href="https://pharmacy.webspider.pk/public/lexon_invoice.pdf">
                          test.pdf
                        </a>
                      </object>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body b-b">
                      <form className="form-material">
                        <div className="body">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-sm-6">
                              <div className="box-body no-padding table-editable" style={{ overflow: "scroll" }}>
                                <label for="">Editable Table Here</label>
                                <table className="table table-striped">
                                  <tbody >
                                  
                                      
                                      <tr>
                          <th style={{ width: "10px" }}>Date</th>
                            <th className="text-left">Description</th>
                             <th className="text-left">Debit</th>                         
                                 <th className="text-left">ref</th>
                            <th style={{ width: "160px" }}>Credit</th>
                            <th style={{ width: "160px" }}>Balance</th>
                          </tr>
                                   
                                    
  {decodedDocument ? (
    decodedDocument.map((data, index) =>(
      <tr key={index}>
      <td>
     <input
       type="text"
       value={data?.date}
       onChange={(e) => handleInputChange(e, index, "date")}
     />
   </td>
   <td>
     <input
       type="text"
       value={data?.description}
       onChange={(e) => handleInputChange(e, index, "description")}
     />
   </td>
   <td>
     <input
       type="number"
       value={data?.debit}
       onChange={(e) => handleInputChange(e, index, "debit")}
     />
   </td><td>
     <input
       type="number"
       value={data?.ref}
       onChange={(e) => handleInputChange(e, index, "ref")}
     />
   </td>
   <td>
     <input
       type="number"
       value={data?.credit}
       onChange={(e) => handleInputChange(e, index, "credit")}
     />
   </td>
   <td>
     <input
       type="number"
       value={data?.balance}
       onChange={(e) => handleInputChange(e, index, "balance")}
     />
   </td>
 </tr>
    ))
  ) : (
    <p>Loading...</p> // Or display an appropriate message for undefined/empty data
  )}


                                    


                                  </tbody>
                                </table>
                              </div>

                              <a
                                loading={loading}
                                onClick={onSubmit}
                                // href="proceed-files.html"
                                className="btn btn-primary mt-2"
                              >
                                <i className="icon-arrow_forward mr-2"></i> Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>{" "}
      </div>
    </>
  );
};

BuyerConverted.propTypes = {
  invoiceRegistration: PropTypes.func.isRequired,
  RegistrationInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  document: state.document,
  invoiceRegistration: state.invoice,
  decodedDocument: state.decodedDocument,
  invoice: state.invoice,
});

export default connect(mapStateToProps, {
  invoiceRegistration,
  RegistrationInvoice,
})(BuyerConverted);