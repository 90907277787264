import React, { useEffect, useState } from "react";
import { getDocuments } from "../../actions/document";
import { documentRegistration } from "../../actions/document";
import { getBanks } from "../../actions/bank";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const FileConvertBank = ({
  getBanks,
  bank: { allBanks },
  documentRegistration,
  document: { allDocuments },
  getDocuments,
  shops: { allShops },
  getShops,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    document_name: "",
    document_type: "",
    document_type_id: "",
    customer_id: "",
    vendor_id: "",
    bank_id: "",
    shop_id: "",
    file_link: "",
  });
  const [errors, setErrors] = useState({
    document_nameErr: "",
    document_typeErr: "",
    document_type_idErr: "",
    customer_idErr: "",
    vendor_idErr: "",
    bank_idErr: "",
    shop_idErr: "",
    file_linkErr: "",
  });

  const {
    document_name,
    document_type,
    document_type_id,
    customer_id,
    vendor_id,
    bank_id,
    shop_id,
    file_link,
  } = data;

  const {
    document_nameErr,
    document_typeErr,
    document_type_idErr,
    customer_idErr,
    vendor_idErr,
    bank_idErr,
    shop_idErr,
    file_linkErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getBanks(loadingFunc);
    getDocuments(loadingFunc);
    getShops(loadingFunc);
  }, [getBanks, getDocuments, getShops]);

  const validate = () => {
    if (!document_name) {
      setErrors((prev) => ({
        ...prev,
        document_nameErr: "Please provide document name",
      }));
    }
    if (!bank_id) {
      setErrors((prev) => ({
        ...prev,
        bank_idErr: "Please provide bank",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please provide shop",
      }));
    }
    if (!file_link) {
      setErrors((prev) => ({
        ...prev,
        file_linkErr: "Please select file link",
      }));
    }

    if (!document_name || !bank_id || !shop_id || !file_link) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      file_linkErr: "",
    }));
    setData({ ...data, file_link: file });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let dataForm = new FormData();
        dataForm.append("document_name", document_name);
        dataForm.append("document_type", JSON.stringify("App\\Models\\Bank"));
        dataForm.append("document_type_id", bank_id);
        dataForm.append("customer_id", "0");
        dataForm.append("vendor_id", "0");
        dataForm.append("bank_id", bank_id);
        dataForm.append("shop_id", shop_id);
        dataForm.append("file_link", file_link);
        let response = await documentRegistration(dataForm, loadingFunc);
        if (response?.status === 200) {
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                File Conversion<span className="s-14">Bank </span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="container">
                        <div className="row my-3">
                          <div className="col-sm">
                            <div className="form-line focused">
                              <input
                                type="text"
                                name="document_name"
                                value={document_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    document_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Document name"
                              />
                            </div>
                            {document_nameErr && (
                              <p className="text-danger">{document_nameErr}</p>
                            )}
                          </div>
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                name="bank_id"
                                className="custom-select form-control"
                                value={bank_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    bank_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an bank
                                </option>
                                {allBanks?.map((data, i) => (
                                  <option value={data?._id} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {bank_idErr && (
                              <p className="text-danger">{bank_idErr}</p>
                            )}
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                name="shop_id"
                                className="custom-select form-control"
                                value={shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops?.map((data, i) => (
                                  <option value={data?._id} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {shop_idErr && (
                              <p className="text-danger">{shop_idErr}</p>
                            )}
                          </div>
                          <div className="col-sm">
                            <div className="form-line focused">
                              <input
                                type="file"
                                name="file_link"
                                onChange={(e) => onChangeFile(e)}
                                className="form-control"
                              />
                            </div>
                            {file_linkErr && (
                              <p className="text-danger">{file_linkErr}</p>
                            )}
                          </div>
                        </div>
                        <a onClick={onSubmit} className="btn btn-primary mt-2">
                          <i className="icon-arrow_forward mr-2"></i> Submit
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-6 mh-100">
              <div className="card">
                <div className="card-body b-b">
                  <object
                    data="http://pharmacy.webspider.pk/public/uploads/document/1678211272150429916.pdf"
                    type="application/pdf"
                    width="100%"
                    height="650px"
                  >
                    alt :{" "}
                    <a href="http://pharmacy.webspider.pk/public/uploads/document/1678211272150429916.pdf">
                      test.pdf
                    </a>
                  </object>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="box-body no-padding table-editable">
                            <label for="">Editable Table Here</label>
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <td>Type</td>
                                  <td>File link</td>
                                </tr>
                                {allDocuments
                                  ?.filter(function (document) {
                                    return (
                                      document.documentable_type ===
                                      JSON.stringify("App\\Models\\Bank")
                                    );
                                  })
                                  .map((data, i) => (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{data?.name}</td>
                                      <td>{data?.documentable_type}</td>
                                      <td>
                                        <a
                                          href={data?.file_link}
                                          target="_blank"
                                        >
                                          Link
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                          <a
                            href="procede-files.html"
                            className="btn btn-primary mt-2"
                          >
                            <i className="icon-arrow_forward mr-2"></i> Submit{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FileConvertBank.propTypes = {
  documentRegistration: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
  document: state.document,
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getBanks,
  getDocuments,
  documentRegistration,
  getShops,
})(FileConvertBank);
