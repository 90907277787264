// GET ALL COURSES

import { LIVE_URl, GET_BUSINESS, GET_BUSINESS_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL COURSES

export const getBusinesses = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/business`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_BUSINESS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// BUSINESS REGISTRATION

export const businessRegistration =
  (businessDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const body = businessDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/business`, body, config);

      if (res) {
        dispatch(getBusinesses(loadingFunc));
        toast.success("Business Registration Successfully");
      }
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// BUSINESS UPDATE

export const businessUpdate =
  (businessId, businessDetails) => async (dispatch) => {
    console.log("businessDetails", businessDetails);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = businessDetails;
    try {
      const res = await axios.put(
        `${LIVE_URl}/business/${businessId}`,
        body,
        config
      );
      toast.success(res?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// GET BUSINESS DETAILS
export const getBusinessDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/business/${id}`);
    if (res) {
      dispatch({
        type: GET_BUSINESS_DETAILS,
        payload: res?.data?.data,
      });
    } else {
      return false;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
  return true;
};

// DELETE BANK

export const deleteBusiness =
  (businessId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(
        `${LIVE_URl}/business/${businessId}`,
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getBusinesses(deleteLoaderFunc));
        toast.success("Business Delete Successfully");
      }

      return res;
    } catch (error) {
      deleteLoaderFunc(false);

      toast.error(error.response.data.message);
      return false;
    }
  };
