import React, { useState } from "react";
import { bankRegistration } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AddBank = ({ bankRegistration }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    bank_name: "",
    code: "",
    city: "",
    country: "",
    is_trained: "",
  });
  const [errors, setErrors] = useState({
    bank_nameErr: "",
    codeErr: "",
    cityErr: "",
    countryErr: "",
    is_trainedErr: "",
  });

  const { bank_name, code, city, country, is_trained } = data;

  const { bank_nameErr, codeErr, cityErr, countryErr, is_trainedErr } = errors;

  const validate = () => {
    if (!bank_name) {
      setErrors((prev) => ({
        ...prev,
        bank_nameErr: "Please provide bank name",
      }));
    }
    if (!code) {
      setErrors((prev) => ({
        ...prev,
        codeErr: "Please provide bank code",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!is_trained) {
      setErrors((prev) => ({
        ...prev,
        is_trainedErr: "Please provide trained",
      }));
    }

    if (!bank_name || !code || !city || !country || !is_trained) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await bankRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-banks");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Add Bank <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="bank_name"
                                value={bank_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    bank_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank name"
                              />
                            </div>
                            {bank_nameErr && (
                              <p className="text-danger">{bank_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="code"
                                value={code}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    codeErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank code"
                              />
                            </div>
                            {codeErr && (
                              <p className="text-danger">{codeErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="country"
                                value={country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    countryErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Country"
                                required="required"
                              />
                            </div>
                            {countryErr && (
                              <p className="text-danger">{countryErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="city"
                                value={city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    cityErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="City"
                                required="required"
                              />
                            </div>
                            {cityErr && (
                              <p className="text-danger">{cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="is_trained"
                                value={is_trained}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    is_trainedErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Trained"
                                required="required"
                              />
                            </div>
                            {is_trainedErr && (
                              <p className="text-danger">{is_trainedErr}</p>
                            )}
                          </div>
                          {
                            hasPermission('add-bank') &&
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              bank_nameErr ||
                              codeErr ||
                              cityErr ||
                              countryErr ||
                              is_trainedErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddBank.propTypes = {
  bankRegistration: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  bankRegistration,
})(AddBank);
