// import { GET_STAFF } from "../actions/types";

// const initialState = {
//   allStaffs: [],
// };

// export default function staff(state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case GET_STAFF:
//       return {
//         ...state,
//         allStaffs: payload,
//       };
//     default:
//       return state;
//   }
// }

import { GET_STAFF,GET_STAFF_DETAILS  } from "../actions/types";

const initialState = {
  allStaffs: [],
  staffDetails: null,
};

export default function staff(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF:
      return {
        ...state,
        allStaffs: payload,
      };
      case GET_STAFF_DETAILS:
        return {
          ...state,
          staffDetails: payload,
        };
    default:
      return state;
  }
}
