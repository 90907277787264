import React, { useEffect, useState } from "react";
import { getPlans, deletePlan } from "../../actions/plan";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AllPlans = ({ plan: { allPlans }, getPlans, deletePlan }) => {
  const [loading, setLoading] = useState(false);
  const [showDeletePlanModal, setDeletePlanModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [planData, setPlanData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getPlans(loadingFunc);
  }, [getPlans]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Plan MODAL

  const openDeletePlanModal = (data) => {
    setPlanData(data);
    setDeletePlanModal(true);
  };

  const closeDeletePlanModal = () => setDeletePlanModal(false);

  const deletePlanFunc = async (e) => {
    e.preventDefault();
    let res = await deletePlan(planData?._id, deleteLoaderFunc);
    if (res) {
      closeDeletePlanModal();
      setPlanData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeletePlanModal}
        onHide={closeDeletePlanModal}
        title="Delete Plan"
        bodyText="Are you sure, you want to delete the Plan?"
        onClickNo={closeDeletePlanModal}
        onClickYes={(e) => deletePlanFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                All Plans <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {hasPermission('add-plan') &&    <Link
                      to="/dashboard/add-plan"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      {" "}
                      Add New Plan
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allPlans?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-right">Plan Name</th>
                            <th className="text-left">Start Date</th>
                            <th className="text-left">End Date</th>
                            <th className="text-left">No of Users</th>
                            <th className="text-left">Price</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allPlans?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data?.name}</td>
                              <td className="text-left">{data?.start_date}</td>
                              <td className="text-left">{data?.end_date}</td>
                              <td className="text-left">{data?.no_of_users}</td>
                              <td className="text-left">{data?.fee}</td>
                              <td className="text-right">
                              {hasPermission('view-plan') && 
                              <a href="bank_add_account_bussiness.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
}
                                |
                                {hasPermission('edit-plan') && 
                                <Link to={`/dashboard/edit-plan/${data?._id}`}>
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
}
                                |
                                {hasPermission('delete-plan') && 
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeletePlanModal(data)}
                                  style={{ cursor: "pointer" }}
                                ></i>
}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllPlans.propTypes = {
  getPlans: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plan: state.plan,
});

export default connect(mapStateToProps, {
  getPlans,
  deletePlan,
})(AllPlans);
