import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteBusiness, getBusinesses } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddBusiness from "../../components/forms/AddBusiness";
import { FaEye, FaEdit, FaUserPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import EditBusiness from "../../components/forms/EditBusiness";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";
const Business = ({
  business: { allBusiness },
  toggleNavbar,
  getBusinesses,
  deleteBusiness,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteBusinessModal, setDeleteBusinessModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [businessDataState, setBusinessDataState] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddBusinessForm = () => {
    setToggleForm(true);
  };

  const closeAddBusinessForm = () => {
    setToggleForm(false);
  };

  const toggleEditBusinessForm = (data) => {
    setBusinessDataState(data);
    setToggleEditForm(true);
  };

  const closeEditBusinessForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getBusinesses(loadingFunc);
  }, [getBusinesses]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE BUSINESS MODAL

  const openDeleteBusinessModal = (data) => {
    setBusinessDataState(data);
    setDeleteBusinessModal(true);
  };

  const closeDeleteBusinessModal = () => setDeleteBusinessModal(false);

  const deleteBusinessFunc = async (e) => {
    e.preventDefault();
    let res = await deleteBusiness(businessDataState?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteBusinessModal();
      setBusinessDataState("");
    }
  };

  return (
    <>
      {/* ADD BUSINESS MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddBusinessForm}
        bodyContent={
          <AddBusiness closeAddBusinessForm={closeAddBusinessForm} />
        }
        title="Add Business"
      />

      {/* EDIT BUSINESS MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditBusinessForm}
        bodyContent={
          <EditBusiness
            closeEditBusinessForm={closeEditBusinessForm}
            businessData={businessDataState}
          />
        }
        title="Edit Business"
      />

      <DeleteModal
        show={showDeleteBusinessModal}
        onHide={closeDeleteBusinessModal}
        title="Delete Business"
        bodyText="Are you sure, you want to delete the business?"
        onClickNo={closeDeleteBusinessModal}
        onClickYes={(e) => deleteBusinessFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Business Registration
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                        {
                            hasPermission('add-business') &&   <a
                        onClick={(e) => toggleAddBusinessForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Business
                      </a>
}
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allBusiness?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Country</th>
                              <th className="text-left">City</th>
                              <th className="text-left">Email</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allBusiness?.map((businessData, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{businessData?.name}</td>
                                <td className="text-left">
                                  {businessData?.country}
                                </td>
                                <td className="text-left">
                                  {businessData?.city}
                                </td>
                                <td className="text-left">
                                  {businessData?.business_email}
                                </td>
                                <td className="text-right">
                                  {/* <FaUserPlus
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  | */}
                                    {
                            hasPermission('view-business') &&   <FaEye
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                    }

                                  |
                                  {
                            hasPermission('edit-business') &&  
                                  <FaEdit
                                    onClick={() =>
                                      toggleEditBusinessForm(businessData)
                                    }
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
}
                                  |
                                  {
                            hasPermission('delete-business') &&  
                                  <AiFillDelete
                                    onClick={() =>
                                      openDeleteBusinessModal(businessData)
                                    }
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

Business.propTypes = {
  getBusinesses: PropTypes.func.isRequired,
  deleteBusiness: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  getBusinesses,
  deleteBusiness,
})(Business);
