import { GET_ACCOUNTS, GET_ACCOUNT_DETAILS } from "../actions/types";

const initialState = {
  allAccounts: [],
  accountDetails: null,
};

export default function account(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        allAccounts: payload,
      };
    case GET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: payload,
      };
    default:
      return state;
  }
}
