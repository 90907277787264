import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { businessRegistration } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const UserDetails = ({
  changeStep,
  data,
  setData,
  errors,
  setErrors,
  closeAddBusinessForm,
  businessRegistration,
}) => {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const validate = () => {
    if (!data?.first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!data?.last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!data?.email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(data?.email)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: data?.email
          ? "Invalid email address"
          : "Email can't be empty",
      }));
    }
    if (!data?.password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!data?.password_confirm) {
      setErrors((prev) => ({
        ...prev,
        password_confirmErr: "Please provide confirm password",
      }));
    }
    if (!data?.city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!data?.country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!data?.owner_image) {
      setErrors((prev) => ({
        ...prev,
        owner_imageErr: "Please provide owner image",
      }));
    }

    if (
      !data?.first_name ||
      !data?.last_name ||
      !data?.email ||
      !data?.password ||
      !data?.password_confirm ||
      !data?.city ||
      !data?.country ||
      !data?.owner_image
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    setData((prev) => ({ ...prev, email: e.target.value }));
    setErrors((prev) => ({ ...prev, emailErr: "" }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        let response = await businessRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          closeAddBusinessForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const backPage = (e) => {
    e.preventDefault();
    localStorage.removeItem("steps", "1");
    changeStep(e, "1");
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirm: "",
      city: "",
      country: "",
      owner_image: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>First Name</label>
            <input
              style={{ border: errors?.first_nameErr && "1px solid #d32f2f" }}
              type="text"
              name="first_name"
              value={data?.first_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  first_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="First name"
            />
            {errors?.first_nameErr && (
              <Typography className={classes.errorMsg}>
                {errors?.first_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              style={{ border: errors?.last_nameErr && "1px solid #d32f2f" }}
              value={data?.last_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  last_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Last name"
              required="required"
            />
            {errors?.last_nameErr && (
              <Typography className={classes.errorMsg}>
                {errors?.last_nameErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Owner Country</label>
            <input
              type="text"
              name="country"
              style={{ border: errors?.countryErr && "1px solid #d32f2f" }}
              value={data?.country}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  countryErr: "",
                }));
              }}
              className="form-control"
              placeholder="Owner country"
              required="required"
            />
            {errors?.countryErr && (
              <Typography className={classes.errorMsg}>
                {errors?.countryErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Owner City</label>
            <input
              type="text"
              name="city"
              style={{ border: errors?.cityErr && "1px solid #d32f2f" }}
              value={data?.city}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  cityErr: "",
                }));
              }}
              className="form-control"
              placeholder="Owner city"
              required="required"
            />
            {errors?.cityErr && (
              <Typography className={classes.errorMsg}>
                {errors?.cityErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Email</label>
            <input
              type="text"
              name="email"
              style={{ border: errors?.emailErr && "1px solid #d32f2f" }}
              value={data?.email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Email"
              required="required"
            />
            {errors?.emailErr && (
              <Typography className={classes.errorMsg}>
                {errors?.emailErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Password</label>
            <input
              type="password"
              name="password"
              style={{ border: errors?.passwordErr && "1px solid #d32f2f" }}
              value={data?.password}
              onChange={(e) => {
                setData((prev) => ({ ...prev, password: e.target.value }));
                if (Number(e.target.value.length) < 8) {
                  setErrors((prev) => ({
                    ...prev,
                    passwordErr: "Password must be atleast 8 characters",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    passwordErr: "",
                  }));
                }
              }}
              className="form-control"
              placeholder="Password"
              required="required"
            />

            {errors?.passwordErr && (
              <Typography className={classes.errorMsg}>
                {errors?.passwordErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Confirm Password</label>
            <input
              type="password"
              name="password_confirm"
              style={{
                border: errors?.password_confirmErr && "1px solid #d32f2f",
              }}
              value={data?.password_confirm}
              onChange={(e) => {
                if (data?.password && e.target.value !== data?.password) {
                  setErrors((prev) => ({
                    ...prev,
                    password_confirmErr:
                      "Password and Confirm Password does not match.",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    password_confirmErr: "",
                  }));
                }
                setData({ ...data, password_confirm: e.target.value });
              }}
              className="form-control"
              placeholder="Confirm password"
              required="required"
            />

            {errors?.password_confirmErr && (
              <Typography className={classes.errorMsg}>
                {errors?.password_confirmErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Owner Image</label>
            <input
              type="file"
              name="owner_image"
              style={{
                border: errors?.owner_imageErr && "1px solid #d32f2f",
              }}
              value={data?.owner_image}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  owner_imageErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business image"
              required="required"
            />
            {errors?.owner_imageErr && (
              <Typography className={classes.errorMsg}>
                {errors?.owner_imageErr}
              </Typography>
            )}
          </Col>
        </Row>
        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              errors?.first_nameErr ||
              errors?.last_nameErr ||
              errors?.emailErr ||
              errors?.passwordErr ||
              errors?.password_confirmErr ||
              errors?.cityErr ||
              errors?.countryErr ||
              errors?.owner_imageErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton text={"BACK"} onClick={backPage} />
        </div>
      </form>
    </>
  );
};

UserDetails.propTypes = {
  businessRegistration: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  businessRegistration,
})(UserDetails);
