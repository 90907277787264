import { LOGIN_SUCCESS, LOG_OUT } from "../actions/types";

const initialState = {
  token: sessionStorage.getItem("token"),
  user_id: null,
  isAuthenticated: false,
  userDetail: null,
  loading: true,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        user_id: payload.user?.id,
      };
    case LOG_OUT:
      sessionStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        user_id: null,
        userDetail: null,
      };
    default:
      return state;
  }
}
