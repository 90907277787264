import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { updateStaff } from "../../actions/staff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const EditStaff = ({ closeEditStaffForm, updateStaff, staffData }) => {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    first_name: staffData?.user?.first_name,
    last_name: staffData?.user?.last_name,
    email: staffData?.user?.email,
    city: staffData?.user?.city,
    country: staffData?.user?.country,
    staff_image: "",
    status: staffData?.user?.staff_image,
  });
  const [errors, setErrors] = useState({
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    cityErr: "",
    countryErr: "",
    staff_imageErr: "",
    statusErr: "",
  });

  const { first_name, last_name, email, city, country, staff_image, status } =
    data;

  const {
    first_nameErr,
    last_nameErr,
    emailErr,
    cityErr,
    countryErr,
    staff_imageErr,
    statusErr,
  } = errors;

  const validate = () => {
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!staff_image) {
      setErrors((prev) => ({
        ...prev,
        staff_imageErr: "Please provide staff image",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please provide staff status",
      }));
    }

    if (
      !first_name ||
      !last_name ||
      !email ||
      !city ||
      !country ||
      !staff_image ||
      !status
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await updateStaff(staffData?._id, data, loadingFunc);
        setLoading(false);
        if (response) {
          closeEditStaffForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      first_name: "",
      last_name: "",
      email: "",
      city: "",
      country: "",
      staff_image: "",
      status: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>First Name</label>
            <input
              style={{ border: first_nameErr && "1px solid #d32f2f" }}
              type="text"
              name="first_name"
              value={first_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  first_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="First name"
            />
            {first_nameErr && (
              <Typography className={classes.errorMsg}>
                {first_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              style={{ border: last_nameErr && "1px solid #d32f2f" }}
              value={last_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  last_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Last name"
              required="required"
            />
            {last_nameErr && (
              <Typography className={classes.errorMsg}>
                {last_nameErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Email</label>
            <input
              type="text"
              name="email"
              style={{ border: emailErr && "1px solid #d32f2f" }}
              value={email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Email"
              required="required"
            />
            {emailErr && (
              <Typography className={classes.errorMsg}>{emailErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Staff Country</label>
            <input
              type="text"
              name="country"
              style={{ border: countryErr && "1px solid #d32f2f" }}
              value={country}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  countryErr: "",
                }));
              }}
              className="form-control"
              placeholder="Staff country"
              required="required"
            />
            {countryErr && (
              <Typography className={classes.errorMsg}>{countryErr}</Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Staff City</label>
            <input
              type="text"
              name="city"
              style={{ border: cityErr && "1px solid #d32f2f" }}
              value={city}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  cityErr: "",
                }));
              }}
              className="form-control"
              placeholder="Staff city"
              required="required"
            />
            {cityErr && (
              <Typography className={classes.errorMsg}>{cityErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Staff Image</label>
            <input
              type="file"
              name="staff_image"
              style={{
                border: staff_imageErr && "1px solid #d32f2f",
              }}
              value={staff_image}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  staff_imageErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business image"
              required="required"
            />
            {staff_imageErr && (
              <Typography className={classes.errorMsg}>
                {staff_imageErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Status</label>
            <div className="select">
              <select
                name="status"
                style={{ border: statusErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={status}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    statusErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an status
                </option>
                <option value="1">Active</option>
                <option value="0">In active</option>
              </select>
              {statusErr && (
                <Typography className={classes.errorMsg}>
                  {statusErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>
        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Edit Staff"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              first_nameErr ||
              last_nameErr ||
              emailErr ||
              cityErr ||
              countryErr ||
              staff_imageErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton text={"Reset"} onClick={onResetForm} />
        </div>
      </form>
    </>
  );
};

EditStaff.propTypes = {
  updateStaff: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staff,
});

export default connect(mapStateToProps, {
  updateStaff,
})(EditStaff);
