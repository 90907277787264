import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hasPermission from "../../utils/permisssions";
import axios from "axios";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { statementRegistration, getStatement, deleteStatement } from "../../actions/statement";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/global/DeleteModal";
import AddStatement from "./AddStatement";
import EditStatement from "./EditStatement";
import Model from "../../components/global/Model";
const Statement = ({ statementRegistration, statement: { allStatement }, getStatement, deleteStatement }) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteStatementModal, setDeleteStatementModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [statementData, setStatementData] = useState("");
  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddStatementForm = () => {
    setToggleForm(true);
  };

  const closeAddStatementForm = () => {
    setToggleForm(false);
  };

  const toggleEditStatementForm = (data) => {
    setStatementData(data);
    setToggleEditForm(true);
  };

  const closeEditStatementForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getStatement(loadingFunc);
  }, [getStatement]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Plan MODAL

  const openDeleteStatementModal = (data) => {
    setStatementData(data);
    setDeleteStatementModal(true);
  };

  const closeDeleteStatementModal = () => setDeleteStatementModal(false);

  const deleteStatementFunc = async (e) => {
    e.preventDefault();
    let res = await deleteStatement(statementData?._id, deleteLoaderFunc);
    console.log(statementData)
    if (res) {
      closeDeleteStatementModal();
      setStatementData("");

    }
  };
  return (
    <>
      {/* ADD Plan MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddStatementForm}
        bodyContent={<AddStatement closeAddStatementForm={closeAddStatementForm} />}
        title="Add Statement"
      />

      {/* EDIT Plan MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditStatementForm}
        bodyContent={
          <EditStatement closeEditStatementForm={closeEditStatementForm} statementData={statementData} />
        }
        title="Edit Statement"
      />

      <DeleteModal
        show={showDeleteStatementModal}
        onHide={closeDeleteStatementModal}
        title="Delete Statement"
        bodyText="Are you sure, you want to delete the Statement?"
        onClickNo={closeDeleteStatementModal}
        onClickYes={(e) => deleteStatementFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <div >
        <div className="page ">
          <header className="my-3">
            <div className="container-fluid">

              <div className="row">

                <div className="col-md-12">
                  <div className="card">

                    <div className="card-body b-b">
                      <form className="form-material">
                        <div className="body">
                          <div className="row clearfix">
                            <div className="box-header">
                              <div
                                className="box-header"
                                style={{ display: "flex", justifyContent: "end" }}
                              >
                                {hasPermission('add-statement') &&
                                  <Link
                                    to="/dashboard/add-statement"
                                    className="btn btn-sm btn-danger float-right mb-4"
                                  >
                                    {" "}
                                    Add New Statement
                                  </Link>
                                }
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-6">
                              <div className="box-body no-padding table-editable" style={{ overflow: "scroll" }}>
                                {loading ? (
                                  <Loader />
                                ) : allStatement?.length > 0 ? (
                                  <table className="table ">
                                    <tbody >
                                      <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                        <th>Action</th>
                                      </tr>
                                      {allStatement?.map((data, i) => (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td className="text-left">{data?.date}</td>
                                          <td className="text-left">{data?.description}</td>
                                          <td className="text-left">{data?.debit}</td>
                                          <td className="text-left">{data?.credit}</td>
                                          <td className="text-left">{data?.balance}</td>
                                          <td className="text-left">
                                            {hasPermission('view-statement') &&
                                              <a href="#">
                                                {" "}
                                                <i className="s-24 icon-eye"> </i>{" "}
                                              </a>
                                            }
                                            |
                                            {hasPermission('edit-statement') &&
                                              <Link to={`/dashboard/edit-statement/${data?._id}`}>
                                                <i className="s-24  icon-clipboard-edit"></i>{" "}
                                              </Link>
                                            }
                                            |
                                            {hasPermission('delete-statement') &&
                                              <i
                                                className="s-24 icon-trash-can"
                                                onClick={() => openDeleteStatementModal(data)}
                                                style={{ cursor: "pointer" }}
                                              ></i>
                                            }
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <h2>No Data</h2>
                                )}
                              </div>


                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>{" "}
      </div>
    </>
  );
};

Statement.propTypes = {
  statementRegistration: PropTypes.func.isRequired,
  getStatement: PropTypes.func.isRequired,
  deleteStatement: PropTypes.func.isRequired,
  statement: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  document: state.document,
  statementRegistration: state.statement,
  statement: state.statement,
});

export default connect(mapStateToProps, {
  statementRegistration,
  getStatement,
  deleteStatement,
})(Statement);
