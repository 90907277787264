import {
  LIVE_URl,
  GET_BANKS,
  GET_BANK_REQUEST,
  GET_BANK_REQUEST_DETAILS,
  GET_BANK_DETAILS,
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL BANKS

export const getBanks = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/bank/list`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_BANKS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);

    toast.error(error.response.data.message);
  }
};
// GET ALL BANK REQUEST

export const getBankRequests = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/bank-request/list`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_BANK_REQUEST,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);

    toast.error(error.response.data.message);
  }
};

// GET BUSINESS DETAILS
export const getBankRequestsDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/bank-request/${id}`);
    if (res) {
      dispatch({
        type: GET_BANK_REQUEST_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// BANK REGISTRATION

export const bankRegistration =
  (bankDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = bankDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/bank/save`, body, config);

      if (res) {
        toast.success("Bank Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// BANK REQUEST REGISTRATION

export const bankRequestRegistration =
  (details, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const body = details;
    try {
      const res = await axios.post(
        `${LIVE_URl}/bank-request/save`,
        body,
        config
      );
      if (res) {
        toast.success("Bank Request Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const updateBankRequest =
  (bankId,bankRequestDetails,bankID  ) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const body = bankRequestDetails;
    try {
      const body = {
        ...bankRequestDetails,
        bank_id: bankID,
      };
      const res = await axios.put(
        `${LIVE_URl}/bank-request/update/${bankId}`,
      //  {bank_name:bank_name,is_trained:is_trained},
      body,
        config
      );
console.log(bankRequestDetails)
      if (res) {
        toast.success("Bank Request Updated Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

  export const BankDetails = (id) => async (dispatch, data) => {
    try {
      const res = await axios.get(`${LIVE_URl}/bank/list/${id}`);
      if (res) {
        dispatch({
          type: GET_BANK_DETAILS,
          payload: res?.data?.data,
         
        });
        console.log(data)
      }
  
      return true;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
 
  
  // export const updateBank = (bankId, BankDetails, loadingFunc) => async (dispatch) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept" : "Application /json"

  //     },
  //   };
  //   const body = BankDetails; 
  //   try {
  //     const res = await axios.post(
  //       `${LIVE_URl}/bank/update/`,
  //       body,
  //       config,headers
  //     );

  //     if (res) {
  //       toast.success("Bank Updated Successfully");
  //     }

  //     return res;
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //     return false;
  //   }
  // };
 const headers = {
    "Authorization": "Bearer YOUR_ACCESS_TOKEN",
    "Content-Type": "Application/json"
  };
  // export const getBankDetails = (id, loadingFunc) => async (dispatch) => {
  //   try {
  //     loadingFunc(true);
  //     const res = await axios.get(`${LIVE_URl}/bank/list/${id}`);
  //     if (res) {
  //       loadingFunc(false);
  //       dispatch({
  //         type: GET_BANK_DETAILS,
  //         payload: res?.data?.data,
  //       });
  //     }
  
  //     return true;
  //   } catch (error) {
  //     loadingFunc(false);
  //     toast.error(error.response.data.message);
  //   }
  // };
  
 
  export const updateBank = (bankId, BankDetails,  bankID) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const body = {
        ...BankDetails,
        bank_id: bankID,
      };
      const res = await axios.put(
        `${LIVE_URl}/bank/update/${bankId}`,
        body,
        config
      );
      if (res) {
        // loadingFunc(false);
        toast.success("Bank Updated Successfully");
      }
  
      return res;
    } catch (error) {
      // loadingFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
  
// DELETE BANK
export const deleteBank =
  (bankId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(
        `${LIVE_URl}/account/${bankId}`,
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getBanks(deleteLoaderFunc));
        toast.success("Bank Delete Successfully");
      }
      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };

