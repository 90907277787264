import { GET_BUSINESS, GET_BUSINESS_DETAILS } from "../actions/types";

const initialState = {
  allBusiness: [],
  businessDetails: null,
};

export default function business(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BUSINESS:
      return {
        ...state,
        allBusiness: payload,
      };
    case GET_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: payload,
      };
    default:
      return state;
  }
}
