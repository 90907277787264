const hasPermission = (permission) => {
 
       
      
  //   var permissions = [
  //     "delete-user",
  //     "add-user",
  //     "update-user"
  //  ];
  
    return(
        sessionStorage.getItem('permissions').includes(permission)
        || sessionStorage.getItem('permissions_via_roles').includes(permission)

    ) ;
    
  };
  
  export default hasPermission;