import React, { useEffect, useState } from "react";
import { getVendor } from "../../actions/vendor";
import { getDocuments } from "../../actions/document";
import { documentRegistration } from "../../actions/document";
import { getShops } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hasPermission from "../../utils/permisssions";
import Loader from "../../components/global/Loader";
import axios from "axios";
import { LIVE_URl } from "../../actions/types";
import { useNavigate } from "react-router-dom";
const InvoiceReports = ({
  vendor: { allVendors },
  getVendor,
  shops: { allShops }, business: { allBusiness },
  getShops,
}) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const navigate = useNavigate();

  const [data, setData] = useState({
    vendor_id: "",
    shop_id: "",
    business_id: "",
  });
  const [errors, setErrors] = useState({
    vendor_idErr: "",
    shop_idErr: "",
    business_idErr: "",
  });

  const {
    vendor_id,
    shop_id,
    business_id,
  } = data;

  const {
    vendor_idErr,
    shop_idErr,
    business_idErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getVendor(loadingFunc);
    getShops(loadingFunc);
    getBusinesses(loadingFunc);
  }, [getVendor, getShops, getBusinesses]);

  

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      file_linkErr: "",
    }));
    setData({ ...data, file_link: file });
  };
  function calculateSum(data, column) {
    let sum = 0;
    for (const item of data) {
      sum += parseFloat(item[column]) || 0;
    }
    return sum;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
      setLoading(true);
      try {
        var myHeaders = new Headers();
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        };
        let formdata = new FormData();
        formdata.append("business_id", business_id);
        formdata.append("vendor_id", vendor_id);
        formdata.append("shop_id", shop_id);

       axios.post(`${LIVE_URl}/invoice-report`,formdata,config)
        .then( response => {
          console.log("Invoices",response)
          setLoading(false);
          setInvoices(response?.data?.data)


        });
      
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Invoice Report
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="container">

                        <div className="row my-3">
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                name="vendor_id"
                                className="custom-select form-control"
                                value={vendor_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    vendor_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an vendor
                                </option>
                                {allVendors?.map((data, i) => (
                                  <option value={data?._id} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {vendor_idErr && (
                              <p className="text-danger">{vendor_idErr}</p>
                            )}
                          </div>
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                name="shop_id"
                                className="custom-select form-control"
                                value={shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops?.map((data, i) => (
                                  <option value={data?._id} key={i}>
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {shop_idErr && (
                              <p className="text-danger">{shop_idErr}</p>
                            )}
                          </div>
                          <div className="col-sm">
                            <div className="form-line focused">
                              <select
                                name="business_id"
                                className="custom-select form-control"
                                value={business_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    business_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an business
                                </option>
                                {allBusiness.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {vendor_idErr && (
                              <p className="text-danger">{vendor_idErr}</p>
                            )}
                          </div>
                        </div>
                        <a onClick={onSubmit} className="btn btn-primary mt-2">
                          <i className="icon-arrow_forward mr-2"></i> Submit
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">

            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="box-body no-padding table-editable" style={{ overflow: "scroll" }}>
                            {loading ? (
                              <Loader />
                            ) : invoices?.length > 0 ? (
                              <table className="table ">
                                <tbody >
                                  <tr>
                                    <th>#</th>
                                    <th>Shop</th>
                                    <th>Date</th>

                                    <th>Description</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    <th>Balance</th>
                                  </tr>
                                  {invoices?.map((data, i) => (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td className="text-left">{data?.shop?.name}</td>
                                      <td className="text-left">{data?.date}</td>
                                      <td className="text-left">{data?.description}</td>
                                      <td className="text-left">{data?.debit}</td>
                                      <td className="text-left">{data?.credit}</td>
                                      <td className="text-left">{data?.balance}</td>


                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td className="font-weight-bold">Date:</td>
                                    <td className="font-weight-bold">Description</td>
                                    <td className="font-weight-bold">Sum:   {calculateSum(invoices, 'debit')} </td>
                                    <td className="font-weight-bold">Sum: {calculateSum(invoices, 'credit')}</td>
                                    <td className="font-weight-bold">Sum: {calculateSum(invoices, 'balance')}</td>
                                 

                                  </tr>
                                </tfoot>
                              </table>
                            ) : (
                              <h2>No Data</h2>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

InvoiceReports.propTypes = {
  getVendor: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vendor: state.vendor,
  shops: state.shops,
  business: state.business,
});

export default connect(mapStateToProps, {
  getVendor,
  getShops,
  getBusinesses,
})(InvoiceReports);

