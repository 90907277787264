import React, { useEffect, useState } from "react";
import { updateBankRequest } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LIVE_URl } from "../../actions/types";
const EditBankRequest = ({ updateBankRequest }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    is_trained: "",
    status: "",
    bank_name:""
  });
  const [errors, setErrors] = useState({
    is_trainedErr: "",
    statusErr: "",
    bank_nameErr:""
  });
console.log()
  const {bank_name, is_trained, status } = data;
console.log(data)
  const { bank_nameErr,is_trainedErr, statusErr } = errors;

  const validate = () => {
    if (!bank_name) {
      setErrors((prev) => ({
        ...prev,
        bank_nameErr: "Please provide bank name",
      }));
    }
    if (!is_trained) {
      setErrors((prev) => ({
        ...prev,
        is_trainedErr: "Please provide trained",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please provide status",
      }));
    }

    if (!bank_name || !is_trained || !status) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/bank-request/update/${params.id}`);
      setData({
        ...data,
        bank_name:result.data.bank_name,
        status:result.data.status,
        is_trained: result?.data?.is_trained,
      });
      setLoading(false);
    };
    fetchData();
  }, [params.id]);



  const onSubmit = async (e) => {
    
    e.preventDefault();
    if (validate()) {
      try {
        let response = updateBankRequest(params?.id, data);
        if (response) {
          navigate("/dashboard/all-bank-requests");
          console.log(updateBankRequest)
        }
      } catch (err) {}
    }
  };
  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     setLoading(true);
  //     try {
  //       let response = await updateBankRequest(params?.id, data, loadingFunc);
  //       setLoading(false);
  //       if (response) {
  //         navigateTo("/dashboard/all-bank");
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   }
  // };
  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     setLoading(true);
  //     try {
  //       let response = await (params?.id, data, loadingFunc);
  //       setLoading(false);
  //       if (response) {
  //         navigateTo("/dashboard/all-banks");
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   }
  // };
  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     setLoading(true);
  //     try {
  //       const dataForm = new FormData();
  //       dataForm.append("is_trained", is_trained);
  //       dataForm.append("status", status);
  //       dataForm.append("_id", params.id);
  //       let response = await (dataForm, loadingFunc);
  //       setLoading(false);
  //       if (response) {
  //         navigate("/dashboard/all-bank-requests");
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   }
  // };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Bank <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                        <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="bank_name"
                                value={bank_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    bank_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank name"
                              />
                            </div>
                            {bank_nameErr && (
                              <p className="text-danger">{bank_nameErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <select
                                name="is_trained"
                                className="custom-select form-control"
                                value={is_trained}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    is_trainedErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an trained
                                </option>
                                <option value="1">Trained</option>
                                <option value="0">No Trained</option>
                              </select>
                            </div>
                            {is_trainedErr && (
                              <p className="text-danger">{is_trainedErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <select
                                name="status"
                                className="custom-select form-control"
                                value={status}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    statusErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">In Active</option>
                              </select>
                            </div>
                            {statusErr && (
                              <p className="text-danger">{statusErr}</p>
                            )}
                          </div>

                          <button
                                   onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading || is_trainedErr || statusErr
                                ? { pointerEvents: "none" }
                                : { pointerEvents: "all" }
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditBankRequest.propTypes = {
  updateBankRequest: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  updateBankRequest
})(EditBankRequest);
