import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { businessUpdate } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const EditBusiness = ({
  closeEditBusinessForm,
  businessData,
  businessUpdate,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: businessData?.name,
    contact_number: businessData?.contact_number,
    business_email: businessData?.business_email,
    country: businessData?.country,
    city: businessData?.city,
    post_code: businessData?.post_code,
    valid_till: businessData?.valid_till,
    alert_before: businessData?.alert_before,
    about: businessData?.about,
    status: businessData?.status,
  });
  const [errors, setErrors] = useState({
    nameErr: "",
    contact_numberErr: "",
    business_emailErr: "",
    countryErr: "",
    cityErr: "",
    post_codeErr: "",
    valid_tillErr: "",
    alert_beforeErr: "",
    aboutErr: "",
    statusErr: "",
  });

  const {
    name,
    contact_number,
    business_email,
    country,
    city,
    post_code,
    valid_till,
    alert_before,
    about,
    status,
  } = data;
  const {
    nameErr,
    contact_numberErr,
    business_emailErr,
    countryErr,
    cityErr,
    post_codeErr,
    valid_tillErr,
    alert_beforeErr,
    aboutErr,
    statusErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const validate = () => {
    if (!name) {
      setErrors((prev) => ({
        ...prev,
        nameErr: "Please provide business name",
      }));
    }
    if (!contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!business_email) {
      setErrors((prev) => ({
        ...prev,
        business_emailErr: "Please provide business email",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide business country",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide business city",
      }));
    }
    if (!post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!valid_till) {
      setErrors((prev) => ({
        ...prev,
        valid_tillErr: "Please provide valid till date",
      }));
    }
    if (!alert_before) {
      setErrors((prev) => ({
        ...prev,
        alert_beforeErr: "Please provide alert before date",
      }));
    }
    if (!about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please provide status",
      }));
    }

    if (
      !name ||
      !contact_number ||
      !business_email ||
      !country ||
      !city ||
      !post_code ||
      !valid_till ||
      !alert_before ||
      !about ||
      !status
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, business_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, business_email: e.target.value }));
      setErrors((prev) => ({ ...prev, business_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await businessUpdate(
          businessData?._id,
          data,
          loadingFunc
        );
        setLoading(false);
        if (response) {
          closeEditBusinessForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      name: "",
      contact_number: "",
      business_email: "",
      country: "",
      city: "",
      post_code: "",
      valid_till: "",
      alert_before: "",
      about: "",
      status: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Business Name</label>
            <input
              style={{
                border: nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business name"
            />
            {nameErr && (
              <Typography className={classes.errorMsg}>{nameErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Business email</label>
            <input
              type="text"
              name="business_email"
              style={{
                border: business_emailErr && "1px solid #d32f2f",
              }}
              value={business_email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Business email"
              required="required"
            />
            {business_emailErr && (
              <Typography className={classes.errorMsg}>
                {business_emailErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              style={{
                border: contact_numberErr && "1px solid #d32f2f",
              }}
              value={contact_number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  contact_numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="Contact number"
              required="required"
            />
            {contact_numberErr && (
              <Typography className={classes.errorMsg}>
                {contact_numberErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Postal Code</label>
            <input
              type="text"
              name="post_code"
              style={{ border: post_codeErr && "1px solid #d32f2f" }}
              value={post_code}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  post_codeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Postal code"
              required="required"
            />
            {post_codeErr && (
              <Typography className={classes.errorMsg}>
                {post_codeErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Business Country</label>
            <input
              type="text"
              name="country"
              style={{
                border: countryErr && "1px solid #d32f2f",
              }}
              value={country}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  countryErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business country"
              required="required"
            />
            {countryErr && (
              <Typography className={classes.errorMsg}>{countryErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Business City</label>
            <input
              type="text"
              name="city"
              style={{
                border: cityErr && "1px solid #d32f2f",
              }}
              value={city}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  cityErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business city"
              required="required"
            />
            {cityErr && (
              <Typography className={classes.errorMsg}>{cityErr}</Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Validate Date</label>
            <input
              type="date"
              name="valid_till"
              style={{ border: valid_tillErr && "1px solid #d32f2f" }}
              value={valid_till}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  valid_tillErr: "",
                }));
              }}
              className="form-control"
              placeholder="Validate date"
              required="required"
            />
            {valid_tillErr && (
              <Typography className={classes.errorMsg}>
                {valid_tillErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Alert Date</label>
            <input
              type="date"
              name="alert_before"
              style={{ border: alert_beforeErr && "1px solid #d32f2f" }}
              value={alert_before}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  alert_beforeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Alert date"
              required="required"
            />
            {alert_beforeErr && (
              <Typography className={classes.errorMsg}>
                {alert_beforeErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>About</label>
            <textarea
              type="text"
              name="about"
              style={{ border: aboutErr && "1px solid #d32f2f" }}
              value={about}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  aboutErr: "",
                }));
              }}
              className="form-control"
              placeholder="About"
              required="required"
            />
            {aboutErr && (
              <Typography className={classes.errorMsg}>{aboutErr}</Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Status</label>
            <div className="select">
              <select
                name="status"
                style={{ border: statusErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={status}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    statusErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an business
                </option>
                <option value="1">Active</option>
                <option value="0">In active</option>
              </select>
              {statusErr && (
                <Typography className={classes.errorMsg}>
                  {statusErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              nameErr ||
              contact_numberErr ||
              business_emailErr ||
              countryErr ||
              cityErr ||
              post_codeErr ||
              valid_tillErr ||
              alert_beforeErr ||
              aboutErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};

EditBusiness.propTypes = {
  businessUpdate: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  businessUpdate,
})(EditBusiness);
