import React, { useEffect, useState } from "react";
import { deleteBusiness, getBusinesses } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AllBusiness = ({
  business: { allBusiness },
  getBusinesses,
  deleteBusiness,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteBusinessModal, setDeleteBusinessModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [businessDataState, setBusinessDataState] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getBusinesses(loadingFunc);
  }, [getBusinesses]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE BUSINESS MODAL

  const openDeleteBusinessModal = (data) => {
    setBusinessDataState(data);
    setDeleteBusinessModal(true);
  };

  const closeDeleteBusinessModal = () => setDeleteBusinessModal(false);

  const deleteBusinessFunc = async (e) => {
    e.preventDefault();
    let res = await deleteBusiness(businessDataState?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteBusinessModal();
      setBusinessDataState("");
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeleteBusinessModal}
        onHide={closeDeleteBusinessModal}
        title="Delete Business"
        bodyText="Are you sure, you want to delete the business?"
        onClickNo={closeDeleteBusinessModal}
        onClickYes={(e) => deleteBusinessFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Bussiness Name <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {
                            hasPermission('add-business') &&  <Link
                      to="/dashboard/add-business"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Business
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allBusiness?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Business Name</th>
                            <th className="text-left">Country</th>
                            <th className="text-left">City</th>
                            <th className="text-left">Email</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allBusiness?.map((businessData, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">
                                {businessData?.name}
                              </td>
                              <td className="text-left">
                                {businessData?.country}
                              </td>
                              <td className="text-left">
                                {businessData?.city}
                              </td>
                              <td className="text-left">
                                {businessData?.business_email}
                              </td>
                              <td className="text-right">
                              {
                            hasPermission('edit-business') &&
                              <Link
                                  to={`/dashboard/edit-business/${businessData?._id}`}
                                >
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
}
                                |
                                {
                            hasPermission('delete-business') &&
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() =>
                                    openDeleteBusinessModal(businessData)
                                  }
                                  style={{ cursor: "pointer" }}
                                ></i>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2 >No Data</h2>
                    )}

                   {allBusiness?.length > 0 &&
                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllBusiness.propTypes = {
  getBusinesses: PropTypes.func.isRequired,
  deleteBusiness: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  getBusinesses,
  deleteBusiness,
})(AllBusiness);
