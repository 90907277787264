import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteBank, getBanks } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddBusiness from "../../components/forms/AddBusiness";
import { FaEye, FaEdit, FaUserPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import EditBusiness from "../../components/forms/EditBusiness";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import AddBank from "../../components/forms/AddBank";
import Loader from "../../components/global/Loader";
import EditBank from "../../components/forms/EditBank";

const Bank = ({ bank: { allBanks }, toggleNavbar, getBanks, deleteBank }) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteBankModal, setDeleteBankModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [bankData, setBankData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddBankForm = () => {
    setToggleForm(true);
  };

  const closeAddBankForm = () => {
    setToggleForm(false);
  };

  const toggleEditBankForm = (data) => {
    setBankData(data);
    setToggleEditForm(true);
  };

  const closeEditBankForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getBanks(loadingFunc);
  }, [getBanks]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE BANK MODAL
  const openDeleteBankModal = (data) => {
    setBankData(data);
    setDeleteBankModal(true);
  };

  const closeDeleteBankModal = () => setDeleteBankModal(false);

  const deleteBankFunc = async (e) => {
    e.preventDefault();
    let res = await deleteBank(bankData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteBankModal();
      setBankData("");
    }
  };
  // const openDeleteBankModal = (data) => {
  //   setBankData(data);
  //   setDeleteBankModal(true);
  // };

  // const closeDeleteBankModal = () => setDeleteBankModal(false);

  // const deleteBankFunc = async (e) => {
  //   e.preventDefault();
  //   let res = await deleteBank(bankData?._id, deleteLoaderFunc);
  //   if (res) {
  //     closeDeleteBankModal();
  //     setBankData("");
  //   }
  // };

  return (
    <>
      {/* ADD BANK MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddBankForm}
        bodyContent={<AddBank closeAddBankForm={closeAddBankForm} />}
        title="Add Bank"
      />

      {/* EDIT BANK MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditBankForm}
        bodyContent={
          <EditBank closeEditBankForm={closeEditBankForm} bankData={bankData} />
        }
        title="Edit Bank"
      />

      <DeleteModal
        show={showDeleteBankModal}
        onHide={closeDeleteBankModal}
        title="Delete Bank"
        bodyText="Are you sure, you want to delete the bank?"
        onClickNo={closeDeleteBankModal}
        onClickYes={(e) => deleteBankFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Bank Registration
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <a
                        onClick={(e) => toggleAddBankForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Bank
                      </a>
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allBanks?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Country</th>
                              <th className="text-left">City</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allBanks?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">{data?.country}</td>
                                <td className="text-left">{data?.city}</td>
                                <td className="text-right">
                                  {/* <FaUserPlus
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  | */}
                                  <FaEye
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  |
                                  <FaEdit
                                    // onClick={() => toggleEditBankForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  |
                                  <AiFillDelete
                                    onClick={() => openDeleteBankModal(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

Bank.propTypes = {
  getBanks: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getBanks,
  deleteBank,
})(Bank);
