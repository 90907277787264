import { GET_STATEMENT, GET_STATEMENT_DETAILS } from "../actions/types";

const initialState = {
  allStatement: [],
  statementDetails: null,
};

export default function statement(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATEMENT:
      return {
        ...state,
        allStatement: payload,
      };
    case GET_STATEMENT_DETAILS:
      return {
        ...state,
        statementDetails: payload,
      };
    default:
      return state;
  }
}
