import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { planRegistration } from "../../actions/plan";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const AddPlan = ({ closeAddPlanForm, planRegistration }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    plan_name: "",
    description: "",
    no_of_users: "",
    start_date: "",
    end_date: "",
    fee: "",
    validity_type: "",
    validity_for: "",
  });
  const [errors, setErrors] = useState({
    plan_nameErr: "",
    descriptionErr: "",
    no_of_usersErr: "",
    start_dateErr: "",
    end_dateErr: "",
    feeErr: "",
    validity_typeErr: "",
    validity_forErr: "",
  });

  const {
    plan_name,
    description,
    no_of_users,
    start_date,
    end_date,
    fee,
    validity_type,
    validity_for,
  } = data;

  const {
    plan_nameErr,
    descriptionErr,
    no_of_usersErr,
    start_dateErr,
    end_dateErr,
    feeErr,
    validity_typeErr,
    validity_forErr,
  } = errors;

  const validate = () => {
    if (!plan_name) {
      setErrors((prev) => ({
        ...prev,
        plan_nameErr: "Please provide plan name",
      }));
    }
    if (!description) {
      setErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (!no_of_users) {
      setErrors((prev) => ({
        ...prev,
        no_of_usersErr: "Please provide users",
      }));
    }
    if (!start_date) {
      setErrors((prev) => ({
        ...prev,
        start_dateErr: "Please provide start date",
      }));
    }
    if (!end_date) {
      setErrors((prev) => ({
        ...prev,
        end_dateErr: "Please provide end date",
      }));
    }
    if (!fee) {
      setErrors((prev) => ({
        ...prev,
        feeErr: "Please provide price",
      }));
    }
    if (!validity_type) {
      setErrors((prev) => ({
        ...prev,
        validity_typeErr: "Please provide validate type",
      }));
    }
    if (!validity_for) {
      setErrors((prev) => ({
        ...prev,
        validity_forErr: "Please provide validity period",
      }));
    }

    if (
      !plan_name ||
      !description ||
      !no_of_users ||
      !start_date ||
      !end_date ||
      !fee ||
      !validity_type ||
      !validity_for
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await planRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          closeAddPlanForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      plan_name: "",
      description: "",
      no_of_users: "",
      start_date: "",
      end_date: "",
      fee: "",
      validity_type: "",
      validity_for: "",
    }));
    setErrors((prev) => ({
      ...prev,
      plan_nameErr: "",
      descriptionErr: "",
      no_of_usersErr: "",
      start_dateErr: "",
      end_dateErr: "",
      feeErr: "",
      validity_typeErr: "",
      validity_forErr: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Name</label>
            <input
              style={{
                border: plan_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="plan_name"
              value={plan_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  plan_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Name"
            />
            {plan_nameErr && (
              <Typography className={classes.errorMsg}>
                {plan_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Price</label>
            <input
              style={{
                border: feeErr && "1px solid #d32f2f",
              }}
              type="text"
              name="fee"
              value={fee}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  feeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Price"
            />
            {feeErr && (
              <Typography className={classes.errorMsg}>{feeErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Description</label>
            <input
              style={{
                border: descriptionErr && "1px solid #d32f2f",
              }}
              type="text"
              name="description"
              value={description}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  descriptionErr: "",
                }));
              }}
              className="form-control"
              placeholder="Description"
            />
            {descriptionErr && (
              <Typography className={classes.errorMsg}>
                {descriptionErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Users</label>
            <input
              style={{
                border: no_of_usersErr && "1px solid #d32f2f",
              }}
              type="text"
              name="no_of_users"
              value={no_of_users}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  no_of_usersErr: "",
                }));
              }}
              className="form-control"
              placeholder="No of Users"
            />
            {no_of_usersErr && (
              <Typography className={classes.errorMsg}>
                {no_of_usersErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Start Date</label>
            <input
              style={{
                border: start_dateErr && "1px solid #d32f2f",
              }}
              type="date"
              name="start_date"
              value={start_date}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  start_dateErr: "",
                }));
              }}
              className="form-control"
              placeholder="Start Date"
            />
            {start_dateErr && (
              <Typography className={classes.errorMsg}>
                {start_dateErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>End Date</label>
            <input
              style={{
                border: end_dateErr && "1px solid #d32f2f",
              }}
              type="date"
              name="end_date"
              value={end_date}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  end_dateErr: "",
                }));
              }}
              className="form-control"
              placeholder="End Date"
            />
            {end_dateErr && (
              <Typography className={classes.errorMsg}>
                {end_dateErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Validate Type</label>
            <input
              style={{
                border: validity_typeErr && "1px solid #d32f2f",
              }}
              type="text"
              name="validity_type"
              value={validity_type}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  validity_typeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Validate Type"
            />
            {validity_typeErr && (
              <Typography className={classes.errorMsg}>
                {validity_typeErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Validate For</label>
            <input
              style={{
                border: validity_forErr && "1px solid #d32f2f",
              }}
              type="number"
              name="validity_for"
              value={validity_for}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  validity_forErr: "",
                }));
              }}
              className="form-control"
              placeholder="Validate For"
            />
            {validity_forErr && (
              <Typography className={classes.errorMsg}>
                {validity_forErr}
              </Typography>
            )}
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              plan_nameErr ||
              descriptionErr ||
              no_of_usersErr ||
              start_dateErr ||
              end_dateErr ||
              feeErr ||
              validity_typeErr ||
              validity_forErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};
AddPlan.propTypes = {
  planRegistration: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plan: state.plan,
});

export default connect(mapStateToProps, {
  planRegistration,
})(AddPlan);
