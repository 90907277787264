
import React, { useEffect, useState } from "react";
import { popUpdate } from "../../actions/pop_settings";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";

const EditVendor = ({ popUpdate, getShops, shops: { allShops } }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  
    const [data, setData] = useState({
      shop_id: "",
      email: "",
      password: "",
  });
  const [errors, setErrors] = useState({
      shop_idErr: "",
      emailErr: "",
      passwordErr: "",
  });
  
  const {
      shop_id,
      email,
      password,
  } = data;
  console.log(data)
  const {
      shop_idErr,
      emailErr,
      passwordErr,
  } = errors;
 
 
  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/pop/${params.id}`);
      setData({
        ...data,
        
        email: result?.data?.data?.email,
        password: result?.data?.data?.password,
     
      });
      setLoading(false);
    };
    fetchData();
  }, [params.id]);

  const validate = () => {
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
   
    if (
      !email ||
      !password
      
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        vendor_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, vendor_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, vendor_email: e.target.value }));
      setErrors((prev) => ({ ...prev, vendor_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await popUpdate(params?.id, data);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-pop");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Pop <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                        <div className="row clearfix">
                        <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <div className="form-line">
                                                            <select
                                                                name="shop_id"
                                                                className="custom-select form-control"
                                                                value={shop_id}
                                                                required="required"
                                                                onChange={(e) => {
                                                                    onChangeFields(e);
                                                                    setErrors((prev) => ({
                                                                        ...prev,
                                                                        shop_idErr: "",
                                                                    }));
                                                                }}
                                                            >
                                                                <option value="" disabled={true}>
                                                                    Select an shop
                                                                </option>
                                                                {allShops.map((data, i) => (
                                                                    <option key={i} value={data._id}>
                                                                        {data.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {shop_idErr && (
                                                            <p className="text-danger">{shop_idErr}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-line">
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                name="email"
                                                                value={data?.email}
                                                                onChange={onChangeFields}
                                                                placeholder="Email"
                                                            />
                                                        </div>
                                                        {emailErr && (
                                                            <p className="text-danger">{emailErr}</p>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="form-line">
                                                            <input
                                                                className="form-control"
                                                                type="password"
                                                                name="password"
                                                                autoComplete="off"
                                                                value={data?.password}
                                                                onChange={(e) => {
                                                                    setData((prev) => ({
                                                                        ...prev,
                                                                        password: e.target.value,
                                                                    }));
                                                                    if (Number(e.target.value.length) < 8) {
                                                                        setErrors((prev) => ({
                                                                            ...prev,
                                                                            passwordErr:
                                                                                "Password must be atleast 8 characters",
                                                                        }));
                                                                    } else {
                                                                        setErrors((prev) => ({
                                                                            ...prev,
                                                                            passwordErr: "",
                                                                        }));
                                                                    }
                                                                }}
                                                                placeholder="Password"
                                                            />
                                                        </div>
                                                        {passwordErr && (
                                                            <p className="text-danger">{passwordErr}</p>
                                                        )}
                                                    </div>


                                                    <button
                                                        onClick={onSubmit}
                                                        className="btn btn-primary mt-4"
                                                        style={
                                                            loading ||
                                                                emailErr ||
                                                                passwordErr 
                                                                // shop_idErr
                                                                ? { pointerEvents: "none" }
                                                                : null
                                                        }
                                                    >
                                                        <i className="icon-arrow_forward mr-2"></i>Edit
                                                    </button>
                                                </div>
                        </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditVendor.propTypes = {
  getShops: PropTypes.func.isRequired,
  popUpdate: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pop: state.pop,
  shops: state.shops,
});

export default connect(mapStateToProps, {
  popUpdate,
  getShops,
})(EditVendor);
