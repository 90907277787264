import { LIVE_URl, GET_PLANS, GET_PLAN_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL Plan

export const getPlans = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/plan`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_PLANS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// Plan REGISTRATION

export const planRegistration =
  (planDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = planDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/plan`, body, config);

      if (res) {
        toast.success("Plan Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const planUpdate = (planId, planDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = planDetails;
  try {
    const res = await axios.put(`${LIVE_URl}/plan/${planId}`, body, config);

    if (res) {
      toast.success("Plan Updated Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const getPlanDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/plan/${id}`);
    if (res) {
      dispatch({
        type: GET_PLAN_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// DELETE Plan

export const deletePlan = (planId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/plan/${planId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getPlans(deleteLoaderFunc));
      toast.success("Plan Deleted Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};
