import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSutemSetting } from "../../actions/system_setting";
import axios from "axios";
import { LIVE_URl } from "../../actions/types";

const SystemSetting = ({ updateSutemSetting }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [settinId, setSettingId] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const fetchData = async () => {
    setLoading(true);
    const result = await axios.get(`${LIVE_URl}/settings`);
    setData({
      ...data,
      software_name: result?.data?.data?.software_name,
      version: result?.data?.data?.version,
      released_date: result?.data?.data?.released_date,
      update_log: result?.data?.data?.update_log,
      email: result?.data?.data?.email,
      is_self_register: result?.data?.data?.is_self_register,
    });
    setSettingId(result?.data?.data?._id);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = useState({
    software_name: "",
    version: "",
    released_date: "",
    update_log: "",
    email: "",
    password: "",
    is_self_register: "",
  });
  const [errors, setErrors] = useState({
    software_nameErr: "",
    versionErr: "",
    released_dateErr: "",
    update_logErr: "",
    emailErr: "",
    passwordErr: "",
    is_self_registerErr: "",
  });

  const {
    software_name,
    version,
    released_date,
    update_log,
    email,
    password,
    is_self_register,
  } = data;

  const {
    software_nameErr,
    versionErr,
    released_dateErr,
    update_logErr,
    emailErr,
    passwordErr,
    is_self_registerErr,
  } = errors;

  const validate = () => {
    if (!software_name) {
      setErrors((prev) => ({
        ...prev,
        software_nameErr: "Please provide software name",
      }));
    }
    if (!version) {
      setErrors((prev) => ({
        ...prev,
        versionErr: "Please provide version",
      }));
    }
    if (!released_date) {
      setErrors((prev) => ({
        ...prev,
        released_dateErr: "Please provide released date",
      }));
    }
    if (!update_log) {
      setErrors((prev) => ({
        ...prev,
        update_logErr: "Please provide update log",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!is_self_register) {
      setErrors((prev) => ({
        ...prev,
        is_self_registerErr: "Please provide self registration",
      }));
    }

    if (
      !software_name ||
      !version ||
      !released_date ||
      !update_log ||
      !email ||
      !password ||
      !is_self_register
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await updateSutemSetting(settinId, data, loadingFunc);
        setLoading(false);
        if (response) {
          fetchData();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                System Setting
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="software_name"
                                value={software_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    software_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Software name"
                              />
                            </div>
                            {software_nameErr && (
                              <p className="text-danger">{software_nameErr}</p>
                            )}
                          </div>

                          <h1 className="s-24">Version Details</h1>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="version"
                                value={version}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    versionErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Version"
                              />
                            </div>
                            {versionErr && (
                              <p className="text-danger">{versionErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Released Date</label>
                              <input
                                type="date"
                                name="released_date"
                                value={released_date}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    released_dateErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Content"
                                required="required"
                              />
                            </div>
                            {released_dateErr && (
                              <p className="text-danger">{released_dateErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="update_log"
                                value={update_log}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    update_logErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Update log"
                                required="required"
                              />
                            </div>
                            {update_logErr && (
                              <p className="text-danger">{update_logErr}</p>
                            )}
                          </div>

                          <h1 className="s-24">Pop Setting</h1>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => handleChangeEmail(e)}
                                className="form-control"
                                placeholder="Email"
                                required="required"
                                autoComplete="off"
                              />
                            </div>
                            {emailErr && (
                              <p className="text-danger">{emailErr}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="password"
                                name="password"
                                style={{
                                  border: passwordErr && "1px solid #d32f2f",
                                }}
                                value={password}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                  }));
                                  if (Number(e.target.value.length) < 8) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr:
                                        "Password must be atleast 8 characters",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr: "",
                                    }));
                                  }
                                }}
                                className="form-control"
                                placeholder="Password"
                                required="required"
                                autoComplete="off"
                              />
                            </div>
                            {passwordErr && (
                              <p className="text-danger">{passwordErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Self Registration</label>
                              <select
                                name="is_self_register"
                                className="custom-select form-control"
                                value={is_self_register}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    is_self_registerErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            {is_self_registerErr && (
                              <p className="text-danger">
                                {is_self_registerErr}
                              </p>
                            )}
                          </div>

                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              software_nameErr ||
                              versionErr ||
                              released_dateErr ||
                              update_logErr ||
                              is_self_registerErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SystemSetting.propTypes = {
  updateSutemSetting: PropTypes.func.isRequired,
  system_setting: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  system_setting: state.system_setting,
});

export default connect(mapStateToProps, {
  updateSutemSetting,
})(SystemSetting);
