import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { getAccounts, deleteAccount } from "../../actions/account";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddAccount from "../../components/forms/AddAccount";
import EditAccount from "../../components/forms/EditAccount";

const Accounts = ({
  account: { allAccounts },
  toggleNavbar,
  getAccounts,
  deleteAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [accountData, setAccountData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddAccountForm = () => {
    setToggleForm(true);
  };

  const closeAddAccountForm = () => {
    setToggleForm(false);
  };

  const toggleEditAccountForm = (data) => {
    setAccountData(data);
    setToggleEditForm(true);
  };

  const closeEditAccountForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getAccounts(loadingFunc);
  }, [getAccounts]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Account MODAL

  const openDeleteAccountModal = (data) => {
    setAccountData(data);
    setDeleteAccountModal(true);
  };

  const closeDeleteAccountModal = () => setDeleteAccountModal(false);

  const deleteAccountFunc = async (e) => {
    e.preventDefault();
    let res = await deleteAccount(accountData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteAccountModal();
      setAccountData("");
    }
  };

  return (
    <>
      {/* ADD ACCOUNT MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddAccountForm}
        bodyContent={<AddAccount closeAddAccountForm={closeAddAccountForm} />}
        title="Add Accounts"
      />

      {/* EDIT ACCOUNT MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditAccountForm}
        bodyContent={
          <EditAccount
            closeEditAccountForm={closeEditAccountForm}
            accountData={accountData}
          />
        }
        title="Edit Accounts"
      />

      <DeleteModal
        show={showDeleteAccountModal}
        onHide={closeDeleteAccountModal}
        title="Delete Accounts"
        bodyText="Are you sure, you want to delete the account?"
        onClickNo={closeDeleteAccountModal}
        onClickYes={(e) => deleteAccountFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Accounts Registration
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <a
                        onClick={(e) => toggleAddAccountForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Accounts
                      </a>
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allAccounts?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Currency</th>
                              <th className="text-left">Number</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allAccounts?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">{data?.currency}</td>
                                <td className="text-left">{data?.number}</td>
                                <td>
                                  <FaEdit
                                    onClick={() => toggleEditAccountForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  |
                                  <AiFillDelete
                                    onClick={() => openDeleteAccountModal(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

Accounts.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  getAccounts,
  deleteAccount,
})(Accounts);
