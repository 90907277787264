import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const Nav = styled.div`
  background: #0d6efd;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NewSidebar = ({ changeToggle, toggleNavbar, logout }) => {
  const navigateTo = useNavigate();

  const logOut = (e) => {
    e.preventDefault();
    logout(navigateTo);
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <NavIcon to="#">
              <FaIcons.FaBars
                onClick={changeToggle}
                style={{
                  fontSize: "24px",
                }}
              />
            </NavIcon>
            <h1
              style={{
                textAlign: "center",
                marginLeft: toggleNavbar ? "215px" : "20px",
                marginBottom: "0",
                color: "#fff",
                transition: "all .3s ease",
                fontSize: "24px",
              }}
            >
              Pharmacy
            </h1>
          </div>

          <Dropdown
            style={{ color: "#fff" }}
            as="ul"
            className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right m-0 p-0"
          >
            <li>
              <Dropdown.Toggle
                as="a"
                className="nav-link remove-caret custom-toggle"
              >
                {sessionStorage.getItem("user_data") ? (
                  <span>
                    {
                      JSON.parse(sessionStorage.getItem("user_data"))?.user
                        ?.name
                    }
                  </span>
                ) : (
                  "Username"
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="user-size dropdown-menu p-0">
                <li onClick={(e) => logOut(e)}>
                  <Dropdown.Item className="text-center" href="!#">
                    Logout
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </li>
          </Dropdown>
        </Nav>
        <SidebarNav sidebar={toggleNavbar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose
                style={{ color: "#000", fontSize: "24px" }}
                onClick={changeToggle}
              />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

NewSidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(NewSidebar);
