import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteVendor } from "../../actions/vendor";
import { getVendor } from "../../actions/vendor";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEye, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddVendor from "../../components/forms/AddVendor";
import EditVendor from "../../components/forms/EditVendor";

const Vendor = ({
  vendor: { allVendors },
  toggleNavbar,
  getVendor,
  deleteVendor,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteVendorModal, setDeleteVendorModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [vendorData, setVendorData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddVendorForm = () => {
    setToggleForm(true);
  };

  const closeAddVendorForm = () => {
    setToggleForm(false);
  };

  const toggleEditVendorForm = (data) => {
    setVendorData(data);
    setToggleEditForm(true);
  };

  const closeEditVendorForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getVendor(loadingFunc);
  }, [getVendor]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Vendor MODAL

  const openDeleteVendorModal = (data) => {
    setVendorData(data);
    setDeleteVendorModal(true);
  };

  const closeDeleteVendorModal = () => setDeleteVendorModal(false);

  const deleteVendorFunc = async (e) => {
    e.preventDefault();
    let res = await deleteVendor(vendorData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteVendorModal();
      setVendorData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteVendorModal}
        onHide={closeDeleteVendorModal}
        title="Delete Vendor"
        bodyText="Are you sure, you want to delete the vendor?"
        onClickNo={closeDeleteVendorModal}
        onClickYes={(e) => deleteVendorFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Vendor Listing
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <a
                        onClick={(e) => toggleAddVendorForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add Vendor
                      </a>
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allVendors?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Email</th>
                              <th className="text-left">Phone No</th>
                              <th className="text-left">City</th>
                              <th
                                style={{ width: "160px", textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                            {allVendors?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">
                                  {data?.vendor_email}
                                </td>
                                <td className="text-left">
                                  {data?.vendor_phone}
                                </td>
                                <td className="text-left">{data?.city}</td>
                                <td className="text-center">
                                  {/* <FaEye
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  | */}
                                  <FaEdit
                                    onClick={() => toggleEditVendorForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                  |
                                  <AiFillDelete
                                    onClick={() => openDeleteVendorModal(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* ADD Vendor MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddVendorForm}
        bodyContent={<AddVendor closeAddVendorForm={closeAddVendorForm} />}
        title="Add Vendor"
      />

      {/* EDIT Vendor MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditVendorForm}
        bodyContent={
          <EditVendor
            closeEditVendorForm={closeEditVendorForm}
            vendorData={vendorData}
          />
        }
        title="Edit Vendor"
      />
    </>
  );
};

Vendor.propTypes = {
  getVendor: PropTypes.func.isRequired,
  deleteVendor: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vendor: state.vendor,
});

export default connect(mapStateToProps, {
  getVendor,
  deleteVendor,
})(Vendor);
