import React, { useEffect, useState } from "react";
import { deleteCustomer } from "../../actions/customer";
import { getCustomer } from "../../actions/customer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import { Link } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AllCustomer = ({
  customer: { allCustomers },
  getCustomer,
  deleteCustomer,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteCustomerModal, setDeleteCustomerModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [customerData, setCustomerData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getCustomer(loadingFunc);
  }, [getCustomer]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Customer MODAL

  const openDeleteCustomerModal = (data) => {
    setCustomerData(data);
    setDeleteCustomerModal(true);
  };

  const closeDeleteCustomerModal = () => setDeleteCustomerModal(false);

  const deleteCustomerFunc = async (e) => {
    e.preventDefault();
    let res = await deleteCustomer(customerData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteCustomerModal();
      setCustomerData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteCustomerModal}
        onHide={closeDeleteCustomerModal}
        title="Delete Customer"
        bodyText="Are you sure, you want to delete the customer?"
        onClickNo={closeDeleteCustomerModal}
        onClickYes={(e) => deleteCustomerFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Customer Name <span className="s-14">Registration No</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {
                            hasPermission('add-customer') &&  <Link
                      to="/dashboard/add-customer"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Customer
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allCustomers?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Customer Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Phone No</th>
                            <th className="text-left">City</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allCustomers?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">
                                {data?.customer_email}
                              </td>
                              <td className="text-left">
                                {data?.customer_phone}
                              </td>
                              <td className="text-left">{data?.city}</td>
                              <td className="text-right">
                                {/* <a href="bank_add_account_bussiness.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
                                | */}
                                 {
                            hasPermission('edit-customer') &&
                                <Link
                                  to={`/dashboard/edit-customer/${data?._id}`}
                                > <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                                 }
                                |
                                {
                            hasPermission('delete-customer') &&
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeleteCustomerModal(data)}
                                ></i>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllCustomer.propTypes = {
  getCustomer: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps, {
  getCustomer,
  deleteCustomer,
})(AllCustomer);
