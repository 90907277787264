import React, { useEffect, useState } from "react";
import { updateBank } from "../../actions/bank";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";
const EditVendor = ({ updateBank, getShops,   bank: { allBanks }, }) => { 
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    // bank_name: "",
    code: "",
    city: "",
    country: "",
    is_trained: "",
  });
  const [errors, setErrors] = useState({
    bank_nameErr: "",
    codeErr: "",
    cityErr: "",
    countryErr: "",
    is_trainedErr: "",
  });
  const {
    // bank_name,
    code,         
    city,
   country,
   is_trained
  } = data;
console.log(data)
  const {
    bank_nameErr,
    codeErr,
    cityErr,
    countryErr,
    is_trainedErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);
  const [putRequestData, setPutRequestData] = useState();
  const [id, setId] = useState('');
  // const [data, setData] = useState('');


  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const result = await axios.get(`${LIVE_URl}/bank/update/${params.id}`);
  //     setData(result?.data?.data);
  //     console.log(data)
  //     setLoading(false);
     
  //   };
  //   fetchData();
  // }, [params.id]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/bank/update/${params.id}`);
      setData({
        ...data,
                 name:result.data.name,
                    code: result?.data?.code,
                    city: result?.data?.city,
                    country: result?.data?.country,
                    is_trained: result?.data?.is_trained,
      });
      setLoading(false);
   
    };
    fetchData();
  }, [params.id]);
  console.log(data?.data?.data?.code)
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     console.log(putRequestData);  
  //     const result = await axios.get(`${LIVE_URl}/bank/update`); 
  //     console.log("dddddd",result)
  //     setData({
  //             ...data,
  //             name:result.data.name,
  //             code: result?.data?.code,
  //             city: result?.data?.city,
  //             country: result?.data?.country,
  //             is_trained: result?.data?.is_trained,
  //             _id:params?.id    
  //           });
  //     setLoading(false);
     
  //   };
  //   fetchData();
  // }, [params.id]);


  const validate = () => {
    // if (!bank_name) {
    //   setErrors((prev) => ({
    //     ...prev,
    //     bank_nameErr: "Please provide bank name",
    //   }));
    // }
    if (!code) {
      setErrors((prev) => ({
        ...prev,
        codeErr: "Please provide code",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!is_trained) {
      setErrors((prev) => ({
        ...prev,
        is_trainedErr: "Please select status",
      }));
    }

    if (
      // !bank_name ||
      !code ||
      !city ||
      !country ||
      !is_trained
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };
 
  const onSubmit = async (e) => {
    
    e.preventDefault();
    if (validate()) {
      try {
        let response = updateBank(params?.id, data);
        if (response) {
          navigate("/dashboard/all-banks");
      
        }
      } catch (err) {}
    }
  };
  console.log(data)

  console.log(updateBank)
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit bank <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                        <div className="col-sm-12">
                          {/* <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="name"
                                value={bank_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    bank_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank name"
                              />
                               
                            </div>
                            {bank_nameErr && (
                              <p className="text-danger">{bank_nameErr}</p>
                            )}
                          </div> */}

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="code"
                                value={data?.code}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    codeErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank code"
                              />
                            </div>
                            {codeErr && (
                              <p className="text-danger">{codeErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="country"
                                value={data?.country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    countryErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Country"
                                required="required"
                              />
                            </div>
                            {countryErr && (
                              <p className="text-danger">{countryErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="city"
                                value={data?.city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    cityErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="City"
                                required="required"
                              />
                            </div>
                            {cityErr && (
                              <p className="text-danger">{cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="is_trained"
                                value={data?.is_trained}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    is_trainedErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Trained"
                                required="required"
                              />
                            </div>
                            {is_trainedErr && (
                              <p className="text-danger">{is_trainedErr}</p>
                            )}
                          </div>
                          {
                            hasPermission('edit-bank') &&
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              bank_nameErr ||
                              codeErr ||
                              cityErr ||
                              countryErr ||
                              is_trainedErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
}
                        </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditVendor.propTypes = {
  getShops: PropTypes.func.isRequired,
  updateBank: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vendor: state.vendor,
  shops: state.shops,
  bank:state.bank
});

export default connect(mapStateToProps, {
  updateBank,
  getShops,
})(EditVendor);
