import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../actions/role";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../../components/global/Loader";

const RolesAndPermissionAssignment = ({ role: { allUsers }, getAllUsers }) => {
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getAllUsers(loadingFunc);
  }, [getAllUsers]);
  console.log(allUsers)
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Roles and Permissions Assignment
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allUsers?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">User Name</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Roles</th>
                            <th className="text-left">Permissions</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allUsers?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.username}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">{data?.email}</td>
                              <td className="text-left">
                                {data?.roles?.length}
                              </td>
                              <td className="text-left">
                                {data?.permissions?.length}
                              </td>
                              <td className="text-center">
                                <Link to={`/dashboard/edit-user/${data?._id}`}>
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

RolesAndPermissionAssignment.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  getAllUsers,
})(RolesAndPermissionAssignment);
