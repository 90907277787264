import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteStaff, getStaff } from "../../actions/staff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddBusiness from "../../components/forms/AddBusiness";
import { FaEye, FaEdit, FaUserPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import EditBusiness from "../../components/forms/EditBusiness";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import AddStaff from "../../components/forms/AddStaff";
import EditStaff from "../../components/forms/EditStaff";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";
const Staff = ({
  staff: { allStaffs },
  toggleNavbar,
  getStaff,
  deleteStaff,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteStaffModal, setDeleteStaffModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [staffData, setStaffData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddStaffForm = () => {
    setToggleForm(true);
  };

  const closeAddStaffForm = () => {
    setToggleForm(false);
  };

  const toggleEditStaffForm = (data) => {
    setStaffData(data);
    setToggleEditForm(true);
  };

  const closeEditStaffForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getStaff(loadingFunc);
  }, [getStaff]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE STAFF MODAL

  const openDeleteStaffModal = (data) => {
    setStaffData(data);
    setDeleteStaffModal(true);
  };

  const closeDeleteStaffModal = () => setDeleteStaffModal(false);

  const deleteStaffFunc = async (e) => {
    e.preventDefault();
    let res = await deleteStaff(staffData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteStaffModal();
      setStaffData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteStaffModal}
        onHide={closeDeleteStaffModal}
        title="Delete Staff"
        bodyText="Are you sure, you want to delete the staff?"
        onClickNo={closeDeleteStaffModal}
        onClickYes={(e) => deleteStaffFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Staff Listing
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    > 
                         {hasPermission('add-staff') &&  <a
                        onClick={(e) => toggleAddStaffForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Staff Member
                      </a>}
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allStaffs?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Email</th>
                              <th className="text-left">Type</th>
                              <th
                                style={{ width: "160px", textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                            {allStaffs?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.user?.name}</td>
                                <td className="text-left">
                                  {data?.user?.email}
                                </td>
                                <td className="text-left">
                                  {data?.user?.type}
                                </td>
                                <td className="text-center">
                                {hasPermission('view-staff') &&   <FaEye
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />}

                                  |
                                  {hasPermission('edit-staff') && 
                                  <FaEdit
                                    onClick={() => toggleEditStaffForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                            }
                                  |
                                  {hasPermission('delete-staff') && 
                                  <AiFillDelete
                                    onClick={() => openDeleteStaffModal(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* ADD STAFF MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddStaffForm}
        bodyContent={<AddStaff closeAddStaffForm={closeAddStaffForm} />}
        title="Add Staff"
      />

      {/* EDIT STAFF MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditStaffForm}
        bodyContent={
          <EditStaff
            closeEditStaffForm={closeEditStaffForm}
            staffData={staffData}
          />
        }
        title="Edit Staff"
      />
    </>
  );
};

Staff.propTypes = {
  getStaff: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staff,
});

export default connect(mapStateToProps, {
  getStaff,
  deleteStaff,
})(Staff);
