import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import {  } from "../../actions/shops";
import { getEmails,deleteEmail } from "../../actions/emails";
import Loader from "../../components/global/Loader";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";

const AllShops = ({ emails: { allEmails }, getEmails,deleteEmail }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteShopModal, setDeleteShopModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [emailData, setEmailData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getEmails(loadingFunc);
  }, [getEmails]);
console.log(allEmails)
  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE SHOP MODAL

  const openDeleteShopModal = (data) => {
    setEmailData(data);
    setDeleteShopModal(true);
  };

  const closeDeleteShopModal = () => setDeleteShopModal(false);

  const deleteShopFunc = async (e) => {
    e.preventDefault();
    let res = await deleteEmail(emailData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteShopModal();
      setEmailData("");
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeleteShopModal}
        onHide={closeDeleteShopModal}
        title="Delete Shop"
        bodyText="Are you sure, you want to delete the shop?"
        onClickNo={closeDeleteShopModal}
        onClickYes={(e) => deleteShopFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Email List<span className="s-14 ps-2">Today List</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allEmails?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                          <th style={{ width: "10px" }}>#</th>
                             <th className="text-left">Date</th>
                            <th className="text-left">Email Title</th>
                             <th className="text-left">Sender</th>
                             <th className="text-left">Status</th>
                             <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allEmails?.map((emailData, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">
                              {emailData?.created_at}
                                {/* {shopsData?.name} <br />
                                <small> {shopsData?.address}</small> */}
                              </td>
                              <td className="text-left">
                              {emailData?.subject}
                                {/* {shopsData?.shop_email} */}
                              </td>
                              <td className=" text-left"> {emailData?.sender} </td>
                              <td className="text-success text-left font-weight-bold">converted</td>
                              <td className="text-center">

                              <Link
                              to={`/dashboard/emailview/${emailData?._id}`}
                                >
                                         <i className="s-24 icon-eye"> </i>{" "}
                                </Link>
                             
{/*                                
                                |
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeleteShopModal(emailData)}
                                  style={{ cursor: "pointer" }}
                                ></i> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllShops.propTypes = {
  getEmails: PropTypes.func.isRequired,
  emails: PropTypes.object.isRequired,
  deleteEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  emails: state.emails,
});

export default connect(mapStateToProps, {
  getEmails,
  deleteEmail
})(AllShops);



