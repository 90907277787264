import { GET_CUSTOMER, GET_CUSTOMER_DETAILS } from "../actions/types";

const initialState = {
  allCustomers: [],
  customerDetails: null,
};

export default function customer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMER:
      return {
        ...state,
        allCustomers: payload,
      };
    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: payload,
      };
    default:
      return state;
  }
}
