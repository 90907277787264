import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { shopsRegistration } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const AddShop = ({
  closeAddShopForm,
  shopsRegistration,
  getBusinesses,
  business: { allBusiness },
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    shop_name: "",
    contact_number: "",
    shop_email: "",
    address: "",
    vat: "",
    about: "",
    shop_image: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    city: "Lahore",
    country: "Pakistan",
    business_id: "",
  });
  const [errors, setErrors] = useState({
    shop_nameErr: "",
    shop_emailErr: "",
    contact_numberErr: "",
    addressErr: "",
    vatErr: "",
    aboutErr: "",
    shop_imageErr: "",
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    passwordErr: "",
    business_idErr: "",
  });

  const {
    shop_name,
    contact_number,
    shop_email,
    address,
    vat,
    about,
    shop_image,
    first_name,
    last_name,
    email,
    password,
    business_id,
  } = data;

  const {
    shop_nameErr,
    shop_emailErr,
    contact_numberErr,
    addressErr,
    vatErr,
    aboutErr,
    shop_imageErr,
    first_nameErr,
    last_nameErr,
    emailErr,
    passwordErr,
    business_idErr,
  } = errors;

  useEffect(() => {
    getBusinesses(loadingFunc);
  }, [getBusinesses]);

  const validate = () => {
    if (!shop_name) {
      setErrors((prev) => ({
        ...prev,
        shop_nameErr: "Please provide shop name",
      }));
    }
    if (!contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!shop_email) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: "Please provide shop email",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide shop address",
      }));
    }
    if (!vat) {
      setErrors((prev) => ({
        ...prev,
        vatErr: "Please provide shop vat",
      }));
    }
    if (!about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!shop_image) {
      setErrors((prev) => ({
        ...prev,
        shop_imageErr: "Please provide shop image",
      }));
    }
    if (!first_name) {
      setErrors((prev) => ({
        ...prev,
        first_nameErr: "Please provide first name",
      }));
    }
    if (!last_name) {
      setErrors((prev) => ({
        ...prev,
        last_nameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }
    if (!business_id) {
      setErrors((prev) => ({
        ...prev,
        business_idErr: "Please provide business",
      }));
    }

    if (
      !shop_name ||
      !contact_number ||
      !shop_email ||
      !address ||
      !vat ||
      !about ||
      !shop_image ||
      !first_name ||
      !last_name ||
      !email ||
      !password ||
      !business_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeShopEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      setErrors((prev) => ({ ...prev, shop_emailErr: "" }));
    }
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("first");
      try {
        let response = await shopsRegistration(data);
        setLoading(false);
        if (response) {
          closeAddShopForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      shop_name: "",
      contact_number: "",
      shop_email: "",
      address: "",
      vat: "",
      about: "",
      shop_image: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      city: "",
      country: "",
    }));
    setErrors((prev) => ({
      ...prev,
      shop_nameErr: "",
      shop_emailErr: "",
      contact_numberErr: "",
      addressErr: "",
      vatErr: "",
      aboutErr: "",
      shop_imageErr: "",
      first_nameErr: "",
      last_nameErr: "",
      emailErr: "",
      passwordErr: "",
      cityErr: "",
      countryErr: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Shop Name</label>
            <input
              style={{
                border: shop_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="shop_name"
              value={shop_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  shop_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop name"
            />
            {shop_nameErr && (
              <Typography className={classes.errorMsg}>
                {shop_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Shop email</label>
            <input
              type="text"
              name="shop_email"
              style={{
                border: shop_emailErr && "1px solid #d32f2f",
              }}
              value={shop_email}
              onChange={(e) => handleChangeShopEmail(e)}
              className="form-control"
              placeholder="Shop email"
              required="required"
            />
            {shop_emailErr && (
              <Typography className={classes.errorMsg}>
                {shop_emailErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              style={{
                border: contact_numberErr && "1px solid #d32f2f",
              }}
              value={contact_number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  contact_numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="Contact number"
              required="required"
            />
            {contact_numberErr && (
              <Typography className={classes.errorMsg}>
                {contact_numberErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Shop Vat</label>
            <input
              type="text"
              name="vat"
              style={{
                border: vatErr && "1px solid #d32f2f",
              }}
              value={vat}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  vatErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop vat"
              required="required"
            />
            {vatErr && (
              <Typography className={classes.errorMsg}>{vatErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Address</label>
            <input
              type="text"
              name="address"
              style={{
                border: addressErr && "1px solid #d32f2f",
              }}
              value={address}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  addressErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop address"
              required="required"
            />
            {addressErr && (
              <Typography className={classes.errorMsg}>{addressErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>About</label>
            <textarea
              type="text"
              name="about"
              style={{ border: errors?.aboutErr && "1px solid #d32f2f" }}
              value={about}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  aboutErr: "",
                }));
              }}
              className="form-control"
              placeholder="About"
              required="required"
            />
            {aboutErr && (
              <Typography className={classes.errorMsg}>{aboutErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Shop Logo</label>
            <input
              type="file"
              name="shop_image"
              style={{
                border: shop_imageErr && "1px solid #d32f2f",
              }}
              value={shop_image}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  shop_imageErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop image"
              required="required"
            />
            {shop_imageErr && (
              <Typography className={classes.errorMsg}>
                {shop_imageErr}
              </Typography>
            )}
          </Col>
        </Row>

        <hr style={{ marginTop: "30px" }} />
        <h1 className="s-24">Manager Information</h1>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>First Name</label>
            <input
              style={{ border: first_nameErr && "1px solid #d32f2f" }}
              type="text"
              name="first_name"
              value={first_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  first_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="First name"
            />
            {first_nameErr && (
              <Typography className={classes.errorMsg}>
                {first_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              style={{ border: last_nameErr && "1px solid #d32f2f" }}
              value={last_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  last_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Last name"
              required="required"
            />
            {last_nameErr && (
              <Typography className={classes.errorMsg}>
                {last_nameErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Email</label>
            <input
              type="text"
              name="email"
              style={{ border: emailErr && "1px solid #d32f2f" }}
              value={email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Email"
              required="required"
            />
            {emailErr && (
              <Typography className={classes.errorMsg}>{emailErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Password</label>
            <input
              type="password"
              name="password"
              style={{ border: passwordErr && "1px solid #d32f2f" }}
              value={password}
              onChange={(e) => {
                setData((prev) => ({ ...prev, password: e.target.value }));
                if (Number(e.target.value.length) < 8) {
                  setErrors((prev) => ({
                    ...prev,
                    passwordErr: "Password must be atleast 8 characters",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    passwordErr: "",
                  }));
                }
              }}
              className="form-control"
              placeholder="Password"
              required="required"
            />

            {passwordErr && (
              <Typography className={classes.errorMsg}>
                {passwordErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Business</label>
            <div className="select">
              <select
                name="business_id"
                style={{ border: business_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={business_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    business_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an business
                </option>
                {allBusiness.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {business_idErr && (
                <Typography className={classes.errorMsg}>
                  {business_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              shop_nameErr ||
              shop_emailErr ||
              contact_numberErr ||
              addressErr ||
              vatErr ||
              aboutErr ||
              shop_imageErr ||
              first_nameErr ||
              last_nameErr ||
              emailErr ||
              passwordErr ||
              business_idErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};

AddShop.propTypes = {
  shopsRegistration: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  business: state.business,
});

export default connect(mapStateToProps, {
  shopsRegistration,
  getBusinesses,
})(AddShop);
