import { LIVE_URl, GET_EMAILS,GET_EMAIL_DETAILS  } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL SHOPS

export const getEmails = (loadingFunc) => async (dispatch,data) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/email-pipe`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_EMAILS,
        payload: res?.data?.data,
      });
      console.log(data)
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

export const getEmailDetails = (id, loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/email-pipe/${id}`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_EMAIL_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};
// export const emailUpdate =
//   (emailId, emailDetails, loadingFunc) => async (dispatch) => {
//     const config = {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//     };
//     const body = emailDetails;
//     try {
//       const res = await axios.put(`${LIVE_URl}/pop/${emailId}`, body, config);
//       toast.success(res?.message);
//       return res;
//     } catch (error) {
//       toast.error(error.response.data.message);
//       return false;
//     }
//   };
  export const deleteEmail = (emailId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(`${LIVE_URl}/email-pipe/${emailId}`, config);
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getEmails());
        toast.success("Email Delete Successfully");
      }
  
      return res;
    } catch (error) {
      deleteLoaderFunc(false);
  
      toast.error(error.response.data.message);
      return false;
    }
  };
  