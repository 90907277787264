// GET ALL COURSES

import {
  LIVE_URl,
  GET_ROLES,
  GET_ROLE_DETAILS,  GET_PERMISSIONS,
  GET_ALL_USERS,
  GET_USER_INFO,
  GET_ROLE_INFO,
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL STAFF

export const getRoles = (loadingFunc) => async (dispatch) => {
  loadingFunc(true);
  try {
    const res = await axios.get(`${LIVE_URl}/get-all-roles`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_ROLES,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// GET ALL STAFF

export const getPermission = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/get-all-permissions`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_PERMISSIONS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

export const getAllUsers = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/get-all-users`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_ALL_USERS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

export const getUserInfo = (userId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${LIVE_URl}/get-user-info`,
      {
        user_id: userId,
      },
      config
    );
    if (res) {
      dispatch({
        type: GET_USER_INFO,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// ADD ROLE

export const roleRegistration = (name, loadingFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  loadingFunc(true);
  try {
    const res = await axios.post(
      `${LIVE_URl}/create-role`,
      { name: name },
      config
    );
    if (res) {
      loadingFunc(false);
      toast.success("Role Added Successfully");
    }
    return res;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};

export const roleInfo = (roleId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${LIVE_URl}/get-role-info`,
      { role_id: roleId },
      config
    );

    if (res) {
      dispatch({
        type: GET_ROLE_INFO,
        payload: res?.data?.data,
      });
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const deleteRole = (roleID, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.post(
      `${LIVE_URl}/delete-role`,
      { role_id: roleID },
      config
    );
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getRoles(deleteLoaderFunc));
      toast.success("Role Deleted Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};

export const permissionRegistration =
  (name, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    loadingFunc(true);
    try {
      const res = await axios.post(
        `${LIVE_URl}/create-permission`,
        { name: name },
        config
      );

      if (res) {
        loadingFunc(false);
        toast.success("Permission Added Successfully");
      }
      return res;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };

export const assignPermissionToRole = (roleId, data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${LIVE_URl}/assign-permission-to-role`,
      { role_id: roleId, permissions_array: data },
      config
    );

    if (res) {
      toast.success("Permissions assigned Successfully");
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const updatePermission =
  (permissionId, name, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    loadingFunc(true);
    try {
      const res = await axios.post(
        `${LIVE_URl}/update-permission`,
        { permission_id: permissionId, name: name },
        config
      );

      if (res) {
        loadingFunc(false);
        toast.success("Permissions Updated Successfully");
      }
      return res;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
  export const getRoleDetails = (id, loadingFunc) => async (dispatch) => {
    try {
      loadingFunc(true);
      const res = await axios.get(`${LIVE_URl}/get-all-roles/${id}`,);
      if (res) {
        loadingFunc(false);
        dispatch({
          type: GET_ROLE_DETAILS,
          payload: res?.data?.data,
        });
      }
  
      return true;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
    }
  };
  export const updateRole = (roleID, name,loadingFunc,id) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
    };
    try {
      
      const res = await axios.put(
        `${LIVE_URl}/update-role/${roleID}`,
        { name: name },
        // { role_id: roleID, name: name},
        config
      );
      if (res) {
        loadingFunc(false);
        toast.success("Role  Updated Successfully");
      }
  
      return res;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };

export const deletePermission =
  (permissionId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.post(
        `${LIVE_URl}/delete-permission`,
        { permission_id: permissionId },
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getPermission(deleteLoaderFunc));
        toast.success("Permission Deleted Successfully");
      }

      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };

export const assignPermissionAndRoleToUSer =
  (user_id, selectedOptions, selectedRole) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${LIVE_URl}/assign-permission-and-role-to-user`,
        {
          user_id: user_id,
          roles_array: selectedRole,
          permissions_array: selectedOptions,
        },
        config
      );

      if (res) {
        toast.success("Permissions and role assigned Successfully");
      }
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };
