import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { getShops } from "../../actions/shops";
import { getBanks } from "../../actions/bank";
import { getVendor } from "../../actions/vendor";
import { getCustomer } from "../../actions/customer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { documentRegistration } from "../../actions/document";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const AddDocument = ({
  closeAddDocumentForm,
  documentRegistration,
  getShops,
  getBanks,
  getVendor,
  getCustomer,
  shops: { allShops },
  bank: { allBanks },
  vendor: { allVendors },
  customer: { allCustomers },
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShops(loadingFunc);
    getBanks(loadingFunc);
    getVendor(loadingFunc);
    getCustomer(loadingFunc);
  }, [getShops, getBanks, getVendor, getCustomer]);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    document_name: "",
    document_type: "",
    document_type_id: "",
    customer_id: "",
    vendor_id: "",
    bank_id: "",
    shop_id: "",
    file_link: "",
  });
  const [errors, setErrors] = useState({
    document_nameErr: "",
    document_typeErr: "",
    document_type_idErr: "",
    customer_idErr: "",
    vendor_idErr: "",
    bank_idErr: "",
    shop_idErr: "",
    file_linkErr: "",
  });

  const {
    document_name,
    document_type,
    document_type_id,
    customer_id,
    vendor_id,
    bank_id,
    shop_id,
    file_link,
  } = data;

  const {
    document_nameErr,
    document_typeErr,
    document_type_idErr,
    customer_idErr,
    vendor_idErr,
    bank_idErr,
    shop_idErr,
    file_linkErr,
  } = errors;

  const validate = () => {
    if (!document_name) {
      setErrors((prev) => ({
        ...prev,
        document_nameErr: "Please provide document name",
      }));
    }
    if (!document_type) {
      setErrors((prev) => ({
        ...prev,
        document_typeErr: "Please provide document type",
      }));
    }
    // if (!document_type_id) {
    //   setErrors((prev) => ({
    //     ...prev,
    //     document_type_idErr: "Please provide document type id",
    //   }));
    // }
    if (!customer_id) {
      setErrors((prev) => ({
        ...prev,
        customer_idErr: "Please provide customer",
      }));
    }
    if (!vendor_id) {
      setErrors((prev) => ({
        ...prev,
        vendor_idErr: "Please provide vendor",
      }));
    }
    if (!bank_id) {
      setErrors((prev) => ({
        ...prev,
        bank_idErr: "Please provide bank",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!file_link) {
      setErrors((prev) => ({
        ...prev,
        file_linkErr: "Please select file link",
      }));
    }

    if (
      !document_name ||
      !document_type ||
      // !document_type_id ||
      !customer_id ||
      !vendor_id ||
      !bank_id ||
      !shop_id ||
      !file_link
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    setErrors((prev) => ({
      ...prev,
      file_linkErr: "",
    }));
    setData({ ...data, file_link: file });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await documentRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          closeAddDocumentForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      document_name: "",
      sample_document: "",
      shop_id: "",
    }));
    setErrors((prev) => ({
      ...prev,
      document_nameErr: "",
      sample_documentErr: "",
      shop_idErr: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Document Name</label>
            <input
              style={{
                border: document_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="document_name"
              value={document_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  document_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Document name"
            />
            {document_nameErr && (
              <Typography className={classes.errorMsg}>
                {document_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Document Type</label>
            <div className="select">
              <select
                name="document_type"
                style={{ border: document_typeErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={document_type}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    document_typeErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an type
                </option>
                <option value="App\Models\Customer">App\Models\Customer</option>
                <option value="App\Models\Vendor">App\Models\Vendor</option>
                <option value="App\Models\Bank">App\Models\Bank</option>
              </select>
              {document_typeErr && (
                <Typography className={classes.errorMsg}>
                  {document_typeErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Shop</label>
            <div className="select">
              <select
                name="shop_id"
                style={{ border: shop_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={shop_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    shop_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an shop
                </option>
                {allShops.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {shop_idErr && (
                <Typography className={classes.errorMsg}>
                  {shop_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Vendor</label>
            <div className="select">
              <select
                name="vendor_id"
                style={{ border: vendor_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={vendor_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    vendor_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an vendor
                </option>
                {allVendors.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {vendor_idErr && (
                <Typography className={classes.errorMsg}>
                  {vendor_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Customer</label>
            <div className="select">
              <select
                name="customer_id"
                style={{ border: customer_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={customer_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    customer_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an customer
                </option>
                {allCustomers.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {customer_idErr && (
                <Typography className={classes.errorMsg}>
                  {customer_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Bank</label>
            <div className="select">
              <select
                name="bank_id"
                style={{ border: bank_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={bank_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    bank_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an bank
                </option>
                {allBanks.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {bank_idErr && (
                <Typography className={classes.errorMsg}>
                  {bank_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>File</label>
            <input
              style={{
                border: file_linkErr && "1px solid #d32f2f",
              }}
              type="file"
              name="file_link"
              onChange={(e) => {
                onChangeFile(e);
                setErrors((prev) => ({
                  ...prev,
                  file_linkErr: "",
                }));
              }}
              className="form-control"
              placeholder="File"
            />
            {file_linkErr && (
              <Typography className={classes.errorMsg}>
                {file_linkErr}
              </Typography>
            )}
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading || document_nameErr || shop_idErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};
AddDocument.propTypes = {
  documentRegistration: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  getVendor: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  bank: state.bank,
  vendor: state.vendor,
  customer: state.customer,
});

export default connect(mapStateToProps, {
  getShops,
  getVendor,
  getBanks,
  getCustomer,
  documentRegistration,
})(AddDocument);
