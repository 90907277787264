import { GET_BUYER, GET_BUYER_DETAILS } from "../actions/types";

const initialState = {
  allBuyer: [],
  buyerDetails: null,
};

export default function buyer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BUYER:
      return {
        ...state,
        allBuyer: payload,
      };
    case GET_BUYER_DETAILS:
      return {
        ...state,
        buyerDetails: payload,
      };
    default:
      return state;
  }
}
