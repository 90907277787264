import React from "react";
import "../../assets/css/loader.css";

const Loader = () => {
  return (
    <>
      <div className="lds-hourglass"></div>
    </>
  );
};

export default Loader;
