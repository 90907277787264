import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPop,deletePop } from "../../actions/pop_settings";
import Loader from "../../components/global/Loader";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";

const AllPop = ({ pop: { allPops }, getPop,deletePop }) => {
  const [loading, setLoading] = useState(false);
  const [showDeletePopModal, setDeletePopModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [popData, setPopData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getPop(loadingFunc);
  }, [getPop]);

console.log(allPops)

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE SHOP MODAL

  const openDeletePopModal = (data) => {
    setPopData(data);
    setDeletePopModal(true);
  };

  const closeDeletePopModal = () => setDeletePopModal(false);

  const deletePopFunc = async (e) => {
    e.preventDefault();
    let res = await deletePop(popData?._id, deleteLoaderFunc);
    if (res) {
      closeDeletePopModal();
      setPopData("");
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeletePopModal}
        onHide={closeDeletePopModal}
        title="Delete Pop"
        bodyText="Are you sure, you want to delete the pop?"
        onClickNo={closeDeletePopModal}
        onClickYes={(e) => deletePopFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          /> 
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                All Pops <span className="s-14 ps-2"></span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allPops?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                          <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Shop</th>
                             <th className="text-left">Email</th>                         
                                 <th className="text-left">Password</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allPops?.map((popData, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">
                              {popData?.shop?.name}
                               
                              </td>
                              <td className="text-left">
                              {popData?.email}
                          
                              </td>
                              <td className=" text-left"> {popData?.password} </td>
                       
                              <td className="text-center">
                              
                                <Link
                              to={`/dashboard/edit-pop/${popData?._id}`}
                                >
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                                |
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeletePopModal(popData)}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllPop.propTypes = {
  getPop: PropTypes.func.isRequired,
  pop: PropTypes.object.isRequired,
  deletePop: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pop: state.pop,
});

export default connect(mapStateToProps, {
  getPop,
  deletePop
})(AllPop);

