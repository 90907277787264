import { FormControl, Input, InputLabel } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import "../../assets/css/inputStyle.css";

const useStyles = makeStyles({
  inputField: {
    "&:after": {
      borderBottom: "3px solid #000 !important",
    },
  },
});

const InputField = ({
  label,
  type,
  value,
  check,
  onChange,
  onClick,
  onMouseDown,
}) => {
  const classes = useStyles();
  return (
    <>
      <FormControl
        sx={{ width: "100%" }}
        variant="standard"
        className={classes.inputField}
      >
        <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
        <Input
          id="standard-adornment-password"
          type={type}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </>
  );
};

export default InputField;
