import React from "react";

function ErrorPage() {
  return (
    <div className="error-page d-flex flex-column align-items-center justify-content-center w-100 h-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 750 500"
        height="500"
      >
        <g id="BACKGROUND_2">
          <g>
            <g>
              <g>
                <path
                  fill="#f1b300"
                  className="fill-green"
                  d="M122.4,275.4v-47.1H30v-37.6l105.9-136h28.8v135.7h27.8v38h-27.8v47.1H122.4z M72.4,190.3h54.7v-71.5
					L72.4,190.3z"
                />
              </g>
              <g>
                <path
                  fill="#f1b300"
                  className="fill-green"
                  d="M616,275.4v-47.1h-92.4v-37.6l105.9-136h28.8v135.7h27.8v38h-27.8v47.1H616z M566,190.3h54.7v-71.5
					L566,190.3z"
                />
              </g>
            </g>
            <path
              fill="#212121"
              className="st1"
              style={{ opacity: "0.15" }}
              d="M524.9,290.3l0.8-0.8c1.2-1.1,1.3-3.1,0.2-4.3l-17-18.5c17.5-22,28.4-49.5,29.7-79.7
			c3.4-76.1-55.6-140.6-131.8-144c-76.2-3.4-140.6,55.6-144,131.8c-3.4,76.2,55.6,140.6,131.8,144c30.2,1.3,58.5-7.1,82-22.6
			l17,18.5c1.1,1.2,3.1,1.3,4.3,0.2l0.8-0.8l125.7,137.3h47.8L524.9,290.3z"
            />
            <g>
              <path
                fill="#f1b300"
                className="fill-green"
                d="M687.6,468.2L482.8,263.4l-26.3,26.3l204.8,204.8c7.7,7.7,20.3,7.2,27.4-1.1h0
				C694.9,486,694.4,475.1,687.6,468.2z"
              />
              <path
                fill="#f1b300"
                className="fill-green"
                d="M437.5,277l24.7,24.7c1.3,1.3,3.3,1.3,4.6,0l28-28c1.3-1.3,1.3-3.3,0-4.6l-24.7-24.7L437.5,277z"
              />
              <polygon
                fill="#bdbdbd"
                className="st2"
                points="474,248.3 477.1,251.5 450.7,290.2 439,278.6 			"
              />
              <polygon
                fill="#212121"
                style={{ opacity: "0.64" }}
                className="st3"
                points="467.6,300.9 494,274.6 495.8,276.4 475.2,308.5 			"
              />
              <path
                fill="#f1b300"
                className="fill-green"
                d="M358.1,19.4c-80.4,0-145.6,65.2-145.6,145.6s65.2,145.6,145.6,145.6S503.7,245.5,503.7,165
				S438.5,19.4,358.1,19.4z M358.1,280.6c-63.8,0-115.5-51.7-115.5-115.5c0-63.8,51.7-115.5,115.5-115.5S473.6,101.2,473.6,165
				C473.6,228.8,421.9,280.6,358.1,280.6z"
              />
              <circle
                fill="#fff"
                className="fill-white"
                cx="358.1"
                cy="165"
                r="115.5"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#f1b300"
                className="fill-green"
                d="M397.6,146.7c-0.4-0.8-1.1-1.3-2-1.3h-26.4l26-41.1c0.4-0.7,0.5-1.6,0.1-2.3c-0.4-0.7-1.1-1.2-1.9-1.2h-35.6
				c-0.8,0-1.6,0.5-2,1.2l-33.4,66.7c-0.3,0.7-0.3,1.5,0.1,2.2c0.4,0.7,1.1,1.1,1.9,1.1h22.9l-24.9,59.2c-0.4,1,0,2.2,0.9,2.8
				c0.4,0.2,0.8,0.3,1.2,0.3c0.6,0,1.3-0.3,1.7-0.8l71.2-84.5C397.8,148.4,397.9,147.5,397.6,146.7z"
              />
            </g>
          </g>
        </g>
      </svg>
      <h1 style={{ fontSize: "4rem", color: "#f1b300" }}>Page Not Found!</h1>
    </div>
  );
}

export default ErrorPage;
