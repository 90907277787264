import { LIVE_URl, GET_INVOICE,GET_INVOICES, GET_INVOICE_DETAILS } from "./types";
import axios from "axios";
import { json } from "react-router-dom";
import { toast } from "react-toastify";
export const getInvoice = (loadingFunc) => async (dispatch) => {
    try {
      loadingFunc(true);
      const res = await axios.get(`${LIVE_URl}/document`);
      if (res) {
        loadingFunc(false);
        dispatch({
          type: GET_INVOICE,
          payload: res?.data?.data,
        });
      }
      return true;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
    }
  };

  export const invoiceRegistration = (val, setErrorState,token ) => {
    return async (dispatch) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "Application/json");
        myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2M2QyYjllYjQ3Zjc2MWYyMDgwZmM3YjIiLCJqdGkiOiJiZjRjNTJmY2ZlOTljNDA3MzEzN2NjYzEwODFlZTNmMDljOTZiMDU2MGM2NzVhNTdlMTU3Mzg2YTkyMTY0YzU2NTUyY2NhZDEwY2UwZTU0MSIsImlhdCI6MTY4MTc2MTY3Mi43MzAwNzg5MzU2MjMxNjg5NDUzMTI1LCJuYmYiOjE2ODE3NjE2NzIuNzMwMDgzOTQyNDEzMzMwMDc4MTI1LCJleHAiOjE3MTMzODQwNzIuNjQ4Mzc1MDM0MzMyMjc1MzkwNjI1LCJzdWIiOiI2M2Q1NGZhN2RiZTM4Y2Y0ZmYwZjE0NTIiLCJzY29wZXMiOltdfQ.HWm27cbJi_qZAXQNmZtOWBBZHFGpcwPZ-1nF-3uP0WVinVUBUPLwHsHLz9bmXxfR6WA1OnAqrVCB6eONjuCAq94b05T3K5WR3Zc-MzFLnJPy06Wxozpr-ypCRIX-fhnCcVWMuF3iKzls2LViq-D_3hCSCciGJ2EJC19lyJac9C9B9lMM79AadXC7xGoDJSqjPKME-AFBbjuRbVK_xXlpZjDSPNtktZujgchDe4AlbELzc9mVPg00LA-5_pUSq9NjMvOqQVHfH46GORdQAqt6a4daZ7KkLvqT0Yk4b5oE8Lyy01QkO0yxSHIw7PMJi5ekyDSGIUoqfbmtqnzqZx5LX9QZOd8aNt6gJ3vzKfTpLKgnwh8JJEyPjD8ptzT319o1g295hoDuFFcoegA4hBfJMkvHNMydSwcsroWrCXZYiLFFmFPYBeiju7pGexAMOSOBNcVD5idjhNRQDafigK2POXonQpmnzShEqP7fvTs8H2TksBLKjtteB8CbjOtvh3_m-YYOzTnfrqfjR6LT8moc2tbiHYcaZMQcFHweKXGpXX08Y4orZBr9_NhhghWb4-3YJCN-xhBOYwbbaTiNxv7_MbGX_AfnMZG8snabxI6Zouwl986utP5pm7B74ZA7fhJlmQ3K4Z9duLpIKd7bx-JaB6Oql9_LiZSSakfqf6O1_aA");
  console.log('data added ',val)
        var formdata = new FormData();
        formdata.append("date[]", "");
        formdata.append("description[]", "");
        formdata.append("business_id", "");
        formdata.append("shop_id", "");
        formdata.append("vendor_id", "");
        formdata.append("debit[]", "");
        formdata.append("credit[]", "");
        formdata.append("balance[]", "");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};
  
        dispatch({
          type: GET_INVOICE,
          payload: true,
        });
  
        let invoice = await fetch(
          `${LIVE_URl}/invoice`,
          requestOptions
        ).then((response) => {
          return response.json().then((data) => ({
            status: response.status,
            data,
          }));
        });
        if (invoice.status === 200 && invoice.data.success === true) {
          dispatch({
            type: GET_INVOICE,
            payload: invoice.data.data,
          });
          
        } else {
          if (invoice.data.success === false) {
            console.log(invoice);
            setErrorState(invoice.data.message);
            
          }
          
          console.log(invoice);
        }
        dispatch({
          type: GET_INVOICE,
          payload: false,
        });
      } 

     catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };
  }

  // get invoice
  export const Invoicesget = (loadingFunc) => async (dispatch) => {
    try {
      loadingFunc(true);
      const res = await axios.get(`${LIVE_URl}/invoice`);
      if (res) {
        loadingFunc(false);
        
        dispatch({
          type: GET_INVOICES,
          payload: res?.data?.data,
        });
      }
      return true;
      
    } catch (error) {
      loadingFunc(false);
      
      toast.error(error.response.data.message);
    }
  };
  
  // Invoice REGISTRATION
  
  export const RegistrationInvoice =
    (invoiceDetails,setErrorState, loadingFunc) => async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = invoiceDetails;
      try {
        const res = await axios.post(`${LIVE_URl}/invoice`, body, config);
  
        if (res) {
          toast.success("Invoice Registration Successfully");
        }
  
        return res;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      }
//       return async (dispatch) => {
//       try {
//         var myHeaders = new Headers();
// myHeaders.append("Accept", "Application/json");
// myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2M2QyYjllYjQ3Zjc2MWYyMDgwZmM3YjIiLCJqdGkiOiJiZjRjNTJmY2ZlOTljNDA3MzEzN2NjYzEwODFlZTNmMDljOTZiMDU2MGM2NzVhNTdlMTU3Mzg2YTkyMTY0YzU2NTUyY2NhZDEwY2UwZTU0MSIsImlhdCI6MTY4MTc2MTY3Mi43MzAwNzg5MzU2MjMxNjg5NDUzMTI1LCJuYmYiOjE2ODE3NjE2NzIuNzMwMDgzOTQyNDEzMzMwMDc4MTI1LCJleHAiOjE3MTMzODQwNzIuNjQ4Mzc1MDM0MzMyMjc1MzkwNjI1LCJzdWIiOiI2M2Q1NGZhN2RiZTM4Y2Y0ZmYwZjE0NTIiLCJzY29wZXMiOltdfQ.HWm27cbJi_qZAXQNmZtOWBBZHFGpcwPZ-1nF-3uP0WVinVUBUPLwHsHLz9bmXxfR6WA1OnAqrVCB6eONjuCAq94b05T3K5WR3Zc-MzFLnJPy06Wxozpr-ypCRIX-fhnCcVWMuF3iKzls2LViq-D_3hCSCciGJ2EJC19lyJac9C9B9lMM79AadXC7xGoDJSqjPKME-AFBbjuRbVK_xXlpZjDSPNtktZujgchDe4AlbELzc9mVPg00LA-5_pUSq9NjMvOqQVHfH46GORdQAqt6a4daZ7KkLvqT0Yk4b5oE8Lyy01QkO0yxSHIw7PMJi5ekyDSGIUoqfbmtqnzqZx5LX9QZOd8aNt6gJ3vzKfTpLKgnwh8JJEyPjD8ptzT319o1g295hoDuFFcoegA4hBfJMkvHNMydSwcsroWrCXZYiLFFmFPYBeiju7pGexAMOSOBNcVD5idjhNRQDafigK2POXonQpmnzShEqP7fvTs8H2TksBLKjtteB8CbjOtvh3_m-YYOzTnfrqfjR6LT8moc2tbiHYcaZMQcFHweKXGpXX08Y4orZBr9_NhhghWb4-3YJCN-xhBOYwbbaTiNxv7_MbGX_AfnMZG8snabxI6Zouwl986utP5pm7B74ZA7fhJlmQ3K4Z9duLpIKd7bx-JaB6Oql9_LiZSSakfqf6O1_aA");
// myHeaders.append("Cache-Control", "no-cache");
//         // var myHeaders = new Headers();
//         // myHeaders.append("Accept", "Application/json");
//         // myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2M2QyYjllYjQ3Zjc2MWYyMDgwZmM3YjIiLCJqdGkiOiJiZjRjNTJmY2ZlOTljNDA3MzEzN2NjYzEwODFlZTNmMDljOTZiMDU2MGM2NzVhNTdlMTU3Mzg2YTkyMTY0YzU2NTUyY2NhZDEwY2UwZTU0MSIsImlhdCI6MTY4MTc2MTY3Mi43MzAwNzg5MzU2MjMxNjg5NDUzMTI1LCJuYmYiOjE2ODE3NjE2NzIuNzMwMDgzOTQyNDEzMzMwMDc4MTI1LCJleHAiOjE3MTMzODQwNzIuNjQ4Mzc1MDM0MzMyMjc1MzkwNjI1LCJzdWIiOiI2M2Q1NGZhN2RiZTM4Y2Y0ZmYwZjE0NTIiLCJzY29wZXMiOltdfQ.HWm27cbJi_qZAXQNmZtOWBBZHFGpcwPZ-1nF-3uP0WVinVUBUPLwHsHLz9bmXxfR6WA1OnAqrVCB6eONjuCAq94b05T3K5WR3Zc-MzFLnJPy06Wxozpr-ypCRIX-fhnCcVWMuF3iKzls2LViq-D_3hCSCciGJ2EJC19lyJac9C9B9lMM79AadXC7xGoDJSqjPKME-AFBbjuRbVK_xXlpZjDSPNtktZujgchDe4AlbELzc9mVPg00LA-5_pUSq9NjMvOqQVHfH46GORdQAqt6a4daZ7KkLvqT0Yk4b5oE8Lyy01QkO0yxSHIw7PMJi5ekyDSGIUoqfbmtqnzqZx5LX9QZOd8aNt6gJ3vzKfTpLKgnwh8JJEyPjD8ptzT319o1g295hoDuFFcoegA4hBfJMkvHNMydSwcsroWrCXZYiLFFmFPYBeiju7pGexAMOSOBNcVD5idjhNRQDafigK2POXonQpmnzShEqP7fvTs8H2TksBLKjtteB8CbjOtvh3_m-YYOzTnfrqfjR6LT8moc2tbiHYcaZMQcFHweKXGpXX08Y4orZBr9_NhhghWb4-3YJCN-xhBOYwbbaTiNxv7_MbGX_AfnMZG8snabxI6Zouwl986utP5pm7B74ZA7fhJlmQ3K4Z9duLpIKd7bx-JaB6Oql9_LiZSSakfqf6O1_aA");
  
//         var formdata = new FormData();
// formdata.append("date", "2023-01-01");
// formdata.append("description", "Some");
// formdata.append("debit", "");
// formdata.append("credit", "");
// formdata.append("balance", "");

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: formdata,
//   redirect: 'follow'
// };
  
//         dispatch({
//           type: GET_INVOICES,
//           payload: true,
//         });
  
//         let invoices = await fetch(
//           `${LIVE_URl}/invoice`,
//           requestOptions
//         ).then((response) => {
//           return response.json().then((data) => ({
//             status: response.status,
//             data,
//           }));
//         });
//         if (invoices.status === 200 && invoices.data.success === true) {
//           dispatch({
//             type: GET_INVOICES,
//             payload: invoices.data.data,
//           });
          
//         } else {
//           if (invoices.data.success === false) {
//             console.log(invoices);
//             setErrorState(invoices.data.message);
            
//           }
          
//           console.log(invoices);
//         }
//         dispatch({
//           type: GET_INVOICES,
//           payload: false,
//         });
//       }  catch (error) {
//         toast.error(error.response.data.message);
//         return false;
//       }
//     }
    };
  
  export const invoiceUpdate = (invoiceId, invoiceDetails) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = invoiceDetails;
    try {
      const res = await axios.put(`${LIVE_URl}/invoice/${invoiceId}`, body, config);
  
      if (res) {
        toast.success("invoice Updated Successfully");
      }
  
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };
  
  export const getInvoiceDetails = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`${LIVE_URl}/invoice/${id}`);
      if (res) {
        dispatch({
          type: GET_INVOICE_DETAILS,
          payload: res?.data?.data,
        });
      }
  
      return true;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  
  // DELETE Plan
  
  export const deleteInvoice = (invoiceId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(`${LIVE_URl}/invoice/${invoiceId}`, config);
      if (res) {
        deleteLoaderFunc(false);
        dispatch(Invoicesget(deleteLoaderFunc));
        toast.success("invoice Deleted Successfully");
      }
  
      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
  
