import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { shopUpdate } from "../../actions/shops";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const EditShop = ({ closeEditShopForm, shopUpdate, shopData }) => {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: shopData?.name,
    contact_number: shopData?.contact_number,
    shop_email: shopData?.shop_email,
    address: shopData?.address,
    vat: shopData?.vat,
    about: shopData?.about,
    status: shopData?.status,
  });
  const [errors, setErrors] = useState({
    nameErr: "",
    shop_emailErr: "",
    contact_numberErr: "",
    addressErr: "",
    vatErr: "",
    aboutErr: "",
    statusErr: "",
  });

  const { name, contact_number, shop_email, address, vat, about, status } =
    data;

  const {
    nameErr,
    shop_emailErr,
    contact_numberErr,
    addressErr,
    vatErr,
    aboutErr,
    statusErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const validate = () => {
    if (!name) {
      setErrors((prev) => ({
        ...prev,
        nameErr: "Please provide shop name",
      }));
    }
    if (!contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!shop_email) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: "Please provide shop email",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide shop address",
      }));
    }
    if (!vat) {
      setErrors((prev) => ({
        ...prev,
        vatErr: "Please provide shop vat",
      }));
    }
    if (!about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please provide status",
      }));
    }

    if (
      !name ||
      !contact_number ||
      !shop_email ||
      !address ||
      !vat ||
      !about ||
      !status
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeShopEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      setErrors((prev) => ({ ...prev, shop_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await shopUpdate(shopData?._id, data, loadingFunc);
        setLoading(false);
        if (response) {
          closeEditShopForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      name: "",
      contact_number: "",
      shop_email: "",
      address: "",
      vat: "",
      about: "",
    }));
    setErrors((prev) => ({
      ...prev,
      nameErr: "",
      shop_emailErr: "",
      contact_numberErr: "",
      addressErr: "",
      vatErr: "",
      aboutErr: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Shop Name</label>
            <input
              style={{
                border: nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop name"
            />
            {nameErr && (
              <Typography className={classes.errorMsg}>{nameErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Shop email</label>
            <input
              type="text"
              name="shop_email"
              style={{
                border: shop_emailErr && "1px solid #d32f2f",
              }}
              value={shop_email}
              onChange={(e) => handleChangeShopEmail(e)}
              className="form-control"
              placeholder="Shop email"
              required="required"
            />
            {shop_emailErr && (
              <Typography className={classes.errorMsg}>
                {shop_emailErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              style={{
                border: contact_numberErr && "1px solid #d32f2f",
              }}
              value={contact_number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  contact_numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="Contact number"
              required="required"
            />
            {contact_numberErr && (
              <Typography className={classes.errorMsg}>
                {contact_numberErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Shop Vat</label>
            <input
              type="text"
              name="vat"
              style={{
                border: vatErr && "1px solid #d32f2f",
              }}
              value={vat}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  vatErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop vat"
              required="required"
            />
            {vatErr && (
              <Typography className={classes.errorMsg}>{vatErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Address</label>
            <input
              type="text"
              name="address"
              style={{
                border: addressErr && "1px solid #d32f2f",
              }}
              value={address}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  addressErr: "",
                }));
              }}
              className="form-control"
              placeholder="Shop address"
              required="required"
            />
            {addressErr && (
              <Typography className={classes.errorMsg}>{addressErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>About</label>
            <textarea
              type="text"
              name="about"
              style={{ border: errors?.aboutErr && "1px solid #d32f2f" }}
              value={about}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  aboutErr: "",
                }));
              }}
              className="form-control"
              placeholder="About"
              required="required"
            />
            {aboutErr && (
              <Typography className={classes.errorMsg}>{aboutErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Status</label>
            <div className="select">
              <select
                name="status"
                style={{ border: statusErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={status}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    statusErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an business
                </option>
                <option value="1">Active</option>
                <option value="0">In active</option>
              </select>
              {statusErr && (
                <Typography className={classes.errorMsg}>
                  {statusErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              nameErr ||
              shop_emailErr ||
              contact_numberErr ||
              addressErr ||
              vatErr ||
              aboutErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};

EditShop.propTypes = {
  shopUpdate: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  shopUpdate,
})(EditShop);
