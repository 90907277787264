import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteBank, getBankRequests } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEye, FaEdit, FaUserPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddBankRequest from "../../components/forms/AddBankRequest";
import EditBankRequest from "../../components/forms/EditBankRequest";
import hasPermission from "../../utils/permisssions";

const BankRequests = ({
  bank: { allBankRequests },
  toggleNavbar,
  getBankRequests,
  deleteBank,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [bankRequestData, setBankRequestData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddBankRequestForm = () => {
    setToggleForm(true);
  };

  const closeAddBankRequestForm = () => {
    setToggleForm(false);
  };

  const toggleEditBankRequestForm = (data) => {
    setBankRequestData(data);
    setToggleEditForm(true);
  };

  const closeEditBankRequestForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getBankRequests(loadingFunc);
  }, [getBankRequests]);

  return (
    <>
      {/* ADD BANK MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddBankRequestForm}
        bodyContent={
          <AddBankRequest closeAddBankRequestForm={closeAddBankRequestForm} />
        }
        title="Add Bank Request"
      />

      {/* EDIT BANK MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditBankRequestForm}
        bodyContent={
          <EditBankRequest
            closeEditBankRequestForm={closeEditBankRequestForm}
            bankRequestData={bankRequestData}
          />
        }
        title="Edit Bank Request"
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Bank Requests
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                       {
                        hasPermission('add-bank-request') &&
                      <a
                        onClick={(e) => toggleAddBankRequestForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add Bank Request
                      </a>
}
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allBankRequests?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Bank Name</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allBankRequests?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.bank_name}</td>
                                <td>
                                  {
                                    hasPermission('view-bank-request') &&
                                    <FaEye
                                      className="s-24 m-1"
                                      style={{ color: "#000", cursor: "pointer" }}
                                    />
                                  }
                                  {
                                    hasPermission('edit-bank-request') &&
                                    <FaEdit
                                      onClick={() =>
                                        toggleEditBankRequestForm(data)
                                      }
                                      className="s-24 m-1"
                                      style={{ color: "#000", cursor: "pointer" }}
                                    />
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

BankRequests.propTypes = {
  getBankRequests: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getBankRequests,
  deleteBank,
})(BankRequests);
