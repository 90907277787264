import { Button } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customBtn: {
    background: "#0098da !important",
    padding: "12px 20px !important",
    fontSize: "1rem !important",
    borderRadius: "4px !important",
    transition: " .5s !important",
    color: "#fff !important",
    border: "0 !important",
    fontWeight: "600!important",
    lineHeight: "1.25 !important",
    textTransform: "none !important",
    letterSpacing: "3px",
  },
});

const CustomButton = ({
  text,
  onClick,
  loading,
  loader,
  style,
  type,
  disable,
}) => {
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={onClick}
        type={type}
        style={style}
        className={classes.customBtn}
        disabled={loading || disable}
      >
        {text}
        {loading && loader}
      </Button>
    </>
  );
};

export default CustomButton;
