import { LIVE_URl, GET_CUSTOMER, GET_CUSTOMER_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

export const getCustomer = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/customer`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_CUSTOMER,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

export const customerRegistration = (customerDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },  
  };
  const body = customerDetails;
  try {
    const res = await axios.post(`${LIVE_URl}/customer`, body, config);
    
    if (res) {
      toast.success("Customer Registration Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const updateCustomer =
  (customerId, customerDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = customerDetails;
    try {
      const res = await axios.put(
        `${LIVE_URl}/customer/${customerId}`,
        body,
        config
      );

      if (res) {
        toast.success("Customer Updated Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const getCustomerDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/customer/${id}`);
    if (res) {
      dispatch({
        type: GET_CUSTOMER_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const deleteCustomer =
  (customerId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(
        `${LIVE_URl}/customer/${customerId}`,
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getCustomer(deleteLoaderFunc));
        toast.success("Customer Delete Successfully");
      }
      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
