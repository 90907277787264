import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePermission } from "../../actions/role";
import Icofont from "react-icofont";

const UpdatePermission = ({ updatePermission }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const validate = () => {
    if (!name) {
      setNameErr("Please provid name");
    }

    if (!name) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        let response = await updatePermission(params?.id, name, loadingFunc);
        if (response) {
          navigate("/dashboard/all-permissions");
        }
      } catch (err) {}
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Update Permission
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="card">
            <div className="card-body b-b">
              <form className="form-material">
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="form-line">
                          <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => {
                              onChangeFields(e);
                              setNameErr("");
                            }}
                            className="form-control"
                            placeholder="Permission Name"
                          />
                        </div>
                        {nameErr && <p className="text-danger">{nameErr}</p>}
                      </div>

                      <button
                        onClick={onSubmit}
                        className="btn btn-primary mt-4"
                        style={
                          loading || nameErr ? { pointerEvents: "none" } : null
                        }
                      >
                        Submit
                        {loading ? (
                          <Icofont
                            icon="spinner"
                            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                            spin="true"
                          />
                        ) : (
                          <i
                            className="icon-arrow_forward mr-2"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UpdatePermission.propTypes = {
  updatePermission: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  updatePermission,
})(UpdatePermission);
