import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shopUpdate } from "../../actions/shops";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/global/Loader";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";

const EditShop = ({ shopUpdate }) => {
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [data, setData] = useState();
  const [errors, setErrors] = useState({
    nameErr: "",
    shop_emailErr: "",
    contact_numberErr: "",
    addressErr: "",
    vatErr: "",
    aboutErr: "",
    statusErr: "",
  });

  const {
    nameErr,
    shop_emailErr,
    contact_numberErr,
    addressErr,
    vatErr,
    aboutErr,
    statusErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/shop/${params.id}`);
      setData(result?.data?.data);
      setLoading(false);
      console.log(data)
    };
    fetchData();
  }, [params.id]);

  const validate = () => {
    const errors = {};
    if (!data?.name) errors.nameErr = "Please provide shop name";
    if (!data?.contact_number)
      errors.contact_numberErr = "Please provide contact number";
    if (!data?.shop_email) errors.shop_emailErr = "Please provide shop email";
    if (!data?.address) errors.addressErr = "Please provide shop address";
    if (!data?.vat) errors.vatErr = "Please provide shop vat";
    if (!data?.about) errors.aboutErr = "Please provide about";
    if (!data?.status) errors.statusErr = "Please ,fprovide status";

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeShopEmail = (e) => {
    const emailRegex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
    const isValidEmail = emailRegex.test(e.target.value);

    if (!isValidEmail) {
      setErrors((prev) => ({
        ...prev,
        shop_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
    } else {
      setErrors((prev) => ({ ...prev, shop_emailErr: "" }));
    }

    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await shopUpdate(params?.id, data, loadingFunc);
        setLoading(false);
        if (response) {
          navigateTo("/dashboard/all-shops");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
               Edit Shop <span className="s-14">Registration Number</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="name"
                                  value={data?.name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Shop name"
                                />
                              </div>
                              {nameErr && (
                                <p className="text-danger">{nameErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="shop_email"
                                  value={data?.shop_email}
                                  onChange={(e) => handleChangeShopEmail(e)}
                                  className="form-control"
                                  placeholder="Shop email"
                                  required="required"
                                />
                              </div>
                              {shop_emailErr && (
                                <p className="text-danger">{shop_emailErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="contact_number"
                                  value={data?.contact_number}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      contact_numberErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Contact number"
                                  required="required"
                                />
                              </div>
                              {contact_numberErr && (
                                <p className="text-danger">
                                  {contact_numberErr}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="vat"
                                  value={data?.vat}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      vatErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Shop vat"
                                  required="required"
                                />
                              </div>
                              {vatErr && (
                                <p className="text-danger">{vatErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="address"
                                  value={data?.address}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      addressErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Shop address"
                                  required="required"
                                />
                                {addressErr && (
                                  <p className="text-danger">{addressErr}</p>
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <textarea
                                  type="text"
                                  name="about"
                                  value={data?.about}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      aboutErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="About"
                                  required="required"
                                />
                              </div>
                              {aboutErr && (
                                <p className="text-danger">{aboutErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="status"
                                  className="custom-select form-control"
                                  value={data?.status}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      statusErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an business
                                  </option>
                                  <option value="1">Active</option>
                                  <option value="0">In active</option>
                                </select>
                              </div>
                              {statusErr && (
                                <p className="text-danger">{statusErr}</p>
                              )}
                            </div>

                            <button
                              onClick={onSubmit}
                              className="btn btn-primary mt-2"
                              style={
                                loading ||
                                nameErr ||
                                shop_emailErr ||
                                contact_numberErr ||
                                addressErr ||
                                vatErr ||
                                aboutErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                            >
                              <i className="icon-arrow_forward mr-2"></i>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Documentation</h3>
                <hr />
                <p>
                  Subscription Plan: Starter
                  <br />
                  Shops: 06
                  <br />
                  Staff: 06 x 3
                </p>

                <p>
                  <strong> Account Information</strong>
                  <br />
                  Bank Name - Title - Account No <br />
                  Bank Name - Title - Account No
                  <br />
                  Bank Name - Title - Account No
                </p>

                <a
                  href="bank_add_account_bussiness.html"
                  target="_blank"
                  className="btn btn-xs btn-danger"
                >
                  Add New Account Number
                </a>

                <a
                  href="#"
                  target="_blank"
                  className="btn btn-xs btn-primary m-1"
                >
                  Update Package
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditShop.propTypes = {
  shopUpdate: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  shopUpdate,
})(EditShop);
