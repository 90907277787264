import { GET_PLANS, GET_PLAN_DETAILS } from "../actions/types";

const initialState = {
  allPlans: [],
  planDetails: null,
};

export default function plan(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PLANS:
      return {
        ...state,
        allPlans: payload,
      };
    case GET_PLAN_DETAILS:
      return {
        ...state,
        planDetails: payload,
      };
    default:
      return state;
  }
}
