import React, { useEffect, useState } from "react";
import { bankRequestRegistration } from "../../actions/bank";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AddBankRequest = ({
  bankRequestRegistration,
  getShops,
  shops: { allShops },
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    bank_name: "",
    sample_document: "",
    shop_id: "",
  });
  const [errors, setErrors] = useState({
    bank_nameErr: "",
    sample_documentErr: "",
    shop_idErr: "",
  });

  const { bank_name, sample_document, shop_id } = data;

  const { bank_nameErr, sample_documentErr, shop_idErr } = errors;

  const validate = () => {
    if (!bank_name) {
      setErrors((prev) => ({
        ...prev,
        bank_nameErr: "Please provide bank name",
      }));
    }
    if (!sample_document) {
      setErrors((prev) => ({
        ...prev,
        sample_documentErr: "Please provide document",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }

    if (!bank_name || !sample_document || !shop_id) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  console.log("e.target.files[0]", sample_document);
  const onChangeFile = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    setErrors((prev) => ({
      ...prev,
      sample_documentErr: "",
    }));
    setData({ ...data, sample_document: e.target.files[0] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const dataForm = new FormData();
        dataForm.append("bank_name", bank_name);
        dataForm.append("sample_document", sample_document);
        dataForm.append("shop_id", shop_id);
        let response = await bankRequestRegistration(dataForm, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-bank-requests");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Add Bank Request <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="bank_name"
                                value={bank_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    bank_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Bank name"
                              />
                            </div>
                            {bank_nameErr && (
                              <p className="text-danger">{bank_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Document</label>
                              <input
                                type="file"
                                name="sample_document"
                                onChange={(e) => {
                                  onChangeFile(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    sample_documentErr: "",
                                  }));
                                }}
                                className="form-control"
                              />
                            </div>
                            {sample_documentErr && (
                              <p className="text-danger">
                                {sample_documentErr}
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <select
                                name="shop_id"
                                className="custom-select form-control"
                                value={shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {shop_idErr && (
                              <p className="text-danger">{shop_idErr}</p>
                            )}
                          </div>
                          {
                            hasPermission('add-bank-request') &&
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              bank_nameErr ||
                              sample_documentErr ||
                              shop_idErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddBankRequest.propTypes = {
  bankRequestRegistration: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getShops,
  bankRequestRegistration,
})(AddBankRequest);
