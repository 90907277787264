import { LIVE_URl, GET_VENDOR, GET_VENDOR_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

export const getVendor = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/vendor`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_VENDOR,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);

    toast.error(error.response.data.message);
  }
};

export const vendorRegistration =
  (vendorDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = vendorDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/vendor`, body, config);

      if (res) {
        toast.success("Vendor Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const updateVendor = (vendorId, vendorDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = vendorDetails;
  try {
    const res = await axios.put(`${LIVE_URl}/vendor/${vendorId}`, body, config);

    if (res) {
      toast.success("Vendor Updated Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const getVendorDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/vendor/${id}`);
    if (res) {
      dispatch({
        type: GET_VENDOR_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const deleteVendor =
  (vendorId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(`${LIVE_URl}/vendor/${vendorId}`, config);
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getVendor(deleteLoaderFunc));
        toast.success("Vendor Delete Successfully");
      }
      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
