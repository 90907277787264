import React ,{ useEffect, useState }from "react";
import img1 from "../../assets/img/basic/logo.png";
import userImage from "../../assets/img/dummy/u2.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as MdIcons from "react-icons/md";
import {  useParams } from "react-router-dom";
import {
  getUserInfo,
  
} from "../../actions/role";
import hasPermission from "../../utils/permisssions";
const AsideBar = ({ getUserInfo,permissions}) => {
  useEffect(() => {
    // Save permissions to local storage on login
    const permissions = ['create', 'superadmin', 'update'];
    savePermissions(permissions);
  }, []);
  const hasPermission = (permissions) => {
    const storedPermissions = getStoredPermissions();
    return storedPermissions.includes(permissions);
  };
  console.log(permissions)
  useEffect(() => {
    getUserInfo(params?.id);
  }, [params?.id]);
  console.log(getUserInfo)
  const params = useParams();
  return (
    <>
      <aside
        className="main-sidebar fixed offcanvasSide shadow"
        data-toggle="offcanvas"
      >
        <div
          className="slimScrollDiv"
          style={{
            position: "relative",
            overflow: "hidden",
            width: "auto",
            height: "781px",
          }}
        >
          <section className="sidebar">
            <div className="w-80px mt-3 mb-3 ml-3">
              <img src={img1} alt="" />
            </div>
            <div className="relative">
              <a
                data-toggle="collapse"
                href="#userSettingsCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="userSettingsCollapse"
                className="btn-fab btn-fab-sm absolute fab-right-bottom fab-top btn-primary shadow1 "
              >
                <i className="icon icon-cogs"></i>
              </a>
              <div className="user-panel p-3 light mb-2">
                <div>
                  <div className="float-left image">
                    <img
                      className="user_avatar"
                      src={userImage}
                      alt="User Image"
                    />
                  </div>
                  <div className="float-left info">
                    <h6 className="font-weight-light mt-2 mb-1">
                      Alexander Pierce
                    </h6>
                    <a href="#">
                      <i className="icon-circle text-primary blink"></i> Online
                    </a>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div
                  className="collapse multi-collapse show"
                  id="userSettingsCollapse"
                >
                  <div className="list-group mt-3 shadow">
                    <a
                      href="index.html"
                      className="list-group-item list-group-item-action "
                    >
                      <i className="mr-2 icon-umbrella text-blue"></i>Profile
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="mr-2 icon-cogs text-yellow"></i>Settings
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="mr-2 icon-security text-purple"></i>Change
                      Password
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul className="sidebar-menu">
              <li className="header">
                <strong>MAIN NAVIGATION</strong>
              </li>
              <li className="treeview">
                <a href="dashboard.html">
                  <i className="icon icon-sailing-boat-water purple-text s-18"></i>{" "}
                  <span>Dashboard</span>
                </a>
              </li>
          {  hasPermission('delete-user') && 

          
              <li className="treeview ">
                <a href="#">
                  <i className="icon  light-green-text s-18">
                    <MdIcons.MdOutlineBusinessCenter />
                  </i>
                  Business
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu ">
                  <li>
                    <a href="shops_list_bussiness.html">
                      <i className="icon icon-circle-o"></i>All Business
                    </a>
                  </li>
                  <li>
                    <a href="shop_Add_bussiness.html">
                      <i className="icon icon-add"></i>Add Business
                    </a>
                  </li>
                </ul>
              </li>
}

            
              <li className="treeview ">
                <a href="#">
                  <i className="icon icon-shopping_cart light-green-text s-18"></i>
                  Shops
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu ">
                  <li>
                    <a href="shops_list_bussiness.html">
                      <i className="icon icon-circle-o"></i>All Shops
                    </a>
                  </li>
                  <li>
                    <a href="shop_Add_bussiness.html">
                      <i className="icon icon-add"></i>Add Shop
                    </a>
                  </li>
                </ul>
              </li>
          
              <li className="treeview" >
                <a href="#">
                  <i className="icon icon-bank light-green-text s-18"></i>
                  Banks
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="bank_list_business.html">
                      <i className="icon icon-circle-o"></i>All Banks
                    </a>
                  </li>
                  <li>
                    <a href="bank_add_request_bussiness.html">
                      <i className="icon icon-add"></i>Add Bank
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-bank light-green-text s-18"></i>
                  Bank Request
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="bank_list_business.html">
                      <i className="icon icon-circle-o"></i>All Bank Request
                    </a>
                  </li>
                  <li>
                    <a href="bank_add_request_bussiness.html">
                      <i className="icon icon-add"></i>Add Bank Request
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-bank light-green-text s-18"></i>
                  Account
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="bank_list_business.html">
                      <i className="icon icon-circle-o"></i>All Account
                    </a>
                  </li>
                  <li>
                    <a href="bank_add_request_bussiness.html">
                      <i className="icon icon-add"></i>Add Account
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-bank light-green-text s-18"></i>
                  Vendor
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="bank_list_business.html">
                      <i className="icon icon-circle-o"></i>All Vendor
                    </a>
                  </li>
                  <li>
                    <a href="bank_add_request_bussiness.html">
                      <i className="icon icon-add"></i>Add Vendor
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-bank light-green-text s-18"></i>
                  Customer
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="bank_list_business.html">
                      <i className="icon icon-circle-o"></i>All Customer
                    </a>
                  </li>
                  <li>
                    <a href="bank_add_request_bussiness.html">
                      <i className="icon icon-add"></i>Add Customer
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-documents light-green-text s-18"></i>
                  Documents
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="file-convert.html">
                      <i className="icon icon-add"></i>Banks
                    </a>
                  </li>
                  <li>
                    <a href="file-convert-sale.html">
                      <i className="icon icon-add"></i>Sale
                    </a>
                  </li>
                  <li>
                    <a href="file-convert-purchase.html">
                      <i className="icon icon-add"></i>Cost
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-support light-green-text s-18"></i>
                  Plans
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="shop-pipe-email-list.html">
                      <i className="icon con-circle-o"></i>All Plans
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-th light-green-text s-18"></i>
                  Proceed Files
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="procede-files.html">
                      <i className="icon icon-add"></i>Banks
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon icon-add"></i>Sale
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon icon-add"></i>Cost
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-support light-green-text s-18"></i>
                  Tickets
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="shop-pipe-email-list.html">
                      <i className="icon con-circle-o"></i>All Tickets
                    </a>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="icon icon-folder-information light-green-text s-18"></i>
                  Reports
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="activity_log.html">
                      <i className="icon con-circle-o"></i>Activity Log
                    </a>
                  </li>
                </ul>
              </li>

              <li className="header light mt-3">
                <strong>Admin Section</strong>
              </li>
              <li className="treeview">
                <a href="#">
                  <i className="icon icon-account_box light-green-text s-18"></i>
                  Staff
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="staff-list.html">
                      <i className="icon icon-add"></i>All Staff
                    </a>
                  </li>
                  <li>
                    <a href="add_shop_staff.html">
                      <i className="icon icon-add"></i>Add Staff
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="#">
                  <i className="icon icon-battery-2 light-green-text s-18"></i>
                  Roll Managment
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icon icon-bug_report light-green-text s-18"></i>
                  Spam Filter
                </a>
              </li>
                   <li className="treeview">
                <a href="#">
                  <i className="icon icon-account_box light-green-text s-18"></i>
                  Email Piping
                  <i className="icon icon-angle-left s-18 pull-right"></i>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a href="#">
                      <i className="icon icon-add"></i>POP Settings
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon icon-add"></i>Add Staff
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="pop_setting.html">
                  <i className="icon icon-send-o light-green-text s-18"></i>
                  Email Piping
                </a>
              </li> */}
            </ul>
          </section>
        </div>
      </aside>
    </>
  );
};
// Save permissions to local storage
const savePermissions = (permissions) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
};

// Retrieve permissions from local storage
const getStoredPermissions = () => {
  const storedPermissions = localStorage.getItem('permissions');
  return storedPermissions ? JSON.parse(storedPermissions) : [];
};

AsideBar.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  getUserInfo,
  
})(AsideBar);


