import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { customerRegistration } from "../../actions/customer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getShops } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const AddCustomer = ({
  closeAddCustomerForm,
  customerRegistration,
  getShops,
  getBusinesses,
  shops: { allShops },
  business: { allBusiness },
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
    getBusinesses(loadingFunc);
  }, [getShops, getBusinesses]);

  const [data, setData] = useState({
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    address: "",
    post_code: "",
    city: "",
    country: "",
    shop_id: "",
    business_id: "",
  });
  const [errors, setErrors] = useState({
    customer_nameErr: "",
    customer_emailErr: "",
    customer_phoneErr: "",
    addressErr: "",
    post_codeErr: "",
    cityErr: "",
    countryErr: "",
    shop_idErr: "",
    business_idErr: "",
  });

  const {
    customer_name,
    customer_email,
    customer_phone,
    address,
    post_code,
    city,
    country,
    shop_id,
    business_id,
  } = data;

  const {
    customer_nameErr,
    customer_emailErr,
    customer_phoneErr,
    addressErr,
    post_codeErr,
    cityErr,
    countryErr,
    shop_idErr,
    business_idErr,
  } = errors;

  const validate = () => {
    if (!customer_name) {
      setErrors((prev) => ({
        ...prev,
        customer_nameErr: "Please provide name",
      }));
    }
    if (!customer_email) {
      setErrors((prev) => ({
        ...prev,
        customer_emailErr: "Please provide email",
      }));
    }
    if (!customer_phone) {
      setErrors((prev) => ({
        ...prev,
        customer_phoneErr: "Please provide phone number",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide address",
      }));
    }
    if (!post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!business_id) {
      setErrors((prev) => ({
        ...prev,
        business_idErr: "Please select business",
      }));
    }

    if (
      !customer_name ||
      !customer_email ||
      !customer_phone ||
      !address ||
      !post_code ||
      !city ||
      !country ||
      !shop_id ||
      !business_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        customer_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, customer_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, customer_email: e.target.value }));
      setErrors((prev) => ({ ...prev, customer_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await customerRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          closeAddCustomerForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      address: "",
      post_code: "",
      city: "",
      country: "",
      shop_id: "",
      business_id: "",
    }));
    setErrors((prev) => ({
      ...prev,
      customer_nameErr: "",
      customer_emailErr: "",
      customer_phoneErr: "",
      addressErr: "",
      post_codeErr: "",
      cityErr: "",
      countryErr: "",
      shop_idErr: "",
      business_idErr: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Customer Name</label>
            <input
              style={{ border: customer_nameErr && "1px solid #d32f2f" }}
              type="text"
              name="customer_name"
              value={customer_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  customer_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Customer name"
            />
            {customer_nameErr && (
              <Typography className={classes.errorMsg}>
                {customer_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Customer Email</label>
            <input
              type="email"
              name="customer_email"
              style={{ border: customer_emailErr && "1px solid #d32f2f" }}
              value={customer_email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Customer email"
              required="required"
            />
            {customer_emailErr && (
              <Typography className={classes.errorMsg}>
                {customer_emailErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Phone Number</label>
            <input
              type="text"
              name="customer_phone"
              style={{ border: customer_phoneErr && "1px solid #d32f2f" }}
              value={customer_phone}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  customer_phoneErr: "",
                }));
              }}
              className="form-control"
              placeholder="Phone number"
              required="required"
            />
            {customer_phoneErr && (
              <Typography className={classes.errorMsg}>
                {customer_phoneErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Country</label>
            <input
              type="text"
              name="country"
              style={{ border: countryErr && "1px solid #d32f2f" }}
              value={country}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  countryErr: "",
                }));
              }}
              className="form-control"
              placeholder="Country"
              required="required"
            />
            {countryErr && (
              <Typography className={classes.errorMsg}>{countryErr}</Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>City</label>
            <input
              type="text"
              name="city"
              style={{ border: cityErr && "1px solid #d32f2f" }}
              value={city}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  cityErr: "",
                }));
              }}
              className="form-control"
              placeholder="City"
              required="required"
            />
            {cityErr && (
              <Typography className={classes.errorMsg}>{cityErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Postal Code</label>
            <input
              type="text"
              name="post_code"
              style={{ border: post_codeErr && "1px solid #d32f2f" }}
              value={post_code}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  post_codeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Postal code"
              required="required"
            />
            {post_codeErr && (
              <Typography className={classes.errorMsg}>
                {post_codeErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Address</label>
            <input
              type="text"
              name="address"
              style={{
                border: addressErr && "1px solid #d32f2f",
              }}
              value={address}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  addressErr: "",
                }));
              }}
              className="form-control"
              placeholder="Address"
              required="required"
            />
            {addressErr && (
              <Typography className={classes.errorMsg}>{addressErr}</Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Shop</label>
            <div className="select">
              <select
                name="shop_id"
                style={{ border: shop_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={shop_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    shop_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an shop
                </option>
                {allShops.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {shop_idErr && (
                <Typography className={classes.errorMsg}>
                  {shop_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Business</label>
            <div className="select">
              <select
                name="business_id"
                style={{ border: business_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={business_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    business_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an business
                </option>
                {allBusiness.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {business_idErr && (
                <Typography className={classes.errorMsg}>
                  {business_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              customer_nameErr ||
              customer_emailErr ||
              customer_phoneErr ||
              addressErr ||
              post_codeErr ||
              cityErr ||
              countryErr ||
              shop_idErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton text={"Reset"} onClick={onResetForm} />
        </div>
      </form>
    </>
  );
};

AddCustomer.propTypes = {
  getShops: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  customerRegistration: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  business: state.business,
});

export default connect(mapStateToProps, {
  customerRegistration,
  getShops,
  getBusinesses,
})(AddCustomer);
