import { LIVE_URl, GET_BUYER, GET_BUYER_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL Plan

export const getBuyer = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/buyer`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_BUYER,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// Plan REGISTRATION

export const buyerRegistration =
  (buyerDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = buyerDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/buyer`, body, config);

      if (res) {
        toast.success("Buyer Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const buyerUpdate = (buyerId, buyerDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = buyerDetails;
  try {
    const res = await axios.put(`${LIVE_URl}/buyer/${buyerId}`, body, config);

    if (res) {
      toast.success("Buyer Updated Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};

export const getBuyerDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/buyer/${id}`);
    if (res) {
      dispatch({
        type: GET_BUYER_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// DELETE Plan

export const deleteBuyer = (buyerId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/buyer/${buyerId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getBuyer(deleteLoaderFunc));
      toast.success("Buyer Deleted Successfully");
    }

    return res;
  } catch (error) {
    deleteLoaderFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};
