import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { getShops } from "../../actions/shops";
import { getBanks } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { accountUpdate } from "../../actions/account";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const EditAccount = ({
  closeEditAccountForm,
  accountUpdate,
  accountData,
  getShops,
  getBanks,
  shops: { allShops },
  bank: { allBanks },
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShops(loadingFunc);
    getBanks(loadingFunc);
  }, [getShops, getBanks]);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    account_name: accountData?.name,
    number: accountData?.number,
    iban: accountData?.iban,
    currency: accountData?.currency,
    shop_id: accountData?.shop_id,
    bank_id: accountData?.bank_id,
    status: accountData?.status,
  });
  const [errors, setErrors] = useState({
    account_nameErr: "",
    numberErr: "",
    ibanErr: "",
    currencyErr: "",
    shop_idErr: "",
    bank_idErr: "",
    statusErr: "",
  });

  const { account_name, number, iban, currency, shop_id, bank_id, status } =
    data;

  const {
    account_nameErr,
    numberErr,
    ibanErr,
    currencyErr,
    shop_idErr,
    bank_idErr,
    statusErr,
  } = errors;

  const validate = () => {
    if (!account_name) {
      setErrors((prev) => ({
        ...prev,
        account_nameErr: "Please provide bank name",
      }));
    }
    if (!number) {
      setErrors((prev) => ({
        ...prev,
        numberErr: "Please provide number",
      }));
    }
    if (!iban) {
      setErrors((prev) => ({
        ...prev,
        ibanErr: "Please provide iban",
      }));
    }
    if (!currency) {
      setErrors((prev) => ({
        ...prev,
        currencyErr: "Please provide currency",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!bank_id) {
      setErrors((prev) => ({
        ...prev,
        bank_idErr: "Please select bank",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please select status",
      }));
    }

    if (
      !account_name ||
      !number ||
      !iban ||
      !currency ||
      !shop_id ||
      !bank_id ||
      !status
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await accountUpdate(accountData?._id, data, loadingFunc);
        setLoading(false);
        if (response) {
          closeEditAccountForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      account_name: "",
      number: "",
      iban: "",
      currency: "",
      shop_id: "",
      bank_id: "",
      status: "",
    }));
    setErrors((prev) => ({
      ...prev,
      account_nameErr: "",
      numberErr: "",
      ibanErr: "",
      currencyErr: "",
      shop_idErr: "",
      bank_idErr: "",
      statusErr: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Name</label>
            <input
              style={{
                border: account_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="account_name"
              value={account_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  account_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Name"
            />
            {account_nameErr && (
              <Typography className={classes.errorMsg}>
                {account_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Number</label>
            <input
              style={{
                border: numberErr && "1px solid #d32f2f",
              }}
              type="number"
              name="number"
              value={number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="Number"
            />
            {numberErr && (
              <Typography className={classes.errorMsg}>{numberErr}</Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>IBAN</label>
            <input
              style={{
                border: ibanErr && "1px solid #d32f2f",
              }}
              type="text"
              name="iban"
              value={iban}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  ibanErr: "",
                }));
              }}
              className="form-control"
              placeholder="IBAN"
            />
            {ibanErr && (
              <Typography className={classes.errorMsg}>{ibanErr}</Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Currency</label>
            <input
              style={{
                border: currencyErr && "1px solid #d32f2f",
              }}
              type="text"
              name="currency"
              value={currency}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  currencyErr: "",
                }));
              }}
              className="form-control"
              placeholder="Currency"
            />
            {currencyErr && (
              <Typography className={classes.errorMsg}>
                {currencyErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Shop</label>
            <div className="select">
              <select
                name="shop_id"
                style={{ border: shop_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={shop_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    shop_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an shop
                </option>
                {allShops.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {shop_idErr && (
                <Typography className={classes.errorMsg}>
                  {shop_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Bank</label>
            <div className="select">
              <select
                name="bank_id"
                style={{ border: bank_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={bank_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    allBanks: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an bank
                </option>
                {allBanks.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {shop_idErr && (
                <Typography className={classes.errorMsg}>
                  {shop_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Status</label>
            <div className="select">
              <select
                name="status"
                style={{ border: statusErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={status}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    statusErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an status
                </option>
                <option value="1">Active</option>
                <option value="0">In active</option>
              </select>
              {statusErr && (
                <Typography className={classes.errorMsg}>
                  {statusErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              account_nameErr ||
              numberErr ||
              ibanErr ||
              currencyErr ||
              shop_idErr ||
              bank_idErr ||
              statusErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};
EditAccount.propTypes = {
  accountUpdate: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  bank: state.bank,
});

export default connect(mapStateToProps, {
  getShops,
  getBanks,
  accountUpdate,
})(EditAccount);
