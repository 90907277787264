import {
  GET_BANKS,
  GET_BANK_REQUEST,
  GET_BANK_REQUEST_DETAILS,
  GET_BANK_DETAILS
} from "../actions/types";

const initialState = {
  allBanks: [],
  allBankRequests: [],
  bankDetails: null,
  bankRequestDetails: null,
};

export default function bank(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BANKS:
      return {
        ...state,
        allBanks: payload,
      };
    case GET_BANK_REQUEST:
      return {
        ...state,
        allBankRequests: payload,
      };
    case GET_BANK_REQUEST_DETAILS:
      return {
        ...state,
        bankRequestDetails: payload,
      };
      case GET_BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    default:
      return state;
  }
}
