import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { getPlans, deletePlan } from "../../actions/plan";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEye, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";
import AddAccount from "../../components/forms/AddAccount";
import EditAccount from "../../components/forms/EditAccount";
import AddPlan from "../../components/forms/AddPlan";
import EditPlan from "../../components/forms/EditPlan";
import hasPermission from "../../utils/permisssions";
const Plan = ({ plan: { allPlans }, toggleNavbar, getPlans, deletePlan }) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeletePlanModal, setDeletePlanModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [planData, setPlanData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddPlanForm = () => {
    setToggleForm(true);
  };

  const closeAddPlanForm = () => {
    setToggleForm(false);
  };

  const toggleEditPlanForm = (data) => {
    setPlanData(data);
    setToggleEditForm(true);
  };

  const closeEditPlanForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getPlans(loadingFunc);
  }, [getPlans]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Plan MODAL

  const openDeletePlanModal = (data) => {
    setPlanData(data);
    setDeletePlanModal(true);
  };

  const closeDeletePlanModal = () => setDeletePlanModal(false);

  const deletePlanFunc = async (e) => {
    e.preventDefault();
    let res = await deletePlan(planData?._id, deleteLoaderFunc);
    if (res) {
      closeDeletePlanModal();
      setPlanData("");
    }
  };

  return (
    <>
      {/* ADD Plan MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddPlanForm}
        bodyContent={<AddPlan closeAddPlanForm={closeAddPlanForm} />}
        title="Add Plan"
      />

      {/* EDIT Plan MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditPlanForm}
        bodyContent={
          <EditPlan closeEditPlanForm={closeEditPlanForm} planData={planData} />
        }
        title="Edit Plan"
      />

      <DeleteModal
        show={showDeletePlanModal}
        onHide={closeDeletePlanModal}
        title="Delete Plan"
        bodyText="Are you sure, you want to delete the Plan?"
        onClickNo={closeDeletePlanModal}
        onClickYes={(e) => deletePlanFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />

      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <header className="my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="s-24">
                  <i
                    className="icofont-brand-china-unicom"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Plan Registration
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid my-3">
          <div className="box">
            <section className="paper-card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box">
                    <div
                      className="box-header"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
{hasPermission('add-plan') &&   
                      <a
                        onClick={(e) => toggleAddPlanForm(e)}
                        className="btn btn-sm btn-danger float-right mb-4"
                      >
                        Add New Plan
                      </a>
}
                    </div>
                    <div className="box-body no-padding">
                      {loading ? (
                        <Loader />
                      ) : allPlans?.length > 0 ? (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ width: "10px" }}>#</th>
                              <th>Name</th>
                              <th className="text-left">Start Date</th>
                              <th className="text-left">End Date</th>
                              <th className="text-left">No of Users</th>
                              <th className="text-left">Price</th>
                              <th style={{ width: "200px" }}>Action</th>
                            </tr>
                            {allPlans?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.name}</td>
                                <td className="text-left">
                                  {data?.start_date}
                                </td>
                                <td className="text-left">{data?.end_date}</td>
                                <td className="text-left">
                                  {data?.no_of_users}
                                </td>
                                <td className="text-left">{data?.fee}</td>
                                <td>
                             {hasPermission('edit-plan') &&   
                                  <FaEdit
                                    onClick={() => toggleEditPlanForm(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                            }
                                  |
{hasPermission('delete-plan') &&   

                                  <AiFillDelete
                                    onClick={() => openDeletePlanModal(data)}
                                    className="s-24 m-1"
                                    style={{ color: "#000", cursor: "pointer" }}
                                  />
                          }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2>No Data</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

Plan.propTypes = {
  getPlans: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plan: state.plan,
});

export default connect(mapStateToProps, {
  getPlans,
  deletePlan,
})(Plan);
