export const LIVE_URl = "https://pharmacy.webspider.pk/api/v1";

export const SET_NAVBAR_TOGGLE = "SET_NAVBAR_TOGGLE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOG_OUT = "LOG_OUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";

export const GET_BUSINESS = "GET_BUSINESS";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";

export const GET_BANKS = "GET_BANKS";
export const  GET_BANK_DETAILS=" GET_BANK_DETAILS";
// export const GET_BANK_DETAILS="GET_BANK_DETAILS"
export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_REQUEST_DETAILS = "GET_BANK_REQUEST_DETAILS";



export const GET_SHOPS = "GET_SHOPS";
export const GET_SHOP_DETAILS = "GET_SHOP_DETAILS";

export const GET_STAFF = "GET_STAFF";
export const GET_STAFF_DETAILS = "GET_STAFF_DETAILS";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";

export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";

export const GET_PLANS = "GET_PLANS";
export const GET_PLAN_DETAILS = "GET_PLAN_DETAILS";


export const GET_STATEMENT = "GET_STATEMENT";
export const GET_STATEMENT_DETAILS = "GET_STATEMENT_DETAILS";


export const GET_BUYER = "GET_BUYER";
export const GET_BUYER_DETAILS = "GET_BUYER_DETAILS";

export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS"; 

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_DETAILS = "GET_DOCUMENTS_DETAILS";

export const GET_PERMISSIONS = "GET_PERMISSIONS";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_ROLE_INFO = "GET_ROLE_INFO";

export const GET_POP_SETTING = "GET_POP_SETTING";
export const GET_POP_DETAILS = "GET_POP_DETAILS";

export const GET_EMAILS = "GET_EMAILS";
export const GET_EMAIL_DETAILS="GET_EMAIL_DETAILS";

export const GET_INVOICE = "GET_INVOICE";