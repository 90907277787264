import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const BusinessDetails = ({ changeStep, data, setData, errors, setErrors }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const validate = () => {
    if (data?.business_name === "") {
      setErrors((prev) => ({
        ...prev,
        business_nameErr: "Please provide business name",
      }));
    }
    if (!data?.lisence_number) {
      setErrors((prev) => ({
        ...prev,
        lisence_numberErr: "Please provide license number",
      }));
    }
    if (!data?.contact_number) {
      setErrors((prev) => ({
        ...prev,
        contact_numberErr: "Please provide contact number",
      }));
    }
    if (!data?.business_email) {
      setErrors((prev) => ({
        ...prev,
        business_emailErr: "Please provide business email",
      }));
    }
    if (!data?.business_country) {
      setErrors((prev) => ({
        ...prev,
        business_countryErr: "Please provide business country",
      }));
    }
    if (!data?.business_city) {
      setErrors((prev) => ({
        ...prev,
        business_cityErr: "Please provide business city",
      }));
    }
    if (!data?.post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!data?.valid_till) {
      setErrors((prev) => ({
        ...prev,
        valid_tillErr: "Please provide valid till date",
      }));
    }
    if (!data?.alert_before) {
      setErrors((prev) => ({
        ...prev,
        alert_beforeErr: "Please provide alert before date",
      }));
    }
    if (!data?.about) {
      setErrors((prev) => ({
        ...prev,
        aboutErr: "Please provide about",
      }));
    }
    if (!data?.business_image) {
      setErrors((prev) => ({
        ...prev,
        business_imageErr: "Please provide business image",
      }));
    }

    if (
      !data?.business_name ||
      !data?.lisence_number ||
      !data?.contact_number ||
      !data?.business_email ||
      !data?.business_country ||
      !data?.business_city ||
      !data?.post_code ||
      !data?.valid_till ||
      !data?.alert_before ||
      !data?.about ||
      !data?.business_image
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, business_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, business_email: e.target.value }));
      setErrors((prev) => ({ ...prev, business_emailErr: "" }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("steps", "2");
      localStorage.setItem("data", JSON.stringify(data));
      changeStep(e, "2");
    }
  };
  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      business_name: "",
      lisence_number: "",
      contact_number: "",
      business_email: "",
      business_country: "",
      business_city: "",
      post_code: "",
      valid_till: "",
      alert_before: "",
      about: "",
      business_image: "",
    }));
  };

  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Business Name</label>
            <input
              style={{
                border: errors?.business_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="business_name"
              value={data?.business_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  business_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business name"
            />
            {errors?.business_nameErr && (
              <Typography className={classes.errorMsg}>
                {errors?.business_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>License Number</label>
            <input
              type="text"
              name="lisence_number"
              style={{
                border: errors?.lisence_numberErr && "1px solid #d32f2f",
              }}
              value={data.lisence_number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  lisence_numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="License number"
              required="required"
            />
            {errors?.lisence_numberErr && (
              <Typography className={classes.errorMsg}>
                {errors?.lisence_numberErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              style={{
                border: errors?.contact_numberErr && "1px solid #d32f2f",
              }}
              value={data?.contact_number}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  contact_numberErr: "",
                }));
              }}
              className="form-control"
              placeholder="Contact number"
              required="required"
            />
            {errors?.contact_numberErr && (
              <Typography className={classes.errorMsg}>
                {errors?.contact_numberErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Business email</label>
            <input
              type="text"
              name="business_email"
              style={{
                border: errors?.business_emailErr && "1px solid #d32f2f",
              }}
              value={data?.business_email}
              onChange={(e) => handleChangeEmail(e)}
              className="form-control"
              placeholder="Business email"
              required="required"
            />
            {errors?.business_emailErr && (
              <Typography className={classes.errorMsg}>
                {errors?.business_emailErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Business Country</label>
            <input
              type="text"
              name="business_country"
              style={{
                border: errors?.business_countryErr && "1px solid #d32f2f",
              }}
              value={data?.business_country}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  business_countryErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business country"
              required="required"
            />
            {errors?.business_countryErr && (
              <Typography className={classes.errorMsg}>
                {errors?.business_countryErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Business City</label>
            <input
              type="text"
              name="business_city"
              style={{
                border: errors?.business_cityErr && "1px solid #d32f2f",
              }}
              value={data?.business_city}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  business_cityErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business city"
              required="required"
            />
            {errors?.business_cityErr && (
              <Typography className={classes.errorMsg}>
                {errors?.business_cityErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Postal Code</label>
            <input
              type="text"
              name="post_code"
              style={{ border: errors?.post_codeErr && "1px solid #d32f2f" }}
              value={data?.post_code}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  post_codeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Postal code"
              required="required"
            />
            {errors?.post_codeErr && (
              <Typography className={classes.errorMsg}>
                {errors?.post_codeErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Validate Date</label>
            <input
              type="date"
              name="valid_till"
              style={{ border: errors?.valid_tillErr && "1px solid #d32f2f" }}
              value={data?.valid_till}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  valid_tillErr: "",
                }));
              }}
              className="form-control"
              placeholder="Validate date"
              required="required"
            />
            {errors?.valid_tillErr && (
              <Typography className={classes.errorMsg}>
                {errors?.valid_tillErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Alert Date</label>
            <input
              type="date"
              name="alert_before"
              style={{ border: errors?.alert_beforeErr && "1px solid #d32f2f" }}
              value={data?.alert_before}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  alert_beforeErr: "",
                }));
              }}
              className="form-control"
              placeholder="Alert date"
              required="required"
            />
            {errors?.alert_beforeErr && (
              <Typography className={classes.errorMsg}>
                {errors?.alert_beforeErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>About</label>
            <input
              type="text"
              name="about"
              style={{ border: errors?.aboutErr && "1px solid #d32f2f" }}
              value={data?.about}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  aboutErr: "",
                }));
              }}
              className="form-control"
              placeholder="About"
              required="required"
            />
            {errors?.aboutErr && (
              <Typography className={classes.errorMsg}>
                {errors?.aboutErr}
              </Typography>
            )}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Business Image</label>
            <input
              type="file"
              name="business_image"
              style={{
                border: errors?.business_imageErr && "1px solid #d32f2f",
              }}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  business_imageErr: "",
                }));
              }}
              className="form-control"
              placeholder="Business image"
              required="required"
            />
            {errors?.business_imageErr && (
              <Typography className={classes.errorMsg}>
                {errors?.business_imageErr}
              </Typography>
            )}
          </Col>
        </Row>
        <div className={classes.bottomFooter}>
          <CustomButton
            text={"NEXT"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading ||
              errors?.business_nameErr ||
              errors?.license_numberErr ||
              errors?.contact_numberErr ||
              errors?.business_emailErr ||
              errors?.business_countryErr ||
              errors?.business_cityErr ||
              errors?.post_codeErr ||
              errors?.valid_tillErr ||
              errors?.alert_beforeErr ||
              errors?.aboutErr ||
              errors?.business_imageErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};

export default BusinessDetails;
