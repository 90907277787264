import React, { useEffect, useState } from "react";
import { accountUpdate } from "../../actions/account";
import { getShops } from "../../actions/shops";
import { getBanks } from "../../actions/bank";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";
const EditAccount = ({
  accountUpdate,
  getShops,
  getBanks,
  shops: { allShops },
  bank: { allBanks },
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [errors, setErrors] = useState({
    account_nameErr: "",
    numberErr: "",
    ibanErr: "",
    currencyErr: "",
    shop_idErr: "",
    bank_idErr: "",
    statusErr: "",
  });

  const {
    account_nameErr,
    numberErr,
    ibanErr,
    currencyErr,
    shop_idErr,
    bank_idErr,
    statusErr,
  } = errors;

  useEffect(() => {
    getShops(loadingFunc);
    getBanks(loadingFunc);
  }, [getShops, getBanks]);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/account/${params.id}`);
      setData(result?.data?.data);
      setLoading(false);
    };
    fetchData();
  }, [params.id]);

  const validate = () => {
    if (!data?.account_name) {
      setErrors((prev) => ({
        ...prev,
        account_nameErr: "Please provide bank name",
      }));
    }
    if (!data?.number) {
      setErrors((prev) => ({
        ...prev,
        numberErr: "Please provide number",
      }));
    }
    if (!data?.iban) {
      setErrors((prev) => ({
        ...prev,
        ibanErr: "Please provide iban",
      }));
    }
    if (!data?.currency) {
      setErrors((prev) => ({
        ...prev,
        currencyErr: "Please provide currency",
      }));
    }
    if (!data?.shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!data?.bank_id) {
      setErrors((prev) => ({
        ...prev,
        bank_idErr: "Please select bank",
      }));
    }
    if (!data?.status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please select status",
      }));
    }

    if (
      !data?.account_name ||
      !data?.number ||
      !data?.iban ||
      !data?.currency ||
      !data?.shop_id ||
      !data?.bank_id ||
      !data?.status
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await accountUpdate(params?.id, data);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-accounts");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Bank Account <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="account_name"
                                  value={data?.account_name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      account_nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Account Name"
                                />
                              </div>
                              {account_nameErr && (
                                <p className="text-danger">{account_nameErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="number"
                                  name="number"
                                  value={data?.number}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      numberErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Number"
                                />
                              </div>
                              {numberErr && (
                                <p className="text-danger">{numberErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="iban"
                                  value={data?.iban}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      ibanErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="IBAN"
                                />
                              </div>
                              {ibanErr && (
                                <p className="text-danger">{ibanErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="currency"
                                  value={data?.currency}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      currencyErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Currency"
                                />
                              </div>
                              {currencyErr && (
                                <p className="text-danger">{currencyErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="shop_id"
                                  className="custom-select form-control"
                                  value={data?.shop_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      shop_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an shop
                                  </option>
                                  {allShops.map((data, i) => (
                                    <option key={i} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {shop_idErr && (
                                <p className="text-danger">{shop_idErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="bank_id"
                                  className="custom-select form-control"
                                  value={data?.bank_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      bank_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an bank
                                  </option>
                                  {allBanks.map((data, i) => (
                                    <option key={i} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {bank_idErr && (
                                <p className="text-danger">{bank_idErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="status"
                                  className="custom-select form-control"
                                  value={data?.status}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      statusErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an status
                                  </option>
                                  <option value="1">Active</option>
                                  <option value="0">In active</option>
                                </select>
                              </div>
                              {statusErr && (
                                <p className="text-danger">{statusErr}</p>
                              )}
                            </div>

                            <button
                              onClick={onSubmit}
                              className="btn btn-primary mt-4"
                              style={
                                loading ||
                                account_nameErr ||
                                numberErr ||
                                ibanErr ||
                                currencyErr ||
                                shop_idErr ||
                                bank_idErr ||
                                statusErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                            >
                              <i className="icon-arrow_forward mr-2"></i>Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditAccount.propTypes = {
  accountUpdate: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  bank: state.bank,
  account: state.account,
});

export default connect(mapStateToProps, {
  getShops,
  getBanks,
  accountUpdate,
})(EditAccount);
