import React, { useState } from "react";
import { planRegistration } from "../../actions/plan";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddPlan = ({ planRegistration }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    plan_name: "",
    description: "",
    no_of_users: "",
    start_date: "",
    end_date: "",
    fee: "",
    validity_type: "",
    validity_for: "",
  });
  const [errors, setErrors] = useState({
    plan_nameErr: "",
    descriptionErr: "",
    no_of_usersErr: "",
    start_dateErr: "",
    end_dateErr: "",
    feeErr: "",
    validity_typeErr: "",
    validity_forErr: "",
  });

  const {
    plan_name,
    description,
    no_of_users,
    start_date,
    end_date,
    fee,
    validity_type,
    validity_for,
  } = data;

  const {
    plan_nameErr,
    descriptionErr,
    no_of_usersErr,
    start_dateErr,
    end_dateErr,
    feeErr,
    validity_typeErr,
    validity_forErr,
  } = errors;

  const validate = () => {
    if (!plan_name) {
      setErrors((prev) => ({
        ...prev,
        plan_nameErr: "Please provide plan name",
      }));
    }
    if (!description) {
      setErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (!no_of_users) {
      setErrors((prev) => ({
        ...prev,
        no_of_usersErr: "Please provide users",
      }));
    }
    if (!start_date) {
      setErrors((prev) => ({
        ...prev,
        start_dateErr: "Please provide start date",
      }));
    }
    if (!end_date) {
      setErrors((prev) => ({
        ...prev,
        end_dateErr: "Please provide end date",
      }));
    }
    if (!fee) {
      setErrors((prev) => ({
        ...prev,
        feeErr: "Please provide price",
      }));
    }
    if (!validity_type) {
      setErrors((prev) => ({
        ...prev,
        validity_typeErr: "Please provide validate type",
      }));
    }
    if (!validity_for) {
      setErrors((prev) => ({
        ...prev,
        validity_forErr: "Please provide validity period",
      }));
    }

    if (
      !plan_name ||
      !description ||
      !no_of_users ||
      !start_date ||
      !end_date ||
      !fee ||
      !validity_type ||
      !validity_for
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await planRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-plans");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Add Plan <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="plan_name"
                                value={plan_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    plan_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Plan Name"
                              />
                            </div>
                            {plan_nameErr && (
                              <p className="text-danger">{plan_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="fee"
                                value={fee}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    feeErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Plan Price"
                              />
                            </div>
                            {feeErr && <p className="text-danger">{feeErr}</p>}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="description"
                                value={description}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    descriptionErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Description"
                              />
                            </div>
                            {descriptionErr && (
                              <p className="text-danger">{descriptionErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="no_of_users"
                                value={no_of_users}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    no_of_usersErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="No of Users"
                              />
                            </div>
                            {no_of_usersErr && (
                              <p className="text-danger">{no_of_usersErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Start Date</label>
                              <input
                                type="date"
                                name="start_date"
                                value={start_date}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    start_dateErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Start Date"
                              />
                            </div>
                            {start_dateErr && (
                              <p className="text-danger">{start_dateErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>End Date</label>
                              <input
                                type="date"
                                name="end_date"
                                value={end_date}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    end_dateErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="End Date"
                              />
                            </div>
                            {end_dateErr && (
                              <p className="text-danger">{end_dateErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="validity_type"
                                value={validity_type}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    validity_typeErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Validate Type"
                              />
                            </div>
                            {validity_typeErr && (
                              <p className="text-danger">{validity_typeErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="number"
                                name="validity_for"
                                value={validity_for}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    validity_forErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Validate For"
                              />
                            </div>
                            {validity_forErr && (
                              <p className="text-danger">{validity_forErr}</p>
                            )}
                          </div>
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              plan_nameErr ||
                              descriptionErr ||
                              no_of_usersErr ||
                              start_dateErr ||
                              end_dateErr ||
                              feeErr ||
                              validity_typeErr ||
                              validity_forErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddPlan.propTypes = {
  planRegistration: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plan: state.plan,
});

export default connect(mapStateToProps, {
  planRegistration,
})(AddPlan);
