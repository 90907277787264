import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bankRequestRegistration } from "../../actions/bank";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const AddBankRequest = ({
  closeAddBankRequestForm,
  bankRequestRegistration,
  getShops,
  shops: { allShops },
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    bank_name: "",
    sample_document: "",
    shop_id: "",
  });
  const [errors, setErrors] = useState({
    bank_nameErr: "",
    sample_documentErr: "",
    shop_idErr: "",
  });

  const { bank_name, sample_document, shop_id } = data;

  const { bank_nameErr, sample_documentErr, shop_idErr } = errors;

  const validate = () => {
    if (!bank_name) {
      setErrors((prev) => ({
        ...prev,
        bank_nameErr: "Please provide bank name",
      }));
    }
    if (!sample_document) {
      setErrors((prev) => ({
        ...prev,
        sample_documentErr: "Please provide document",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }

    if (!bank_name || !sample_document || !shop_id) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const dataForm = new FormData();
        dataForm.append("bank_name", bank_name);
        dataForm.append("sample_document", sample_document);
        dataForm.append("shop_id", shop_id);
        let response = await bankRequestRegistration(dataForm, loadingFunc);
        setLoading(false);
        if (response) {
          closeAddBankRequestForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      bank_name: "",
      sample_document: "",
      shop_id: "",
    }));
    setErrors((prev) => ({
      ...prev,
      bank_nameErr: "",
      sample_documentErr: "",
      shop_idErr: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={6}>
            <label>Bank Name</label>
            <input
              style={{
                border: bank_nameErr && "1px solid #d32f2f",
              }}
              type="text"
              name="bank_name"
              value={bank_name}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  bank_nameErr: "",
                }));
              }}
              className="form-control"
              placeholder="Bank name"
            />
            {bank_nameErr && (
              <Typography className={classes.errorMsg}>
                {bank_nameErr}
              </Typography>
            )}
          </Col>
          <Col sm={12} md={6}>
            <label>Document</label>
            <input
              style={{
                border: sample_documentErr && "1px solid #d32f2f",
              }}
              type="file"
              name="sample_document"
              value={sample_document}
              onChange={(e) => {
                onChangeFields(e);
                setErrors((prev) => ({
                  ...prev,
                  sample_documentErr: "",
                }));
              }}
              className="form-control"
            />
            {sample_documentErr && (
              <Typography className={classes.errorMsg}>
                {sample_documentErr}
              </Typography>
            )}
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Shop</label>
            <div className="select">
              <select
                name="shop_id"
                style={{ border: shop_idErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={shop_id}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    shop_idErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an shop
                </option>
                {allShops.map((data, i) => (
                  <option key={i} value={data._id}>
                    {data.name}
                  </option>
                ))}
              </select>
              {shop_idErr && (
                <Typography className={classes.errorMsg}>
                  {shop_idErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading || bank_nameErr || sample_documentErr || shop_idErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};
AddBankRequest.propTypes = {
  bankRequestRegistration: PropTypes.func.isRequired,
  getShops: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getShops,
  bankRequestRegistration,
})(AddBankRequest);
