import { GET_INVOICE, GET_INVOICES,GET_INVOICE_DETAILS } from "../actions/types";

const initialState = {
  allInvoices: [],
  invoices: [],
  invoiceDetails: null,
};

export default function invoice(state = initialState, action) {
  const { type, payload } = action;

  switch (action.type) {
    case GET_INVOICE:
      return {
        ...state,
        allInvoices: payload,
      };
      case GET_INVOICES:
        return {
          ...state,
          invoices: action.payload,
        };
      case GET_INVOICE_DETAILS:
        return {
          ...state,
          invoiceDetails: payload,
        };
    default:
      return state;
  }
}
