import React, { useState } from "react";
import {  buyerRegistration} from "../../actions/buyer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddBuyer = ({  buyerRegistration}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
   date:"",
    description: "",
   debit:"",
   credit:"",
   balance:""
  });
  const [errors, setErrors] = useState({
    dateErr: "",
    descriptionErr: "",
    debitErr: "",
    creditErr: "",
    balanceErr: "",
 
  });

  const {
    date,
    description,
    debit,
    credit,
    balance,
  } = data;

  const {
    dateErr,
    descriptionErr,
    debitErr,
    creditErr,
    balanceErr,
  } = errors;

  // const validate = () => {
  //   if (!date) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       dateErr: "Please provide date",
  //     }));
  //   }
  //   if (!description) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       descriptionErr: "Please provide description",
  //     }));
  //   }
  //   if (!debit) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       debitErrErr: "Please provide debit",
  //     }));
  //   }
  //   if (!credit) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       creditErrErr: "Please provide credit",
  //     }));
  //   }
  //   if (!balance) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       balanceErrErr: "Please provide balance",
  //     }));
  //   }
 

  //   if (
  //     !date ||
  //     !description ||
  //     !debit ||
  //     !credit ||
  //     !balance 
  
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // if (validate()) {
      setLoading(true);
      try {
        let response = await buyerRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-buyer");
        }
      } catch (err) {
        setLoading(false);
      }
    // }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Add Buyer <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="date"
                                name="date"
                                value={date}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    dateErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Date"
                              />
                            </div>
                            {dateErr && (
                              <p className="text-danger">{dateErr}</p>
                            )}
                          </div>


                          <div className="form-group">
                            <div className="form-line">
                            <label></label>
                              <input
                                type="text"
                                name="description"
                                value={description}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    descriptionErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Description"
                              />
                            </div>
                            {descriptionErr && (
                              <p className="text-danger">{descriptionErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                            <label>Debit</label>
                              <input
                                type="text"
                                name="debit"
                                value={debit}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    debitErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Debit"
                              />
                            </div>
                            {debitErr && (
                              <p className="text-danger">{debitErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Credit</label>
                              <input
                                type="text"
                                name="credit"
                                value={credit}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    creditErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="credit"
                              />
                            </div>
                            {creditErr && (
                              <p className="text-danger">{creditErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <label>Balance</label>
                              <input
                                type="text"
                                name="balance"
                                value={balance}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    balanceErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="End Date"
                              />
                            </div>
                            {balanceErr && (
                              <p className="text-danger">{balanceErr}</p>
                            )}
                          </div>

                          

                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            // style={
                            //   loading ||
                            //   dateErr ||
                            //   descriptionErr ||
                            //   creditErr ||
                            //   debitErr ||
                            //   balanceErr
                            
                            // }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> 
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddBuyer.propTypes = {
  buyerRegistration: PropTypes.func.isRequired,
  buyer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
 
  buyer: state.buyer,
});

export default connect(mapStateToProps, {
  buyerRegistration,
})(AddBuyer);
