import React, { useEffect, useState } from "react";
import "../../assets/css/globalStyle.css";
import Model from "../../components/global/Model";
import { deleteShop, getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaEye, FaEdit, FaUserPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import AddShop from "../../components/forms/AddShop";
import EditShop from "../../components/forms/EditShop";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import Loader from "../../components/global/Loader";

const Shops = ({ shops: { allShops }, toggleNavbar, getShops, deleteShop }) => {
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [showDeleteShopModal, setDeleteShopModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [shopData, setShopData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const toggleAddShopForm = () => {
    setToggleForm(true);
  };

  const closeAddShopForm = () => {
    setToggleForm(false);
  };

  const toggleEditShopForm = (data) => {
    setShopData(data);
    setToggleEditForm(true);
  };

  const closeEditShopForm = () => {
    setToggleEditForm(false);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE SHOP MODAL

  const openDeleteShopModal = (data) => {
    setShopData(data);
    setDeleteShopModal(true);
  };

  const closeDeleteShopModal = () => setDeleteShopModal(false);

  const deleteShopFunc = async (e) => {
    e.preventDefault();
    let res = await deleteShop(shopData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteShopModal();
      setShopData("");
    }
  };

  return (
    <>
      {/* ADD SHOP MODAL */}
      <Model
        openModel={toggleForm}
        handleClose={closeAddShopForm}
        bodyContent={<AddShop closeAddShopForm={closeAddShopForm} />}
        title="Add Shop"
      />

      {/* EDIT SHOP MODAL */}
      <Model
        openModel={toggleEditForm}
        handleClose={closeEditShopForm}
        bodyContent={
          <EditShop closeEditShopForm={closeEditShopForm} shopData={shopData} />
        }
        title="Edit Shop"
      />

      <DeleteModal
        show={showDeleteShopModal}
        onHide={closeDeleteShopModal}
        title="Delete Shop"
        bodyText="Are you sure, you want to delete the shop?"
        onClickNo={closeDeleteShopModal}
        onClickYes={(e) => deleteShopFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <div className={toggleNavbar ? "content-inner" : "content-inner active"}>
        <div className="page ">
          <header className="my-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h1 className="s-24">
                    <i
                      className="icofont-brand-china-unicom"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Shops Registration
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <div className="container-fluid my-3">
            <div className="box">
              <section className="paper-card">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="box">
                      <div
                        className="box-header"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <a
                          onClick={(e) => toggleAddShopForm(e)}
                          className="btn btn-sm btn-danger float-right mb-4"
                        >
                          Add New Shop
                        </a>
                      </div>
                      <div className="box-body no-padding">
                        {loading ? (
                          <Loader />
                        ) : allShops?.length > 0 ? (
                          <table className="table">
                            <tbody>
                              <tr>
                                <th style={{ width: "10px" }}>#</th>
                                <th className="text-left">Shop Name</th>
                                <th className="text-left">Shop Email</th>
                                <th className="text-left">Menpower</th>
                                <th style={{ width: "200px" }}>Action</th>
                              </tr>
                              {allShops?.map((shopsData, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td className="text-left">
                                    {shopsData?.name} <br />
                                    <small> {shopsData?.address}</small>
                                  </td>
                                  <td className="text-left">
                                    {shopsData?.shop_email}
                                  </td>
                                  <td>
                                    <div className="progress progress-xs">
                                      <div
                                        className="progress-bar progress-bar-danger"
                                        style={{ width: "55%" }}
                                      ></div>
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    {/* <FaUserPlus
                                      className="s-24 m-1"
                                      style={{
                                        color: "#000",
                                        cursor: "pointer",
                                      }}
                                    />
                                    | */}
                                    <FaEye
                                      className="s-24 m-1"
                                      style={{
                                        color: "#000",
                                        cursor: "pointer",
                                      }}
                                    />
                                    |
                                    <FaEdit
                                      onClick={() =>
                                        toggleEditShopForm(shopsData)
                                      }
                                      className="s-24 m-1"
                                      style={{
                                        color: "#000",
                                        cursor: "pointer",
                                      }}
                                    />
                                    |
                                    <AiFillDelete
                                      onClick={() =>
                                        openDeleteShopModal(shopsData)
                                      }
                                      className="s-24 m-1"
                                      style={{
                                        color: "#000",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <h2>No Data</h2>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Shops.propTypes = {
  getShops: PropTypes.func.isRequired,
  deleteShop: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getShops,
  deleteShop,
})(Shops);
