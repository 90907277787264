import { combineReducers } from "redux";
import toggling from "./toggling";
import auth from "./auth";
import business from "./business";
import shops from "./shops";
import emails from "./emails";
import pop from "./pop";
import bank from "./bank";
import staff from "./staff";
import role from "./role";
import vendor from "./vendor";
import customer from "./customer";
import account from "./account";
import plan from "./plan";
import document from "./document";
import invoice from "./invoice";
import statement from "./statement"
import buyer from "./buyer"
export default combineReducers({
  auth,
  business,
  shops,
  bank,
  staff,
  emails,
  pop,
  role,
  buyer,
  vendor,
  customer,
  account,
  plan,
  statement,
  document,
  toggling,
  invoice
});
