import { LIVE_URl, GET_ACCOUNTS, GET_ACCOUNT_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL BANKS

export const getAccounts = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/account`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_ACCOUNTS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

// BANK REGISTRATION

export const accountRegistration =
  (accountDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = accountDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/account`, body, config);

      if (res) {
        toast.success("Account Registration Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const accountUpdate =
  (accountId, accountDetails) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = accountDetails;
    try {
      const res = await axios.put(
        `${LIVE_URl}/account/${accountId}`,
        body,
        config
      );

      if (res) {
        toast.success("Account Updated Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// GET SHOP DETAILS
export const getAccountDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/account/${id}`);
    if (res) {
      dispatch({
        type: GET_ACCOUNT_DETAILS,
        payload: res?.data?.data,
      });
    }

    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// UPDATE BANK
export const updateBank = (bankId, bankDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = bankDetails;
  try {
    const res = await axios.put(`${LIVE_URl}/account/${bankId}`, body, config);

    if (res) {
      toast.success("bank Updated Successfully");
    }

    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
  }
};


// DELETE BANK

export const deleteAccount =
  (accountId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(
        `${LIVE_URl}/account/${accountId}`,
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getAccounts(deleteLoaderFunc));
        toast.success("Account Deleted Successfully");
      }

      return res;
    } catch (error) {
      deleteLoaderFunc(false);

      toast.error(error.response.data.message);
      return false;
    }
  };
 