import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import { getPermission, deletePermission } from "../../actions/role";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../../components/global/Loader";

const Permissions = ({
  role: { allPermissions },
  getPermission,
  deletePermission,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeletePermissionModal, setDeletePermissionModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [permissionData, setPermissiondata] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getPermission(loadingFunc);
  }, [getPermission]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };
  // DELETE PERMISSION MODAL

  const openDeletePermissionModal = (data) => {
    setPermissiondata(data);
    setDeletePermissionModal(true);
  };

  const closeDeletePermissionModal = () => setDeletePermissionModal(false);

  const deletePermissionFunc = async (e) => {
    e.preventDefault();
    let res = await deletePermission(permissionData?._id, deleteLoaderFunc);
    if (res) {
      closeDeletePermissionModal();
      setPermissiondata("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeletePermissionModal}
        onHide={closeDeletePermissionModal}
        title="Delete Permission"
        bodyText="Are you sure, you want to delete the Permission?"
        onClickNo={closeDeletePermissionModal}
        onClickYes={(e) => deletePermissionFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Permissions
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                    <Link
                      to="/dashboard/add-permission"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Permission
                    </Link>
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allPermissions?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Gaurd Name</th>
                            <th className="text-left">Name</th>
                            <th
                              style={{ width: "160px" }}
                              className="text-center"
                            >
                              Action
                            </th>
                          </tr>
                          {allPermissions?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.guard_name}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-center">
                                <Link
                                  to={`/dashboard/edit-permission/${data?._id}`}
                                >
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                                |
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() =>
                                    openDeletePermissionModal(data)
                                  }
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Permissions.propTypes = {
  getPermission: PropTypes.func.isRequired,
  deletePermission: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  getPermission,
  deletePermission,
})(Permissions);
