import { LIVE_URl, GET_DOCUMENTS, GET_DOCUMENTS_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

export const getDocuments = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/document`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_DOCUMENTS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);
    toast.error(error.response.data.message);
  }
};

export const getDocumentDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${LIVE_URl}/document/${id}`);
    if (res) {
      dispatch({
        type: GET_DOCUMENTS_DETAILS,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const documentRegistration =
  (documentDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const body = documentDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/document`, body, config);

      if (res) {
        dispatch(getDocuments(loadingFunc));
        toast.success("Document Registration Successfully");
      }
console.log(documentRegistration)
      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

export const documentUpdate =
  (documentId, documentDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body = documentDetails;
    try {
      const res = await axios.put(
        `${LIVE_URl}/document/${documentId}`,
        body,
        config
      );

      if (res) {
        dispatch(getDocuments(loadingFunc));
        toast.success("Document Updated Successfully");
      }

      return res;
    } catch (error) {
      toast.error(error.response.data.message);
      return false;
    }
  };

// DELETE BANK

export const deleteDocument =
  (documentId, deleteLoaderFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      deleteLoaderFunc(true);
      const res = await axios.delete(
        `${LIVE_URl}/document/${documentId}`,
        config
      );
      if (res) {
        deleteLoaderFunc(false);
        dispatch(getDocuments(deleteLoaderFunc));
        toast.success("Document Deleted Successfully");
      }

      return res;
    } catch (error) {
      deleteLoaderFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
