import React, { useEffect, useState } from "react";
import { vendorRegistration } from "../../actions/vendor";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import hasPermission from "../../utils/permisssions";
const AddVendor = ({ vendorRegistration, getShops, shops: { allShops } }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);

  const [data, setData] = useState({
    vendor_name: "",
    vendor_email: "",
    vendor_phone: "",
    address: "",
    post_code: "",
    city: "",
    country: "",
    shop_id: "",
  });
  const [errors, setErrors] = useState({
    vendor_nameErr: "",
    vendor_emailErr: "",
    vendor_phoneErr: "",
    addressErr: "",
    post_codeErr: "",
    cityErr: "",
    countryErr: "",
    shop_idErr: "",
  });

  const {
    vendor_name,
    vendor_email,
    vendor_phone,
    address,
    post_code,
    city,
    country,
    shop_id,
  } = data;

  const {
    vendor_nameErr,
    vendor_emailErr,
    vendor_phoneErr,
    addressErr,
    post_codeErr,
    cityErr,
    countryErr,
    shop_idErr,
  } = errors;

  const validate = () => {
    if (!vendor_name) {
      setErrors((prev) => ({
        ...prev,
        vendor_nameErr: "Please provide first name",
      }));
    }
    if (!vendor_email) {
      setErrors((prev) => ({
        ...prev,
        vendor_emailErr: "Please provide email",
      }));
    }
    if (!vendor_phone) {
      setErrors((prev) => ({
        ...prev,
        vendor_phoneErr: "Please provide phone number",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide address",
      }));
    }
    if (!post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }

    if (
      !vendor_name ||
      !vendor_email ||
      !vendor_phone ||
      !address ||
      !post_code ||
      !city ||
      !country ||
      !shop_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        vendor_emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, vendor_email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, vendor_email: e.target.value }));
      setErrors((prev) => ({ ...prev, vendor_emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await vendorRegistration(data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-vendors");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Add Vendor <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="vendor_name"
                                value={vendor_name}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    vendor_nameErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Vendor name"
                              />
                            </div>
                            {vendor_nameErr && (
                              <p className="text-danger">{vendor_nameErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="email"
                                name="vendor_email"
                                value={vendor_email}
                                onChange={(e) => handleChangeEmail(e)}
                                className="form-control"
                                placeholder="Vendor email"
                                required="required"
                              />
                            </div>
                            {vendor_emailErr && (
                              <p className="text-danger">{vendor_emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="vendor_phone"
                                value={vendor_phone}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    vendor_phoneErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Phone number"
                                required="required"
                              />
                            </div>
                            {vendor_phoneErr && (
                              <p className="text-danger">{vendor_phoneErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="country"
                                value={country}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    countryErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Country"
                                required="required"
                              />
                            </div>
                            {countryErr && (
                              <p className="text-danger">{countryErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="city"
                                style={{}}
                                value={city}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    cityErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="City"
                                required="required"
                              />
                            </div>
                            {cityErr && (
                              <p className="text-danger">{cityErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="post_code"
                                value={post_code}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    post_codeErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Postal code"
                                required="required"
                              />
                            </div>
                            {post_codeErr && (
                              <p className="text-danger">{post_codeErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                name="address"
                                value={address}
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    addressErr: "",
                                  }));
                                }}
                                className="form-control"
                                placeholder="Address"
                                required="required"
                              />
                            </div>
                            {addressErr && (
                              <p className="text-danger">{addressErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <select
                                name="shop_id"
                                className="custom-select form-control"
                                value={shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {shop_idErr && (
                              <p className="text-danger">{shop_idErr}</p>
                            )}
                          </div>
                          {
                                  hasPermission('add-vendor') &&
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              vendor_nameErr ||
                              vendor_emailErr ||
                              vendor_phoneErr ||
                              addressErr ||
                              post_codeErr ||
                              cityErr ||
                              countryErr ||
                              shop_idErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Submit
                          </button>
}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddVendor.propTypes = {
  getShops: PropTypes.func.isRequired,
  vendorRegistration: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staff,
  shops: state.shops,
});

export default connect(mapStateToProps, {
  vendorRegistration,
  getShops,
})(AddVendor);
