import React, { useEffect, useState } from "react";
import { updateCustomer } from "../../actions/customer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getShops } from "../../actions/shops";
import { getBusinesses } from "../../actions/business";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";
const EditCustomer = ({
  updateCustomer,
  getShops,
  getBusinesses,
  shops: { allShops },
  business: { allBusiness },
  customer: { customerDetails },
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    address: "",
    post_code: "",
    city: "",
    country: "",
    shop_id: "",
    business_id: "",
  });

  const [errors, setErrors] = useState({
    nameErr: "",
    customer_emailErr: "",
    customer_phoneErr: "",
    addressErr: "",
    post_codeErr: "",
    cityErr: "",
    countryErr: "",
    shop_idErr: "",
    business_idErr: "",
  });

  const {
    customer_name,
    customer_email,
    customer_phone,
    address,
    post_code,
    city,
    country,
    shop_id,
    business_id,
  } = data;

  const {
    nameErr,
    customer_emailErr,
    customer_phoneErr,
    addressErr,
    post_codeErr,
    cityErr,
    countryErr,
    shop_idErr,
    business_idErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
    getBusinesses(loadingFunc);
  }, [getShops, getBusinesses]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/customer/${params.id}`);
      setData({
        ...data,
        customer_name: result?.data?.data?.name,
        customer_email: result?.data?.data?.customer_email,
        customer_phone: result?.data?.data?.customer_phone,
        address: result?.data?.data?.address,
        post_code: result?.data?.data?.post_code,
        city: result?.data?.data?.city,
        country: result?.data?.data?.country,
        shop_id: result?.data?.data?.shop_id,
        business_id: result?.data?.data?.business_id,
      });
      setLoading(false);
    };
    fetchData();
  }, [params.id]);

  const validate = () => {
    if (!customer_name) {
      setErrors((prev) => ({
        ...prev,
        nameErr: "Please provide name",
      }));
    }
    if (!customer_email) {
      setErrors((prev) => ({
        ...prev,
        customer_emailErr: "Please provide email",
      }));
    }
    if (!customer_phone) {
      setErrors((prev) => ({
        ...prev,
        customer_phoneErr: "Please provide phone number",
      }));
    }
    if (!address) {
      setErrors((prev) => ({
        ...prev,
        addressErr: "Please provide address",
      }));
    }
    if (!post_code) {
      setErrors((prev) => ({
        ...prev,
        post_codeErr: "Please provide postal code",
      }));
    }
    if (!city) {
      setErrors((prev) => ({
        ...prev,
        cityErr: "Please provide city",
      }));
    }
    if (!country) {
      setErrors((prev) => ({
        ...prev,
        countryErr: "Please provide country",
      }));
    }
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!business_id) {
      setErrors((prev) => ({
        ...prev,
        business_idErr: "Please select business",
      }));
    }

    if (
      !customer_name ||
      !customer_email ||
      !customer_phone ||
      !address ||
      !post_code ||
      !city ||
      !country ||
      !shop_id ||
      !business_id
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    const emailRegex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
    const isValidEmail = emailRegex.test(e.target.value);

    setData((prev) => ({ ...prev, customer_email: e.target.value }));
    setErrors((prev) => ({
      ...prev,
      customer_emailErr: isValidEmail
        ? ""
        : e.target.value
        ? "Invalid email address"
        : "Email can't be empty",
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      let response = await updateCustomer(params?.id, data);
      navigate("/dashboard/all-customers");
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Edit Customer <span className="s-14">Request ID</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="animatedParent animateOnce">
          <div className="container-fluid my-3">
            <div className="row">
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="customer_name"
                                  value={customer_name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Customer name"
                                />
                              </div>
                              {nameErr && (
                                <p className="text-danger">{nameErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="email"
                                  name="customer_email"
                                  value={customer_email}
                                  onChange={(e) => handleChangeEmail(e)}
                                  className="form-control"
                                  placeholder="Customer email"
                                  required="required"
                                />
                              </div>
                              {customer_emailErr && (
                                <p className="text-danger">
                                  {customer_emailErr}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="customer_phone"
                                  value={customer_phone}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      customer_phoneErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Phone number"
                                  required="required"
                                />
                              </div>
                              {customer_phoneErr && (
                                <p className="text-danger">
                                  {customer_phoneErr}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="country"
                                  value={country}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      countryErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Country"
                                  required="required"
                                />
                              </div>
                              {countryErr && (
                                <p className="text-danger">{countryErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="city"
                                  style={{}}
                                  value={city}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      cityErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="City"
                                  required="required"
                                />
                              </div>
                              {cityErr && (
                                <p className="text-danger">{cityErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="post_code"
                                  value={post_code}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      post_codeErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Postal code"
                                  required="required"
                                />
                              </div>
                              {post_codeErr && (
                                <p className="text-danger">{post_codeErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="address"
                                  value={address}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      addressErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Address"
                                  required="required"
                                />
                              </div>
                              {addressErr && (
                                <p className="text-danger">{addressErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="shop_id"
                                  className="custom-select form-control"
                                  value={shop_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      shop_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an shop
                                  </option>
                                  {allShops.map((data, i) => (
                                    <option key={i} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {shop_idErr && (
                                <p className="text-danger">{shop_idErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="business_id"
                                  className="custom-select form-control"
                                  value={business_id}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      business_idErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an business
                                  </option>
                                  {allBusiness.map((data, i) => (
                                    <option key={i} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {business_idErr && (
                                <p className="text-danger">{business_idErr}</p>
                              )}
                            </div>
                            {
                            hasPermission('edit-customer') &&
                            <button
                              onClick={onSubmit}
                              className="btn btn-primary mt-4"
                              style={
                                loading ||
                                nameErr ||
                                customer_emailErr ||
                                customer_phoneErr ||
                                addressErr ||
                                post_codeErr ||
                                cityErr ||
                                countryErr ||
                                shop_idErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                            >
                              <i className="icon-arrow_forward mr-2"></i>Submit
                            </button>
}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <h3>Detail</h3>
                <hr />
                <p>
                  {" "}
                  Bussiness Name: Bussiness Name
                  <br />
                  Submited By: Requester Name
                  <br />
                  Ticket No: Auto increnment
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditCustomer.propTypes = {
  getShops: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
  business: state.business,
  customer: state.customer,
});

export default connect(mapStateToProps, {
  updateCustomer,
  getShops,
  getBusinesses,
})(EditCustomer);
