import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteShop, getShops } from "../../actions/shops";
import Loader from "../../components/global/Loader";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import hasPermission from "../../utils/permisssions";
const AllShops = ({ shops: { allShops }, getShops, deleteShop }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteShopModal, setDeleteShopModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [shopData, setShopData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getShops(loadingFunc);
  }, [getShops]);
console.log(allShops)
  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE SHOP MODAL

  const openDeleteShopModal = (data) => {
    setShopData(data);
    setDeleteShopModal(true);
  };

  const closeDeleteShopModal = () => setDeleteShopModal(false);

  const deleteShopFunc = async (e) => {
    e.preventDefault();
    let res = await deleteShop(shopData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteShopModal();
      setShopData("");
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeleteShopModal}
        onHide={closeDeleteShopModal}
        title="Delete Shop"
        bodyText="Are you sure, you want to delete the shop?"
        onClickNo={closeDeleteShopModal}
        onClickYes={(e) => deleteShopFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Shops
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {hasPermission('add-shop') && <Link
                      to="/dashboard/add-shop"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Shop
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allShops?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Shop Name</th>
                            <th className="text-left">Shop Email</th>
                            <th className="text-left">Menpower</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allShops?.map((shopsData, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">
                                {shopsData?.name} <br />
                                <small> {shopsData?.address}</small>
                              </td>
                              <td className="text-left">
                                {shopsData?.shop_email}
                              </td>
                              <td>
                                <div className="progress progress-xs">
                                  <div
                                    className="progress-bar progress-bar-danger"
                                    style={{ width: "55%" }}
                                  ></div>
                                </div>
                              </td>
                              <td className="text-right">
                                {/* <a href="shop_Add_bussiness.html">
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>{" "}
                                | */}
                                {hasPermission('edit-shop') &&

                                  <Link
                                    to={`/dashboard/edit-shop/${shopsData?._id}`}
                                  >
                                    <i className="s-24  icon-clipboard-edit"></i>{" "}
                                  </Link>
                                }

                                {hasPermission('delete-shop') &&


                                  (<i
                                    className="s-24 icon-trash-can"
                                    onClick={() => openDeleteShopModal(shopsData)}
                                    style={{ cursor: "pointer" }}
                                  ></i>)
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllShops.propTypes = {
  getShops: PropTypes.func.isRequired,
  deleteShop: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, {
  getShops,
  deleteShop,
})(AllShops);
