import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import BusinessDetails from "./BusinessDetails";
import UserDetails from "./UserDetails";

const AddBusiness = ({ closeAddBusinessForm }) => {
  const [step, setSteps] = useState(
    localStorage.getItem("steps") ? localStorage.getItem("steps") : "1"
  );

  const [data, setData] = useState({
    business_name: "",
    lisence_number: "",
    contact_number: "",
    business_email: "",
    business_country: "",
    business_city: "",
    post_code: "",
    valid_till: "",
    alert_before: "",
    about: "",
    business_image: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: "",
    city: "",
    country: "",
    owner_image: "",
  });
  const [errors, setErrors] = useState({
    business_nameErr: "",
    lisence_numberErr: "",
    contact_numberErr: "",
    business_emailErr: "",
    business_countryErr: "",
    business_cityErr: "",
    post_codeErr: "",
    valid_tillErr: "",
    alert_beforeErr: "",
    aboutErr: "",
    business_imageErr: "",
    first_nameErr: "",
    last_nameErr: "",
    emailErr: "",
    passwordErr: "",
    password_confirmErr: "",
    cityErr: "",
    countryErr: "",
    owner_imageErr: "",
  });

  const changeStep = (e, stepNumber) => {
    e.preventDefault();
    setSteps(stepNumber);
  };

  return (
    <>
      <ToastContainer autoClose={3000} position="bottom-center" />
      {step === "1" ? (
        <BusinessDetails
          changeStep={changeStep}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
        />
      ) : step === "2" ? (
        <UserDetails
          changeStep={changeStep}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          closeAddBusinessForm={closeAddBusinessForm}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddBusiness;
