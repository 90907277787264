import { makeStyles } from "@mui/styles";
import { Grid, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Icofont from "react-icofont";
import { Nav } from "react-bootstrap";
import InputField from "../global/InputField";
import InputFieldPassword from "../global/InputFieldPassword";
import CustomButton from "../global/Button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { userAuthentication } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/pharmacy.jpg";

const useStyles = makeStyles({
  gridContainer: {
    backgroundColor: " #fff !important",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },

  form: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff !important",

    "@media (max-width: 600px)": {
      height: "65vh",
    },
  },
  boxForm: {
    width: "60%",
    boxShadow: "0 1px 15px 1px rgb(52 40 104 / 8%)",
    // boxShadow:
    //   "0 4px 6px rgba(33, 150, 243, 0.5), 0 6px 10px rgba(33, 150, 243, 0.3)",
    padding: "3rem",
    "@media (max-width: 768px)": {
      width: "100%",
      boxShadow: "none",
    },
  },

  formHeading: {
    fontWeight: "500 !important",
    fontSize: "1.8rem !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginBottom: "10px !important",
    color: " #2c304d !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  formSubtitle: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    color: "var(--text-color) !important",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  inputDiv: {
    marginBottom: "40px",
  },
  inputDivBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },

  sideBox: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent !important",

    "@media (max-width: 600px)": {
      height: "fit-content",
      padding: "60px",
    },
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
  forgetPassword: {
    padding: "0",
    width: "fit-content",
    textDecoration: "underline !important",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "120%",
    textTransform: "capitalize",
    margin: "15px 0",
    color: "#9e9e9e!important",
  },
});

const Login = ({ userAuthentication }) => {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    emailErr: "",
    passwordErr: "",
  });

  const { email, password } = values;
  const { emailErr, passwordErr } = errors;

  const validate = () => {
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }

    if (!email || !password) {
      return false;
    }
    return true;
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setValues((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setValues((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(() => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      try {
        const data = await userAuthentication(values, navigateTo);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <div className="light">
        <div id="app">
          <main>
            <div id="primary" className="p-t-b-100 height-full">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 mx-md-auto">
                    <div className="shadow r-10">
                      <div className="row grid">
                        <div className="col-md-5 white p-5">
                          <div className="mb-5">
                            <img
                              src="assets/img/basic/logo.png"
                              width="60"
                              alt=""
                            />
                          </div>

                          <form className="form-material" action="index.html">
                            <div className="body">
                              <div className="form-group form-float">
                                <div
                                  className="form-line"
                                  style={{
                                    borderBottom: emailErr
                                      ? "1px solid  #d32f2f"
                                      : "1px solid #e1e8ee",
                                  }}
                                >
                                  <label className="form-label m-0">Email</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={values.email}
                                    onChange={(e) => handleChangeEmail(e)}
                                  />
                                </div>
                                {emailErr ? (
                                  <Typography className={classes.errorMsg}>
                                    {emailErr}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="form-line"
                                  style={{
                                    borderBottom: passwordErr
                                      ? "1px solid  #d32f2f"
                                      : "1px solid #e1e8ee",
                                  }}
                                >
                                  <label className="form-label m-0">
                                    Password
                                  </label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    value={values.password}
                                    onChange={({ target }) => {
                                      setValues((prev) => ({
                                        ...prev,
                                        password: target.value,
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        passwordErr: "",
                                      }));
                                    }}
                                    onClick={handleClickShowPassword}
                                  />
                                </div>
                                {passwordErr ? (
                                  <Typography className={classes.errorMsg}>
                                    {passwordErr}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </div>
                              <a
                                href="#"
                                onClick={(e) => onSubmit(e)}
                                className="btn btn-primary btn-sm pl-4 pr-4"
                              >
                                {" "}
                                Login
                              </a>

                              <div className="pt-5 pb-5">
                                <a href="/forgetPassword" className="grey-text">
                                  <i className="icon-add"></i> Forgot Password?
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-md-7 blue p-5 text-white">
                          <i className="icon-circle-o s-36"></i>
                          <h1 className="mt-3">Are You Ready?</h1>

                          <div className="pt-3 mb-5">
                            <p>
                              Lorem ipsum dolor sit amet, sapiente tenetur ut,
                              veritatis.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <div className="control-sidebar-bg shadow white fixed"></div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  userAuthentication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  userAuthentication,
})(Login);
