import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modelTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <i className="icofont-close"></i>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Model(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.openModel}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className={classes.modelTitle}
          onClose={props.handleClose}
        >
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent>{props.bodyContent}</DialogContent>
      </Dialog>
    </div>
  );
}
