import { LIVE_URl } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// BANK REGISTRATION

export const updateSutemSetting =
  (settinId, data, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    loadingFunc(true);
    try {
      const res = await axios.put(
        `${LIVE_URl}/settings/${settinId}`,
        data,
        config
      );

      if (res) {
        loadingFunc(false);
        toast.success("System Setting Updateed Successfully");
      }
      return res;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
      return false;
    }
  };
