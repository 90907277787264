import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getShops } from "../../actions/shops";
import {  getPop,popRegistration} from "../../actions/pop_settings";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { LIVE_URl } from "../../actions/types";
const POPSettings = ({
  
  getShops,
  popRegistration,
  getPop,
  shops: { allShops },

}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  
  const loadingFunc = () => {
    setLoading();
  };

  useEffect(() => {
    getPop(loadingFunc);
    getShops(loadingFunc);
   

  }, [getShops,getPop]);
console.log(getPop)
  const [data, setData] = useState({
    shop_id: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    shop_idErr: "",
    emailErr: "",
    passwordErr: "",
  });

  const {
    shop_id,
    email,
    password,
  } = data;

  const {
    shop_idErr,
    emailErr,
    passwordErr,
  } = errors;

  const validate = () => {
    if (!shop_id) {
      setErrors((prev) => ({
        ...prev,
        shop_idErr: "Please select shop",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(email)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: email ? "Invalid email address" : "Email can't be empty",
      }));
    }
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: "Please provide password",
      }));
    }


    if (
     
      !email ||
      !password||
      !shop_id 
    ) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    if (!/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        emailErr: e.target.value
          ? "Invalid email address"
          : "Email can't be empty",
      }));
      setData((prev) => ({ ...prev, email: e.target.value }));
    } else {
      setData((prev) => ({ ...prev, email: e.target.value }));
      setErrors((prev) => ({ ...prev, emailErr: "" }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        var myHeaders = new Headers();
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        var formdata = new FormData();
        formdata.append("shop_id", shop_id);
        formdata.append("email", email);
        formdata.append("password", password);
        axios.post(`${LIVE_URl}/pop`, formdata, myHeaders,config)
          .then(function ({ response }) {
            console.log(response)
            setLoading(false);
      
            navigate("/dashboard/all-pop");
      
          });
      
      }catch (err) {
        setLoading(true);
      }
    }
  };
  
  // const handleCompanySubmit = () => {
  //   const data = {
  //   shop_id: shop_id,
  //     email: email,
  //    password:password
  //   };
  // }
  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                POP Settings
                {/* <span className="s-14">Request ID</span> */}
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body b-b">
                  <form className="form-material">
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                        <div className="form-group">
                            <div className="form-line">
                              <select
                                name="shop_id"
                                className="custom-select form-control"
                                value={data?.shop_id}
                                required="required"
                                onChange={(e) => {
                                  onChangeFields(e);
                                  setErrors((prev) => ({
                                    ...prev,
                                    shop_idErr: "",
                                  }));
                                }}
                              >
                                <option value="" disabled={true}>
                                  Select an shop
                                </option>
                                {allShops.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {shop_idErr && (
                              <p className="text-danger">{shop_idErr}</p>
                            )}
                          </div>
<div className="form-group">
                            <div className="form-line">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                name="email"
                                value={data?.email}
                                onChange={(e) => handleChangeEmail(e)}
                                placeholder="Email"
                              />
                            </div>
                            {emailErr && (
                              <p className="text-danger">{emailErr}</p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="form-line">
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                autoComplete="off"
                                value={data?.password}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                  }));
                                  if (Number(e.target.value.length) < 8) {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr:
                                        "Password must be atleast 8 characters",
                                    }));
                                  } else {
                                    setErrors((prev) => ({
                                      ...prev,
                                      passwordErr: "",
                                    }));
                                  }
                                }}
                                placeholder="Password"
                              />
                            </div>
                            {passwordErr && (
                              <p className="text-danger">{passwordErr}</p>
                            )}
                          </div>
                         

                          <button
                            // onClick={() => {
                            //   handleCompanySubmit();
                            //   // ChangeShowAddCompanyOrIndividualModal(false);
                            // }}
                            onClick={onSubmit}
                            className="btn btn-primary mt-4"
                            style={
                              loading ||
                              emailErr ||
                              passwordErr ||
                              shop_idErr
                                ? { pointerEvents: "none" }
                                : null
                            }
                          >
                            <i className="icon-arrow_forward mr-2"></i>Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="col-md-5">
              <h3>Detail</h3>
              <hr />
              <p>
                {" "}
                Bussiness Name: Bussiness Name
                <br />
                Submited By: Requester Name
                <br />
                Ticket No: Auto increnment
                <br />
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

POPSettings.propTypes = {
  getShops: PropTypes.func.isRequired,
  getPop:PropTypes.func.isRequired,
  popRegistration:PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
  shops: state.shops,
  // popRegistration:state.pop,
  pop:state.pop,
});

export default connect(mapStateToProps, { 
  popRegistration,
  getShops,
  getPop
  
  
})(POPSettings);
