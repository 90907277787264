import React, { Suspense, useState } from "react";
// import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Icofont from "react-icofont";
import ErrorPage from "../pages/ErrorPage";
import ForgetPassword from "../components/autherization/ForgetPassword";
import { ToastContainer } from "react-toastify";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/autherization/Login";
import Shops from "../pages/Shops/Shops";
import store from "../store";
import { Provider } from "react-redux";
import Business from "../pages/Business/Business";
import setAuthToken from "../utils/setAuthToken";
import Banks from "../pages/Banks/Banks";
import Staff from "../pages/Staff/Staff";
import BankRequests from "../pages/Banks/BankRequests";
import Vendor from "../pages/Vendor/Vendor";
import Customer from "../pages/Customer/Customer";
import Accounts from "../pages/Accounts/Accounts";
import Plan from "../pages/Plans/Plan";
import Document from "../pages/Documents/Document";
import BankDocuments from "../pages/BankDocument/BankDocuments";
import SaleDocument from "../pages/SaleDocument/SaleDocument";
import CostDocument from "../pages/CostDocument/CostDocument";
import BankDocumentDetail from "../pages/BankDocument/BankDocumentDetail";
import AllShops from "../pages/Shops/AllShops";
import AddShop from "../pages/Shops/AddShop";
import AllBanks from "../pages/Banks/AllBanks";
import AllBusiness from "../pages/Business/AllBusiness";
import AllStaff from "../pages/Staff/AllStaff";
import AllAccounts from "../pages/Accounts/AllAccounts";
import AllBankRequests from "../pages/Banks/AllBankRequests";
import AllVendor from "../pages/Vendor/AllVendor";
import AllCustomer from "../pages/Customer/AllCustomer";
import AllPlans from "../pages/Plans/AllPlans";
import FileConvertBank from "../pages/BankDocument/FileConvertBank";
import AddBank from "../pages/Banks/AddBank";
import FileConvertSale from "../pages/SaleDocument/FileConvertSale";
import FileConvertCost from "../pages/CostDocument/FileConvertCost";
import AddBusiness from "../pages/Business/AddBusiness";
import AddBankRequest from "../pages/Banks/AddBankRequest";
import AddAccount from "../pages/Accounts/AddAccount";
import AddVendor from "../pages/Vendor/AddVendor";
import AddCustomer from "../pages/Customer/AddCustomer";
import AddPlan from "../pages/Plans/AddPlan";
import AddStaff from "../pages/Staff/AddStaff";
import EditBusiness from "../pages/Business/EditBusiness";
import EditShop from "../pages/Shops/EditShop";
import EditBank from "../pages/Banks/EditBank";
import EditBankRequest from "../pages/Banks/EditBankRequest";
import EditAccount from "../pages/Accounts/EditAccount";
import EditVendor from "../pages/Vendor/EditVendor";
import EditCustomer from "../pages/Customer/EditCustomer";
import EditPlan from "../pages/Plans/EditPlan";
import EmailPiping from "../pages/EmailPiping/Email";
import EditStaff from "../pages/Staff/EditStaff";
import SystemSetting from "../pages/SystemSetting/SystemSetting";
import RolesAndPermissionAssignment from "../pages/RollManagment/RolesAndPermissionAssignment";
import RolesAndPermissions from "../pages/RollManagment/RolesAndPermissions";
import Roles from "../pages/RollManagment/Roles";
import AddRole from "../pages/RollManagment/AddRole";
import Permissions from "../pages/RollManagment/Permissions";
import AddPermission from "../pages/RollManagment/AddPermission";
import EditRole from "../pages/RollManagment/UpdateRole";
import UpdatePermission from "../pages/RollManagment/UpdatePermission";
import Invoice from "../pages/Invoice/Invoice";
import InvoiceReports from "../pages/Reports/InvoiceReports";
import StatementReports from "../pages/Reports/StatementReports";
import BuyerReports from "../pages/Reports/BuyerReports";
import AddInvoice from "../pages/Invoice/AddInvoice";
import EditInvoice from "../pages/Invoice/EditInvoice";
import POPSettings from "../pages/EmailPiping/Add_Pop";
import AllPop from "../pages/EmailPiping/All_Pop";
import EditPop from "../pages/EmailPiping/Edit_Pop";
import Converted from "../pages/EmailPiping/Converted";
import StatementConverted from "../pages/EmailPiping/StatementConverted";
import BuyerConverted from "../pages/EmailPiping/BuyerConverted";
import EmailView from "../pages/EmailPiping/EmailView"; 
import Statement from "../pages/Statement/Statement";
// import AddStatement from "../pages/Statement/AddStatement";
// import EditStatement from "../pages/Statement/EditStatement";
import Buyer from "../pages/Buyer/Buyer";
import EditBuyer from "../pages/Buyer/EditBuyer";
import AddBuyer from "../pages/Buyer/AddBuyer";

export default function RouterPage() {
  const [toggleNavbar, setToggleNavbar] = useState(true);

  const changeToggle = () => {
    setToggleNavbar(() => !toggleNavbar);
  };

  if (sessionStorage.token) {
    setAuthToken(sessionStorage.token);
  }
  // const userPermissions = useSelector((state) => state.auth.user.permissions);

  // // Function to check if the user has permission to access a specific route
  // const hasPermission = (permission) => userPermissions.includes(permission);

  return (
    <>
      <Provider store={store}>
        <ToastContainer autoClose={3000} position="bottom-center" />
        <Router>
          <Suspense
            fallback={
              <>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress style={{ backgroundColor: "#454545" }} />
                </Box>
                <div
                  style={{
                    textAlign: "center",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Icofont icon="spinner" size="3" spin="true" />
                  </div>
                </div>
              </>
            }
          >
            <Routes>
            {/* {hasPermission("view_stats") && (
          <Route path="stats" element={<Stats />} />
        )} */}
        {/* {hasPermission("delete-user") && (
          <Route exact path="email_pipping" element={<EmailPiping />} />
        )} */}
              <Route exact path="/" element={<Login />} />
              <Route
                exact
                path="/forgetPassword"
                element={<ForgetPassword />}
              />
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      changeToggle={changeToggle}
                      toggleNavbar={toggleNavbar}
                    />
                  }
                >
                  <Route exact path="businesses" element={<Business />} />
                  <Route exact path="add-business" element={<AddBusiness />} />
                  <Route
                    exact
                    path="edit-business/:id"
                    element={<EditBusiness />}
                  />
                  <Route
                    exact
                    path="all-businesses"
                    element={<AllBusiness />}
                  />
                  <Route exact path="all-shops" element={<AllShops />} />
                  <Route exact path="add-shop" element={<AddShop />} />
                  <Route exact path="edit-shop/:id" element={<EditShop />} />
                  <Route exact path="shops" element={<Shops />} />
                  <Route exact path="banks" element={<Banks />} />
                  <Route exact path="all-banks" element={<AllBanks />} />
                  <Route exact path="add-bank" element={<AddBank />} />
                  <Route exact path="edit-bank/:id" element={<EditBank />} />
                  <Route exact path="bank-request" element={<BankRequests />} />
                  <Route
                    exact
                    path="add-bank-request"
                    element={<AddBankRequest />}
                  />
                  <Route
                    exact
                    path="edit-bank-request/:id"
                    element={<EditBankRequest />}
                  />
                  <Route
                    exact
                    path="all-bank-requests"
                    element={<AllBankRequests />}
                  />
                  <Route exact path="vendor" element={<Vendor />} />
                  <Route exact path="all-vendors" element={<AllVendor />} />
                  <Route exact path="add-vendor" element={<AddVendor />} />
                  <Route
                    exact
                    path="edit-vendor/:id"
                    element={<EditVendor />}
                  />
                  <Route exact path="customer" element={<Customer />} />
                  <Route exact path="all-customers" element={<AllCustomer />} />
                  <Route exact path="add-customer" element={<AddCustomer />} />
                  <Route
                    exact
                    path="edit-customer/:id"
                    element={<EditCustomer />}
                  />
                  <Route exact path="accounts" element={<Accounts />} />
                  <Route exact path="all-accounts" element={<AllAccounts />} />
                  <Route exact path="add-account" element={<AddAccount />} />
                  <Route
                    exact
                    path="edit-account/:id"
                    element={<EditAccount />}
                  />
                  <Route exact path="plans" element={<Plan />} />
                  <Route exact path="all-plans" element={<AllPlans />} />
                  <Route exact path="add-plan" element={<AddPlan />} />
                  <Route exact path="edit-plan/:id" element={<EditPlan />} />
                  <Route exact path="documents" element={<Document />} />
                  <Route
                    exact
                    path="bankDocuments"
                    element={<BankDocuments />}
                  />
                  <Route
                    exact
                    path="file-convert-bank"
                    element={<FileConvertBank />}
                  />
                  <Route
                    exact
                    path="bankDocumentsDetails/:id"
                    element={<BankDocumentDetail />}
                  />
                  <Route
                    exact
                    path="saleDocuments"
                    element={<SaleDocument />}
                  />
                  <Route
                    exact
                    path="file-convert-sale"
                    element={<FileConvertSale />}
                  />
                  <Route
                    exact
                    path="costDocuments"
                    element={<CostDocument />}
                  />
                  <Route
                    exact
                    path="file-convert-cost"
                    element={<FileConvertCost />}
                  />
                  <Route exact path="invoice-reports" element={<InvoiceReports />} />
                  <Route exact path="statement-reports" element={<StatementReports />} />
                  <Route exact path="buyer-reports" element={<BuyerReports />} />
                  <Route exact path="staff" element={<Staff />} />
                  <Route exact path="all-staff" element={<AllStaff />} />
                  <Route exact path="add-staff" element={<AddStaff />} />
                  <Route exact path="edit-staff/:id" element={<EditStaff />} />
                  <Route
                    exact
                    path="roles-and-permission"
                    element={<RolesAndPermissionAssignment />}
                  />
                  <Route
                    exact
                    path="edit-user/:id"
                    element={<RolesAndPermissions />}
                  />
                  <Route exact path="all-roles" element={<Roles />} />
                  <Route exact path="add-role" element={<AddRole />} />
                  <Route
                    exact
                    path="edit-role/:id"
                    element={<EditRole />}
                  />
                  <Route
                    exact
                    path="all-permissions"
                    element={<Permissions />}
                  />
                  <Route
                    exact
                    path="add-permission"
                    element={<AddPermission />}
                  />
                  <Route
                    exact
                    path="edit-permission/:id"
                    element={<UpdatePermission />}
                  />
                  <Route exact path="email_pipping" element={<EmailPiping />} />
                  <Route exact path="pop-settings" element={<POPSettings/>} />
                  <Route exact path="all-pop" element={<AllPop/>} />
                  <Route exact path="edit-pop/:id" element={<EditPop/>} />
                  <Route exact path="converted/:id" element={<Converted/>} />
                  <Route exact path="statementconverted/:id" element={<StatementConverted/>} />
                  <Route exact path="buyerconverted/:id" element={<BuyerConverted/>} />
                  <Route exact path="emailview/:id" element={<EmailView/>} />
                  <Route
                    exact
                    path="all-invoice"
                    element={<Invoice />}
                  />
                  <Route
                    exact
                    path="add-invoice"
                    element={<AddInvoice />}
                  />
                  <Route
                    exact
                    path="edit-invoice/:id"
                    element={<EditInvoice />}
                  />
                    <Route
                    exact
                    path="all-statement"
                    element={<Statement />}
                  />
                  {/* <Route
                    exact
                    path="add-statement"
                    element={<AddStatement />}
                  />
                  <Route
                    exact
                    path="edit-statement/:id"
                    element={<EditStatement />}
                  /> */}
                  
                  <Route
                    exact
                    path="all-buyer"
                    element={<Buyer/>}
                  />
                  <Route
                    exact
                    path="add-buyer"
                    element={<AddBuyer/>}
                  />
                  <Route
                    exact
                    path="edit-buyer/:id"
                    element={<EditBuyer />}
                  />
                  <Route
                    exact
                    path="system-setting"
                    element={<SystemSetting />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </>
  );
}
