import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import { getRoles, deleteRole ,} from "../../actions/role";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../../components/global/Loader";

const Roles = ({ role: { allRoles }, getRoles, deleteRole }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteRoleModal, setDeleteRoleModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [roleData, setRoledata] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getRoles(loadingFunc);
  }, [getRoles]);

  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE Role MODAL
  console.log(allRoles)
  const openDeleteRoleModal = (data) => {
    setRoledata(data);

    setDeleteRoleModal(true);
  };
 
  const closeDeleteRoleModal = () => setDeleteRoleModal(false);

  const deleteRoleFunc = async (e) => {
    e.preventDefault();
    let res = await deleteRole(roleData?._id, deleteLoaderFunc);
  
    if (res) {
      closeDeleteRoleModal();
      setRoledata("");
    }
 
  };
  return (
    <>
      <DeleteModal
        show={showDeleteRoleModal}
        onHide={closeDeleteRoleModal}
        title="Delete Role"
        bodyText="Are you sure, you want to delete the Role?"
        onClickNo={closeDeleteRoleModal}
        onClickYes={(e) => deleteRoleFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Roles
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                    <Link
                      to="/dashboard/add-role"
                      className="btn btn-sm btn-danger float-right mb-4"
                    >
                      Add New Role
                    </Link>
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allRoles?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Gaurd Name</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Permissions</th>
                            <th
                              style={{ width: "160px" }}
                              className="text-center"
                            >
                              Action
                            </th>
                          </tr>
                          {allRoles?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">{data?.guard_name}</td>
                              <td className="text-left">{data?.name}</td>
                              <td className="text-left">
                                {data?.permission_ids
                                  ? data?.permission_ids?.length
                                  : "0"}
                              </td>
                              <td className="text-center">
                                <Link to={`/dashboard/edit-role/${data?._id}`}>
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>
                                |
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeleteRoleModal(data)}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Roles.propTypes = {
  getRoles: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps, {
  getRoles,
  deleteRole,
})(Roles);