import React, { useEffect, useState } from "react";
import { deleteStaff, getStaff   } from "../../actions/staff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteModal from "../../components/global/DeleteModal";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import Loader from "../../components/global/Loader";
import hasPermission from "../../utils/permisssions";
const AllStaff = ({ staff: { allStaffs }, getStaff, deleteStaff }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteStaffModal, setDeleteStaffModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [staffData, setStaffData] = useState("");

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    getStaff(loadingFunc);
  }, [getStaff]);
console.log(allStaffs)
  const deleteLoaderFunc = (key) => {
    setDeleteLoader(key);
  };

  // DELETE STAFF MODAL

  const openDeleteStaffModal = (data) => {
    setStaffData(data);
    setDeleteStaffModal(true);
  };

  const closeDeleteStaffModal = () => setDeleteStaffModal(false);

  const deleteStaffFunc = async (e) => {
    e.preventDefault();
    let res = await deleteStaff(staffData?._id, deleteLoaderFunc);
    if (res) {
      closeDeleteStaffModal();
      setStaffData("");
    }
  };
  return (
    <>
      <DeleteModal
        show={showDeleteStaffModal}
        onHide={closeDeleteStaffModal}
        title="Delete Staff"
        bodyText="Are you sure, you want to delete the staff?"
        onClickNo={closeDeleteStaffModal}
        onClickYes={(e) => deleteStaffFunc(e)}
        deleteLoader={deleteLoader}
        loader={
          <Icofont
            icon="spinner"
            style={{ fontSize: "1.2rem", marginLeft: "5px" }}
            spin="true"
          />
        }
        style={deleteLoader ? { pointerEvents: "none" } : null}
      />
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Staff List<span className="s-14">Business Name</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid my-3">
        <div className="box">
          <section className="paper-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="box">
                  <div className="box-header">
                  {hasPermission('add-staff') && 
                  <Link
                      to="/dashboard/add-staff"
                      className="btn btn-sm btn-danger   float-right mb-4"
                    >
                      Add New Staff Member
                    </Link>
}
                  </div>
                  <div className="box-body no-padding">
                    {loading ? (
                      <Loader />
                    ) : allStaffs?.length > 0 ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}>#</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Type</th>
                            <th style={{ width: "160px" }}>Action</th>
                          </tr>
                          {allStaffs?.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-left">
                                {data?.user?.name} <br />
                                <small> {data?.user?.city} </small>
                              </td>
                              <td>
                                <div className="text-left">
                                  {data?.user?.email}
                                </div>
                              </td>

                              <td>
                                <div className="text-left">
                                  {data?.user?.type}
                                </div>
                              </td>

                              <td className="text-right">
                                {/* <a href="profile_Shop_staff.html">
                                  {" "}
                                  <i className="s-24 icon-eye"> </i>{" "}
                                </a>
                                | */}
                                  {hasPermission('edit-staff') &&
                                <Link to={`/dashboard/edit-staff/${data?._id}`}>
                                  <i className="s-24  icon-clipboard-edit"></i>{" "}
                                </Link>}{" "}

                                |
                                {hasPermission('delete-staff') &&
                                <i
                                  className="s-24 icon-trash-can"
                                  onClick={() => openDeleteStaffModal(data)}
                                  style={{ cursor: "pointer" }}
                                ></i>
}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h2>No Data</h2>
                    )}

                    <div className="box-tools">
                      <ul className="pagination pagination-sm no-margin float-right">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            «
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

AllStaff.propTypes = {
  getStaff: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  staff: state.staff,
});

export default connect(mapStateToProps, {
  getStaff,
  deleteStaff,
})(AllStaff);
