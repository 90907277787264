import { GET_EMAILS,GET_EMAIL_DETAILS  } from "../actions/types";

const initialState = {
  allEmails: [],
  emailDetails: null,
  
};

export default function emails(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAILS:
      return {
        ...state,
        allEmails: payload,
      };
      case GET_EMAIL_DETAILS:
        return {
          ...state,
          emailDetails: payload,
        };
    default:
      return state;
  }
}
