// GET ALL COURSES

import { LIVE_URl, GET_STAFF,GET_STAFF_DETAILS } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// GET ALL STAFF

export const getStaff = (loadingFunc) => async (dispatch) => {
  try {
    loadingFunc(true);
    const res = await axios.get(`${LIVE_URl}/staff`);
    if (res) {
      loadingFunc(false);
      dispatch({
        type: GET_STAFF,
        payload: res?.data?.data,
      });
    }
    return true;
  } catch (error) {
    loadingFunc(false);

    toast.error(error.response.data.message);
  }
};

// STAFF REGISTRATION

export const staffRegistration =
  (staffDetails, loadingFunc) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const body = staffDetails;
    try {
      const res = await axios.post(`${LIVE_URl}/staff`, body, config);

      if (res) {
        toast.success("Staff Registration Successfully");
      }

      return res;
    } catch (error) {
    toast.error(error.response.data.message);
      return false;
    }
  };
  //   GET STAFF DETAIL
  export const getStaffDetails = (id, loadingFunc) => async (dispatch) => {
    try {
      loadingFunc(true);
      const res = await axios.get(`${LIVE_URl}/staff/${id}`);
      if (res) {
        loadingFunc(false);
        dispatch({
          type: GET_STAFF_DETAILS,
          payload: res?.data?.data,
        });
      }
  
      return true;
    } catch (error) {
      loadingFunc(false);
      toast.error(error.response.data.message);
    }
  };
// STAFF Update

// export const updateStaff =
//   (staffId, staffDetails, loadingFunc) => async (dispatch) => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     const body = staffDetails;
//     try {
//       const res = await axios.put(`${LIVE_URl}/staff/${staffId}`, body, config);

//       if (res) {
//         dispatch(getStaff(loadingFunc));
//         toast.success("Staff Updated Successfully");
//       }

//       return res;
//     } catch (error) {
//       toast.error(error.response.data.message);
//       return false;
//     }
//   };
export const updateStaff = (staffId, staffDetails, loadingFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(`${LIVE_URl}/staff/${staffId}`, staffDetails, config);

    if (res.status === 200) {
      dispatch(getStaff(loadingFunc));
      toast.success("Staff Updated Successfully");
      console.log(getStaff)
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return false;
    
  }
};

// DELETE STAFF

export const deleteStaff = (staffId, deleteLoaderFunc) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    deleteLoaderFunc(true);
    const res = await axios.delete(`${LIVE_URl}/staff/${staffId}`, config);
    if (res) {
      deleteLoaderFunc(false);
      dispatch(getStaff(deleteLoaderFunc));
      toast.success("Staff Delete Successfully");
    }
    return res;
  } catch (error) {
    deleteLoaderFunc(false);
    toast.error(error.response.data.message);
    return false;
  }
};
