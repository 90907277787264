import {
  GET_PERMISSIONS,
  GET_ROLES,
  GET_ALL_USERS,
  GET_USER_INFO,
  GET_ROLE_INFO,
  GET_ROLE_DETAILS
} from "../actions/types";

const initialState = {
  allRoles: [],
  allPermissions: [],
  allUsers: [],
  userDetails: "",
  roleDetails: "",
};

export default function role(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ROLES:
      return {
        ...state,
        allRoles: payload,
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        allPermissions: payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case GET_USER_INFO:
      return {
        ...state,
        userDetails: payload,
      };
    case GET_ROLE_INFO:
      return {
        ...state,
        roleDetails: payload,
      };
      case GET_ROLE_DETAILS:
        return {
          ...state,
          roleDetails: payload,
        };
    default:
      return state;
  }
}
