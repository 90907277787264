import React, { useCallback, useEffect, useState } from "react";
import { businessUpdate, getBusinessDetails } from "../../actions/business";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URl } from "../../actions/types";
import axios from "axios";
import Loader from "../../components/global/Loader";

const EditBusiness = ({ businessUpdate }) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();

  const [errors, setErrors] = useState({
    nameErr: "",
    contact_numberErr: "",
    business_emailErr: "",
    countryErr: "",
    cityErr: "",
    post_codeErr: "",
    valid_tillErr: "",
    alert_beforeErr: "",
    aboutErr: "",
    statusErr: "",
  });
  const {
    nameErr,
    contact_numberErr,
    business_emailErr,
    countryErr,
    cityErr,
    post_codeErr,
    valid_tillErr,
    alert_beforeErr,
    aboutErr,
    statusErr,
  } = errors;

  const loadingFunc = (key) => {
    setLoading(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios.get(`${LIVE_URl}/business/${params.id}`);
      setData(result?.data?.data);
      setLoading(false);
    };
    fetchData();
  }, [params.id]);

  const validate = () => {
    const requiredFields = [
      "name",
      "contact_number",
      "business_email",
      "country",
      "city",
      "post_code",
      "valid_till",
      "alert_before",
      "about",
      "status",
    ];

    for (let field of requiredFields) {
      if (!data?.[field]) {
        setErrors((prev) => ({
          ...prev,
          [`${field}Err`]: `Please provide ${field}`,
        }));
      }
    }

    return !requiredFields.some((field) => !data?.[field]);
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    const isValid = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(
      e.target.value
    );
    setData((prev) => ({ ...prev, business_email: e.target.value }));
    setErrors((prev) => ({
      ...prev,
      emailErr: isValid
        ? ""
        : e.target.value
        ? "Invalid email address"
        : "Email can't be empty",
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        let response = await businessUpdate(params.id, data, loadingFunc);
        setLoading(false);
        if (response) {
          navigate("/dashboard/all-businesses");
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <header className="my-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="s-24">
                <i className="icon-pages"></i>
                Company Name <span className="s-14">Registration Number</span>
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="animatedParent animateOnce">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Loader />
                </div>
              ) : (
                <div className="card">
                  <div className="card-body b-b">
                    <form className="form-material">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="name"
                                  value={data?.name}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      nameErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Business name"
                                />
                              </div>
                              {nameErr && (
                                <p className="text-danger m-0">{nameErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="business_email"
                                  value={data?.business_email}
                                  onChange={(e) => handleChangeEmail(e)}
                                  className="form-control"
                                  placeholder="Business email"
                                  required="required"
                                />
                              </div>
                              {business_emailErr && (
                                <p className="text-danger">
                                  {business_emailErr}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="contact_number"
                                  value={data?.contact_number}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      contact_numberErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Contact number"
                                  required="required"
                                />
                              </div>
                              {contact_numberErr && (
                                <p className="text-danger">
                                  {contact_numberErr}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="post_code"
                                  value={data?.post_code}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      post_codeErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Postal code"
                                  required="required"
                                />
                              </div>
                              {post_codeErr && (
                                <p className="text-danger">{post_codeErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="country"
                                  value={data?.country}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      countryErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Business country"
                                  required="required"
                                />
                              </div>
                              {countryErr && (
                                <p className="text-danger">{countryErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <input
                                  type="text"
                                  name="city"
                                  value={data?.city}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      cityErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Business city"
                                  required="required"
                                />
                              </div>
                              {cityErr && (
                                <p className="text-danger">{cityErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <label>Validate Date</label>

                                <input
                                  type="date"
                                  name="valid_till"
                                  value={data?.valid_till}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      valid_tillErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Validate date"
                                  required="required"
                                />
                              </div>
                              {valid_tillErr && (
                                <p className="text-danger">{valid_tillErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <label>Alert Date</label>
                                <input
                                  type="date"
                                  name="alert_before"
                                  value={data?.alert_before}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      alert_beforeErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="Alert date"
                                  required="required"
                                />
                              </div>
                              {alert_beforeErr && (
                                <p className="text-danger">{alert_beforeErr}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="form-line">
                                <textarea
                                  type="text"
                                  name="about"
                                  value={data?.about}
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      aboutErr: "",
                                    }));
                                  }}
                                  className="form-control"
                                  placeholder="About"
                                  required="required"
                                />
                              </div>
                              {aboutErr && (
                                <p className="text-danger">{aboutErr}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="form-line">
                                <select
                                  name="status"
                                  className="custom-select form-control"
                                  value={data?.status}
                                  required="required"
                                  onChange={(e) => {
                                    onChangeFields(e);
                                    setErrors((prev) => ({
                                      ...prev,
                                      statusErr: "",
                                    }));
                                  }}
                                >
                                  <option value="" disabled={true}>
                                    Select an business
                                  </option>
                                  <option value="1">Active</option>
                                  <option value="0">In active</option>
                                </select>
                              </div>
                              {statusErr && (
                                <p className="text-danger">{statusErr}</p>
                              )}
                            </div>

                            <button
                              style={
                                loading ||
                                nameErr ||
                                contact_numberErr ||
                                business_emailErr ||
                                countryErr ||
                                cityErr ||
                                post_codeErr ||
                                valid_tillErr ||
                                alert_beforeErr ||
                                aboutErr
                                  ? { pointerEvents: "none" }
                                  : null
                              }
                              onClick={onSubmit}
                              className="btn btn-primary mt-2"
                            >
                              <i className="icon-arrow_forward mr-2"></i>Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditBusiness.propTypes = {
  businessUpdate: PropTypes.func.isRequired,
  business: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, {
  businessUpdate,
})(EditBusiness);
