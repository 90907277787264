import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../global/Button";
import Icofont from "react-icofont";
import ResetButton from "../global/ResetButton";
import { getShops } from "../../actions/shops";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {  } from "../../actions/bank";

const useStyles = makeStyles({
  form: {
    padding: "10px 0",
  },
  labelCol: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#555",
    "@media (max-width: 768px)": {
      justifyContent: "start",
    },
  },
  row: {
    marginBottom: "1rem",
    alignItems: "center",
    "@media (max-width: 768px)": {
      marginBottom: "1rem !important",
    },
  },
  bottomFooter: {
    textAlign: "right",
  },
  errorMsg: {
    color: "#d32f2f",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.66 !important",
    letterSpacing: "0.03333em !important",
    textAlign: "left !important",
    marginTop: "3px !important",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "0 !important",
  },
});

const EditBankRequest = ({
  closeEditBankRequestForm,
  bankRequestData,
  
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const loadingFunc = (key) => {
    setLoading(key);
  };

  const [data, setData] = useState({
    is_trained: bankRequestData?.is_trained,
    status: bankRequestData?.status,
  });
  const [errors, setErrors] = useState({
    is_trainedErr: "",
    statusErr: "",
  });

  const { is_trained, status } = data;

  const { is_trainedErr, statusErr } = errors;

  const validate = () => {
    if (!is_trained) {
      setErrors((prev) => ({
        ...prev,
        is_trainedErr: "Please provide trained",
      }));
    }
    if (!status) {
      setErrors((prev) => ({
        ...prev,
        statusErr: "Please provide status",
      }));
    }

    if (!is_trained || !status) {
      return false;
    }
    return true;
  };

  const onChangeFields = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const dataForm = new FormData();
        dataForm.append("is_trained", is_trained);
        dataForm.append("status", status);
        dataForm.append("_id", bankRequestData?._id);
        let response = await (dataForm, loadingFunc);
        setLoading(false);
        if (response) {
          closeEditBankRequestForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onResetForm = (e) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      is_trained: "",
      status: "",
    }));
    setErrors((prev) => ({
      ...prev,
      is_trainedErr: "",
      statusErr: "",
    }));
  };
  return (
    <>
      <form className={classes.form}>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Trained</label>
            <div className="select">
              <select
                name="is_trained"
                style={{ border: is_trainedErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={is_trained}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    is_trainedErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an trained
                </option>
                <option value="1">Trained</option>
                <option value="0">No Trained</option>
              </select>
              {is_trainedErr && (
                <Typography className={classes.errorMsg}>
                  {is_trainedErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={12} md={12}>
            <label>Status</label>
            <div className="select">
              <select
                name="status"
                style={{ border: statusErr && "1px solid #d32f2f" }}
                className="custom-select form-control"
                value={status}
                required="required"
                onChange={(e) => {
                  onChangeFields(e);
                  setErrors((prev) => ({
                    ...prev,
                    statusErr: "",
                  }));
                }}
              >
                <option value="" disabled={true}>
                  Select an trained
                </option>
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </select>
              {statusErr && (
                <Typography className={classes.errorMsg}>
                  {statusErr}
                </Typography>
              )}
            </div>
          </Col>
        </Row>

        <div className={classes.bottomFooter}>
          <CustomButton
            text={"Submit"}
            onClick={onSubmit}
            loading={loading}
            loader={
              <Icofont
                icon="spinner"
                style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                spin="true"
              />
            }
            style={
              loading || is_trainedErr || statusErr
                ? { pointerEvents: "none" }
                : null
            }
          />
          <ResetButton
            text={"Reset"}
            onClick={(e) => onResetForm(e)}
            style={loading ? { pointerEvents: "none" } : null}
          />
        </div>
      </form>
    </>
  );
};
EditBankRequest.propTypes = {
  
};

const mapStateToProps = (state) => ({
  bank: state.bank,
});

export default connect(mapStateToProps, {
  
})(EditBankRequest);
