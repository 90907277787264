import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../../assets/css/sidebar.css";

function Sidebar({ toggleNavbar }) {
  let location = useLocation();
  let staticPath = "/dashboard";
  const userPermissions = ["delete-user", "add-user", "update-user"]; // Replace with actual user permissions

  // // Function to check if the user has permission to view a specific sidebar item
  // const hasPermission = (permission) => userPermissions.includes(permission);

  const hasPermission = (permission) => {
    const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    return userPermissions.includes(permission);
  };
  return (
    <>
      <div className="page-content d-flex align-items-stretch">
        <div className="default-sidebar shadow">
          <nav
            className={
              toggleNavbar
                ? "side-navbar box-scroll sidebar-scroll"
                : "side-navbar box-scroll sidebar-scroll shrinked"
            }
          >
            <ul className="list-unstyled customList">
      {hasPermission("delete-user") && (
        <li
          style={{
            background:
              location.pathname === `${staticPath}/email_pipping` ? "#0098da" : "#fff",
          }}
        >
          <NavLink to={`${staticPath}/email_pipping`}>
            
          </NavLink>
        </li>
      )}
     
    </ul>
            <ul className="list-unstyled customList">
            {hasPermission("add-users") && (
        <li
          style={{
            background:
              location.pathname === `${staticPath}/stats` ? "#0098da" : "#fff",
          }}
        >
          <NavLink to={`${staticPath}/stats`}>
            {/* ... */}
          </NavLink>
        </li>
      )}
              {JSON.parse(localStorage?.getItem("user_data"))?.user_type ===
              "SuperAdmin" ? (
                <li
                  style={{
                    background:
                      location.pathname === `${staticPath}/businesses`
                        ? "#0098da"
                        : "#fff",
                  }}
                >
                  <NavLink to={`${staticPath}/businesses`}>
                    <i
                      className="icofont-dashboard"
                      style={{
                        color:
                          location.pathname === `${staticPath}/businesses`
                            ? "#fff"
                            : "#0098da",
                      }}
                    ></i>
                    <span
                      style={{
                        display: "block !important",
                        color:
                          location.pathname === `${staticPath}/businesses`
                            ? "#fff"
                            : "#0098da",
                      }}
                    >
                      Businesses
                    </span>
                  </NavLink>
                </li>
              ) : (
                ""
              )}
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/shops`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/shops`}>
                  <i
                    className="icofont-shopping-cart"
                    style={{
                      color:
                        location.pathname === `${staticPath}/shops`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/shops`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Shops
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/banks`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/banks`}>
                  <i
                    className="icofont-bank-alt"
                    style={{
                      color:
                        location.pathname === `${staticPath}/banks`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/banks`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Banks
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/bank-request`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/bank-request`}>
                  <i
                    className="icofont-bank-alt"
                    style={{
                      color:
                        location.pathname === `${staticPath}/bank-request`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/bank-request`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Banks Requests
                  </span>
                </NavLink>
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/vendor`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/vendor`}>
                  <i
                    className="icofont-bank-alt"
                    style={{
                      color:
                        location.pathname === `${staticPath}/vendor`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/vendor`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Vendor
                  </span>
                </NavLink>
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/customer`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/customer`}>
                  <i
                    className="icofont-bank-alt"
                    style={{
                      color:
                        location.pathname === `${staticPath}/customer`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/customer`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Customer
                  </span>
                </NavLink>
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/documents`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                {/* <NavLink to={`${staticPath}/documents`}>
                  <i
                    className="icofont-file-document"
                    style={{
                      color:
                        location.pathname === `${staticPath}/documents`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/documents`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Documents
                  </span>
                </NavLink> */}
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/accounts`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/accounts`}>
                  <i
                    className="icofont-file-document"
                    style={{
                      color:
                        location.pathname === `${staticPath}/accounts`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/accounts`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Accounts
                  </span>
                </NavLink>
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/plans`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/plans`}>
                  <i
                    className="icofont-file-document"
                    style={{
                      color:
                        location.pathname === `${staticPath}/plans`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/plans`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Plans
                  </span>
                </NavLink>
              </li>

              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/proceed_files`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-files-stack"
                    style={{
                      color:
                        location.pathname === `${staticPath}/proceed_files`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/proceed_files`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Proceed Files
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/tickets`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-ticket"
                    style={{
                      color:
                        location.pathname === `${staticPath}/tickets`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/tickets`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Tickets
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/reports`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-document-folder"
                    style={{
                      color:
                        location.pathname === `${staticPath}/reports`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/reports`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Reports
                  </span>
                </NavLink>
              </li>
              <li className="header light mt-3">
                <strong>Admin Section</strong>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/staff`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink to={`${staticPath}/staff`}>
                  <i
                    className="icofont-users-alt-5"
                    style={{
                      color:
                        location.pathname === `${staticPath}/staff`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/staff`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Staff
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/roll_management`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-search-user"
                    style={{
                      color:
                        location.pathname === `${staticPath}/roll_management`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/roll_management`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Roll Management
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/spam_filter`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-bug"
                    style={{
                      color:
                        location.pathname === `${staticPath}/spam_filter`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                    style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/spam_filter`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Spam Filter
                  </span>
                </NavLink>
              </li>
              <li
                style={{
                  background:
                    location.pathname === `${staticPath}/email_pipping`
                      ? "#0098da"
                      : "#fff",
                }}
              >
                <NavLink>
                  <i
                    className="icofont-send-mail"
                    style={{
                      color:
                        location.pathname === `${staticPath}/email_pipping`
                          ? "#fff"
                          : "#0098da",
                    }}
                  ></i>
                  <span
                  style={{
                      display: "block !important",
                      color:
                        location.pathname === `${staticPath}/email_pipping`
                          ? "#fff"
                          : "#0098da",
                    }}
                  >
                    Email Pipping
                  </span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
